const fontFamily = 'Roboto';
const editorFontFamily = {
    primary: 'Sole Serif Text',
    secondary: 'Sole Serif Bold',
    subtitle: 'Libre Franklin',
};

export const baseConfig = {
    body: {
        XS: {
            fontFamily,
            fontSize: '10px',
            fontWeight: 'normal',
            lineHeight: '16px',
        },
        S: {
            fontFamily,
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '16px',
        },
        M: {
            fontFamily,
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '20px',
        },
        L: {
            fontFamily,
            fontSize: '16px',
            fontWeight: 'normal',
            lineHeight: '24px',
        },
        XL: {
            fontFamily,
            fontSize: '22px',
            fontWeight: 'normal',
            lineHeight: '32px',
        },
    },
    subtitle: {
        XS: {
            fontFamily,
            fontSize: '10px',
            fontWeight: 500,
            lineHeight: '16px',
        },
        S: {
            fontFamily,
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '16px',
        },
        M: {
            fontFamily,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
        },
        L: {
            fontFamily,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
        },
        XL: {
            fontFamily,
            fontSize: '22px',
            fontWeight: 500,
            lineHeight: '32px',
        },
    },
    title: {
        XS: {
            fontFamily,
            fontSize: '11px',
            fontWeight: 'bold',
        },
        S: {
            fontFamily,
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '18px',
        },
        M: {
            fontFamily,
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: '24px',
        },
        L: {
            fontFamily,
            fontSize: '22px',
            fontWeight: 'bold',
            lineHeight: '32px',
        },
        XL: {
            fontFamily,
            fontSize: '48px',
            fontWeight: 'bold',
            lineHeight: '48px',
        },
    },
    editor: {
        primary: {
            S: {
                fontFamily: editorFontFamily.primary,
                fontSize: '14px',
                fontWeight: 'normal',
                lineHeight: '19px',
            },
            M: {
                fontFamily: editorFontFamily.primary,
                fontSize: '18px',
                fontWeight: 'normal',
                lineHeight: '28px',
            },
            L: {
                fontFamily: editorFontFamily.primary,
                fontSize: '20px',
                fontWeight: 'normal',
                lineHeight: '30px',
            },
            XL: {
                fontFamily: editorFontFamily.primary,
                fontSize: '22px',
                fontWeight: 'normal',
                lineHeight: '30px',
            },
        },
        secondary: {
            XS: {
                fontFamily: editorFontFamily.secondary,
                fontSize: '12px',
                fontWeight: 'normal',
                lineHeight: '18px',
            },
            S: {
                fontFamily: editorFontFamily.secondary,
                fontSize: '14px',
                fontWeight: 'normal',
                lineHeight: '18px',
            },
            M: {
                fontFamily: editorFontFamily.secondary,
                fontSize: '20px',
                fontWeight: 'normal',
                lineHeight: '28px',
            },
            L: {
                fontFamily: editorFontFamily.secondary,
                fontSize: '24px',
                fontWeight: 'normal',
                lineHeight: '30px',
            },
            XL: {
                fontFamily: editorFontFamily.secondary,
                fontSize: '36px',
                fontWeight: 'normal',
                lineHeight: '45px',
            },
            XXL: {
                fontFamily: editorFontFamily.secondary,
                fontSize: '49px',
                fontWeight: 'normal',
                lineHeight: '52px',
            },
        },
        subtitle: {
            XS: {
                fontFamily: editorFontFamily.subtitle,
                fontSize: '12px',
                fontWeight: 'normal',
                lineHeight: '20px',
            },
            S: {
                fontFamily: editorFontFamily.subtitle,
                fontSize: '14px',
                fontWeight: 'normal',
                lineHeight: '20px',
            },
            M: {
                fontFamily: editorFontFamily.subtitle,
                fontSize: '16px',
                fontWeight: 'normal',
                lineHeight: '28px',
            },
            L: {
                fontFamily: editorFontFamily.subtitle,
                fontSize: '18px',
                fontWeight: 'normal',
                lineHeight: '30px',
            },
        },
    },
};
