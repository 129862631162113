import deserializeState from '@@editor/serialization/deserializeState';
import serializeState from '@@editor/serialization/serializeState';
import {
    type Embed,
    type Link,
    type UnityEmbed,
    type UnityVideo,
    type UnityLink,
    type Video,
    NewVideo,
    NewUnityVideo,
    NewUnityLink,
    NewLink,
    NewEmbed,
    NewUnityEmbed,
} from '@@api/services/externalContent/schemas';
import { getDefaultVariant } from '@@containers/TenantSpecific/arrayUtils';
import { isNormalLink } from '@@routes/externalContent/links/utils';

import { deserializeVariants, serializeVariants } from '../../utils/transformers';
import { deserializeMetadata, serializeMetadata } from '../metadata/transformers';

export const deserializeEmbed = (entity: UnityEmbed): Embed => ({
    ...entity,
    metadata: entity.metadata ? deserializeMetadata(entity.metadata) : undefined,
    variants: deserializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: deserializeState(variant.lead),
        allowAutomaticTeaserUpdate: true,
    })),
});

export const serializeEmbed = (entity: Embed): UnityEmbed => ({
    ...entity,
    metadata: entity.metadata ? serializeMetadata(entity.metadata) : undefined,
    variants: serializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: serializeState(variant.lead),
    })),
});

export const serializeNewEmbed = (entity: NewEmbed): NewUnityEmbed => ({
    ...entity,
    variants: serializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: serializeState(variant.lead),
    })),
});

export const deserializeLink = (entity: UnityLink): Link => {
    const variants = deserializeVariants(entity.variants, (variant) => variant);
    const type = isNormalLink(getDefaultVariant(variants).url || '') ? 'url' : 'deepLink';

    return {
        ...entity,
        metadata: entity.metadata ? deserializeMetadata(entity.metadata) : undefined,
        variants,
        type,
    };
};

export const serializeLink = (entity: Link): UnityLink => ({
    ...entity,
    metadata: entity.metadata ? serializeMetadata(entity.metadata) : undefined,
    variants: serializeVariants(entity.variants, (variant) => variant),
});

export const serializeNewLink = (entity: NewLink): NewUnityLink => ({
    ...entity,
    variants: serializeVariants(entity.variants, (variant) => variant),
});

export const deserializeVideo = (entity: UnityVideo): Video => ({
    ...entity,
    metadata: entity.metadata ? deserializeMetadata(entity.metadata) : undefined,
    variants: deserializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: deserializeState(variant.lead),
        intro: deserializeState(variant.intro),
        allowAutomaticTeaserUpdate: true,
    })),
    content: deserializeState(entity.content),
});

export const serializeVideo = (entity: Video): UnityVideo => ({
    ...entity,
    url: entity.url ?? '',
    variants: serializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: serializeState(variant.lead || []),
        intro: serializeState(variant.intro || []),
    })),
    metadata: entity.metadata ? serializeMetadata(entity.metadata) : undefined,
    content: serializeState(entity.content),
});

export const serializeNewVideo = (entity: NewVideo): NewUnityVideo => ({
    ...entity,
    url: entity.url ?? '',
    variants: serializeVariants(entity.variants, (variant) => ({
        ...variant,
        lead: serializeState(variant.lead || []),
        intro: serializeState(variant.intro || []),
    })),
    content: serializeState(entity.content),
});
