import update from 'immutability-helper';
import invariant from 'tiny-invariant';

export const addItem = <T>(array: T[], index: number, item: T): T[] => [
    ...array.slice(0, index),
    item,
    ...array.slice(index),
];

export const cleanArray = <T>(arr: any[], cleanArraysAndObjects = true): T[] =>
    arr.filter((item) => {
        if (item === null || typeof item === 'undefined') {
            return false;
        }
        if (item === '') {
            return false;
        }
        if (cleanArraysAndObjects && typeof item === 'object' && Object.keys(item).length <= 0) {
            return false;
        }

        return true;
    });

export const moveItem = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
    invariant(
        fromIndex >= 0 && fromIndex < array.length,
        'Selected item index exceeds array bounds.',
    );

    let adjustedToIndex = toIndex;

    if (adjustedToIndex < 0) {
        adjustedToIndex = 0;
    } else if (adjustedToIndex >= array.length) {
        adjustedToIndex = array.length - 1;
    }

    const nextArray = update(array, {
        $splice: [
            [fromIndex, 1],
            [adjustedToIndex, 0, array[fromIndex]],
        ],
    });

    return nextArray;
};

export const removeIndex = <T>(array: T[], index?: number): T[] => {
    if (typeof index !== 'number' || index > array.length - 1 || index < 0) {
        return array;
    }

    return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const replaceIndex = <T>(array: T[], index: number, item: T): T[] => [
    ...array.slice(0, index),
    item,
    ...array.slice(index + 1),
];

export const initialize = <T>(len: number, value?: T): T[] => new Array(len).fill(value);
