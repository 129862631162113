import { castArray, noop } from 'lodash';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import Sockette from 'sockette';

import config from '@@config';
import { clearAuthentication, getAuth } from '@@auth/authSlice';
import checkAuthentication from '@@auth/utils/checkAuthentication';
import { useDispatch, useSelector } from '@@store/hooks';

export type Options = {
    debug?: boolean;
    enabled?: boolean;
    onUpdate?: (data: Record<string, unknown>) => void;
};

type Payload = {
    path: string;
    queryKey: QueryKey | QueryKey[];
    params?: UnknownObject;
};

const useLiveUpdates = ({ path, params, queryKey }: Payload, options: Options = {}) => {
    const { debug = false, enabled = true, onUpdate = noop } = options;

    const ws = useRef<Sockette | null>(null);
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { accessToken, expiresIn, loggedInTime } = useSelector(getAuth);

    const isMultipleQueryKeys = Array.isArray(queryKey[0]);

    const queryKeys = (isMultipleQueryKeys ? queryKey : [queryKey]) as QueryKey[];

    const log = (...args) => {
        if (debug) {
            // eslint-disable-next-line no-console
            console.log('useLiveUpdates', ...args);
        }
    };

    useEffect(() => {
        if (enabled) {
            const url = new URL(`${config.microservices.websocket.baseUrl}${path}`);

            // @ts-expect-error URLSearchParams should accept numbers for values https://github.com/microsoft/TypeScript/issues/32951
            const urlParams = new URLSearchParams(params);

            for (const [key, value] of urlParams.entries()) {
                url.searchParams.append(key, value);
            }

            ws.current = new Sockette(url.href, {
                protocols: accessToken as string,
                onopen: (e: Event) => log('Connected!', e),
                onmessage: (e: MessageEvent) => {
                    log('Received:', e);

                    const data = JSON.parse(e.data);
                    const array = castArray(data);

                    array.forEach((item) => {
                        if (item.resourceOperation === 'DELETE') {
                            queryKeys.forEach((key) => {
                                queryClient.removeQueries({ queryKey: key });
                            });
                        } else {
                            queryKeys.forEach((key) => {
                                queryClient.invalidateQueries({ queryKey: key });
                            });
                        }
                    });

                    onUpdate(data);
                },
                onreconnect: (e: CloseEvent | Event) => log('Reconnecting...', e),
                onmaximum: (e: CloseEvent | Event) => log('Stop Attempting!', e),

                // as we dont get the reason of why connection in closed, and we need to redirect to login if auth token expires,
                // we can check on our side if the session expired or not, and if it did we redirect.
                onclose: (e: CloseEvent) => {
                    log('Closed!', e);

                    if (!checkAuthentication(loggedInTime, expiresIn)) {
                        dispatch(clearAuthentication());
                    }
                },
                onerror: (e: Event) => {
                    if (process.env.NODE_ENV !== 'test') {
                        console.error('Error:', e);
                    }
                },
            });
        }

        return () => {
            if (ws.current) {
                ws.current.close();
                ws.current = null;
            }
        };
    }, [accessToken, enabled]);
};

export default useLiveUpdates;
