import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { z } from 'zod';
import { useStore } from 'react-redux';

import config from '@@config';
import { DefaultHeaders } from '@@api/headers';
import {
    NewTicker,
    Ticker,
    TickerCommon,
    TickerTypesTypes,
    UnityTicker,
} from '@@api/services/liveticker/schemas/ticker';
import {
    deserializeLeague,
    deserializeTeam,
    deserializeTicker,
    deserializeTickerEvent,
    serializeNewTicker,
    serializeTeam,
    serializeTicker,
    serializeTickerEvent,
} from '@@api/services/liveticker/transformers';
import { paginated } from '@@api/utils/schemas/utils';
import { League, NewTeam, Team, UnityTeam } from '@@api/services/liveticker/schemas/team';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import {
    NewTickerEvent,
    TickerEvent,
    TickerEventBase,
    UnityTickerEvent,
    UnityTickerEventReorder,
} from '@@api/services/liveticker/schemas/event';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { BreakingNews } from '@@api/services/breakingnews/schemas';
import { RootState } from '@@scripts/store/store';
import { commonResponses } from '@@api/commonResponses';
import { getQueryKeys } from '@@api/utils/queryKeys';

const contract = initContract();

const router = contract.router(
    {
        ticker: contract.router({
            post: {
                method: 'POST',
                path: '/tickers',
                body: NewTicker.transform(serializeNewTicker),
                responses: {
                    201: UnityTicker.transform(deserializeTicker),
                },
            },
            replace: {
                method: 'POST',
                path: '/tickers',
                body: z.null(),
                query: z.object({
                    breakingnewsId: BreakingNews.shape.id,
                }),
                responses: {
                    201: UnityTicker.transform(deserializeTicker),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/tickers/:id',
                pathParams: z.object({
                    id: TickerCommon.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
            getAll: {
                method: 'GET',
                path: '/tickers',
                query: z.object({
                    ...SearchParams.omit({ contentType: true }).shape,
                    ...PaginationParams.shape,
                    useSearch: z.literal(true).optional(),
                }),
                responses: {
                    200: paginated(UnityTicker.transform(deserializeTicker)),
                },
            },
            get: {
                method: 'GET',
                path: '/tickers/:id',
                pathParams: z.object({
                    id: TickerCommon.shape.id,
                }),
                responses: {
                    200: UnityTicker.transform(deserializeTicker),
                },
            },
            put: {
                method: 'PUT',
                path: '/tickers/:id',
                pathParams: z.object({
                    id: TickerCommon.shape.id,
                }),
                body: Ticker.transform(serializeTicker),
                responses: {
                    200: UnityTicker.transform(deserializeTicker),
                },
            },
        }),
        leagues: contract.router({
            getAll: {
                method: 'GET',
                path: '/leagues',
                query: z.object({
                    ...SearchParams.pick({ q: true }).shape,
                    ...PaginationParams.shape,
                    sportTypes: z.enum(['soccer,iceHockey', 'tennis', 'soccer', 'iceHockey']),
                }),
                responses: {
                    200: paginated(League.transform(deserializeLeague)),
                },
            },
            get: {
                method: 'GET',
                path: '/leagues/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: League.transform(deserializeLeague),
                },
            },
            post: {
                method: 'POST',
                path: '/leagues',
                body: League,
                responses: {
                    201: League.transform(deserializeLeague),
                },
            },
            put: {
                method: 'PUT',
                path: '/leagues/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: League,
                responses: {
                    200: League.transform(deserializeLeague),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/leagues/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
        teams: contract.router({
            getAll: {
                method: 'GET',
                path: '/teams',
                query: z.object({
                    ...SearchParams.pick({ q: true }).shape,
                    ...PaginationParams.shape,
                    leagueIds: z
                        .union([z.number(), z.array(z.number()), z.string(), z.array(z.string())])
                        .optional(),
                    sportType: z.enum(['soccer', 'iceHockey', 'tennis']),
                }),
                responses: {
                    200: paginated(UnityTeam.transform(deserializeTeam)),
                },
            },
            get: {
                method: 'GET',
                path: '/teams/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: UnityTeam.transform(deserializeTeam),
                },
            },
            post: {
                method: 'POST',
                path: '/teams',
                body: NewTeam.transform(serializeTeam),
                responses: {
                    200: UnityTeam.transform(deserializeTeam),
                    201: UnityTeam.transform(deserializeTeam),
                },
            },
            put: {
                method: 'PUT',
                path: '/teams/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: Team.transform(serializeTeam),
                responses: {
                    200: UnityTeam.transform(deserializeTeam),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/teams/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
        events: contract.router({
            getAll: {
                method: 'GET',
                path: '/tickers/:id/events',
                pathParams: z.object({
                    id: TickerCommon.shape.id,
                }),
                query: z.object({
                    ...PaginationParams.shape,
                }),
                responses: {
                    200: paginated(UnityTickerEvent.transform(deserializeTickerEvent)),
                },
            },
            get: {
                method: 'GET',
                path: '/tickers/:tickerId/events/:eventId',
                pathParams: z.object({
                    tickerId: TickerCommon.shape.id,
                    eventId: TickerEventBase.shape.id,
                }),
                responses: {
                    200: UnityTickerEvent.transform(deserializeTickerEvent),
                },
            },
            post: {
                method: 'POST',
                path: '/tickers/:id/events',
                pathParams: z.object({
                    id: TickerCommon.shape.id,
                }),
                query: z.object({
                    after: z.string().nullish(),
                }),
                body: NewTickerEvent.transform(serializeTickerEvent),
                responses: {
                    200: UnityTickerEvent.transform(deserializeTickerEvent),
                    201: UnityTickerEvent.transform(deserializeTickerEvent),
                },
            },
            put: {
                method: 'PUT',
                path: '/tickers/:tickerId/events/:eventId',
                pathParams: z.object({
                    tickerId: TickerCommon.shape.id,
                    eventId: TickerEventBase.shape.id,
                }),
                body: TickerEvent.transform(serializeTickerEvent),
                responses: {
                    200: UnityTickerEvent.transform(deserializeTickerEvent),
                },
            },
            patch: {
                method: 'PATCH',
                path: '/tickers/:tickerId/events',
                pathParams: z.object({
                    tickerId: TickerCommon.shape.id,
                }),
                body: UnityTickerEventReorder,
                responses: {
                    200: z.never(),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/tickers/:tickerId/events/:eventId',
                pathParams: z.object({
                    tickerId: TickerCommon.shape.id,
                    eventId: TickerEventBase.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
        types: contract.router({
            get: {
                method: 'GET',
                path: '/types',
                responses: {
                    200: TickerTypesTypes,
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type LivetickerRouter = typeof router;

const livetickerClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.livetickerUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export type LivetickerClient = ReturnType<typeof livetickerClient>;

export const useLivetickerClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return livetickerClient(state);
};
