import { useCallback, useEffect, useMemo, useState } from 'react';

type StorageTypes = 'localStorage' | 'sessionStorage';

/**
 * @param key under which value is/will be stored
 * @param initialValue
 * @param storageType restricted to local & session storage
 * @returns array containing retrieved|default value and setter function
 */
export const useStorage = function <T>(
    key: string,
    initialValue: T,
    storageType: StorageTypes = 'localStorage',
) {
    // We want to use only the first initial value. If it changes, it should not affect the stored value and avoid
    // unnecessary re-renders.
    const firstInitialValue = useMemo(() => initialValue, []);

    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        const item = window[storageType].getItem(key);

        try {
            return item ? JSON.parse(item) : firstInitialValue;
        } catch (error) {
            console.warn(`Error reading ${storageType} key “${key}”:`, error);

            return firstInitialValue;
        }
    });

    const handleStorageChange = useCallback(
        (event: StorageEvent) => {
            if (event.key === key) {
                setStoredValue(event.newValue ? JSON.parse(event.newValue) : firstInitialValue);
            }
        },
        [key, firstInitialValue],
    );

    useEffect(() => {
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [handleStorageChange]);

    // Return a wrapped version of useState's setter function that persists the new value
    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore = value instanceof Function ? value(storedValue) : value;

                setStoredValue(valueToStore);
                window[storageType].setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                console.warn(`Error writing in ${storageType} - key “${key}”:`, error);
            }
        },
        [key, storageType, storedValue],
    );

    return [storedValue, setValue] as const;
};
