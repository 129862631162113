import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';

import linkToContent from '@@containers/ContentItem/utils/linkToContent';
import { PREVIEW_IMAGE_WIDTH } from '@@containers/ContentItem/styles';
import ContentItem from '@@containers/ContentItem/ContentItem';
import PreviewImage from '@@containers/PreviewImage';
import TenantImage from '@@containers/TenantSpecific/TenantImage';
import { type Metadata } from '@@api/services/metadata/schemas';
import { getDefaultTeaserVariant } from '@@containers/Teaser/utils';
import { useMetadataClient } from '@@api/services/metadata/client';

const StyledContentItem = styled(ContentItem)({
    cursor: 'move',
    paddingLeft: 0,
    paddingRight: 0,
});

const TeaserImageWrapper = styled('div')({
    width: '100px',
});

const TeaserImage = styled(PreviewImage)({
    width: '100%',
    height: '56px',
    objectFit: 'cover',
});

const SmallTenantImage = styled(TenantImage)(({ theme }) => ({
    margin: theme.spacing(1, 1, 0, 1),
    verticalAlign: 'middle',
}));

type TeaserAndTenantImagesProps = {
    entity: AnyObject;
};
const TeaserAndTenantImages = ({ entity }: TeaserAndTenantImagesProps) => (
    <TeaserImageWrapper>
        <TeaserImage
            src={entity.image.url}
            transformations={{
                ...entity.image,
                maxWidth: PREVIEW_IMAGE_WIDTH,
            }}
        />
        {entity.tenantIds.map((tenantId) => (
            <SmallTenantImage key={tenantId} id={tenantId} size="S" natural />
        ))}
    </TeaserImageWrapper>
);

type Props = {
    id: Metadata['id'];
    onDelete: (teaserId: number) => void;
    readOnly?: boolean;
};

const EnhancedContentItem = (props: Props) => {
    const { id } = props;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const params = { id };

    const { data: metadataData } = metadataClient.metadata.get.useQuery({
        queryKey: metadataKeys.metadata.get({ params, query: {} }),
        queryData: { params },
    });

    const data = metadataData?.body;

    if (!data) {
        return null;
    }

    const entity = {
        ...data,
        ...getDefaultTeaserVariant(data.teasers),
    };

    const onEdit = () => linkToContent(data, navigate, true);
    const getDate = (entity) => entity.publishedAt;

    return (
        <StyledContentItem
            {...props}
            // @ts-expect-error ContentItem container is not typed
            entity={entity}
            renderImage={TeaserAndTenantImages}
            onEdit={onEdit}
            getDate={getDate}
            datePrefix={t('relatedcontent.contentItem.published')}
            hidePins
            collapsed
        />
    );
};

export default EnhancedContentItem;
