import { omit } from 'lodash';

import { Element } from '@@editor/helpers';
import { CustomText } from '@@editor/helpers/Element';
import { logCustomEvent } from '@@utils/analyticsTracker';

export type Value = Element | CustomText;

export type ValueToLog = {
    values: Value[];
    articleId?: string | number;
};

export type FilteredData = {
    originalValue: Value[];
    filteredValue: Value[];
    isModified: boolean;
};

/**
 *
 * @param originalValue
 * @returns FilteredData
 */
export const removeDuplicates = (originalValue: Value[]): FilteredData => {
    const idMap = new Map<string | number, number>();
    let isModified = false;
    const typeGuard = (itemToVerify: Element | CustomText): itemToVerify is Element => true;

    const omitDuplicateIds = (v: Element | CustomText) => {
        if (typeGuard(v) && v.id) {
            const repetitions = idMap.get(v.id);

            if (v.id && repetitions) {
                idMap.set(v.id, repetitions + 1);

                isModified = true;

                return omit<Element>(v, 'id') as Element;
            }
            if (v.id && !repetitions) {
                idMap.set(v.id, 1);
            }
        }

        return v;
    };

    const filteredValue = originalValue.map(omitDuplicateIds);

    return {
        originalValue,
        filteredValue,
        isModified,
    };
};

export const logDuplicateEvent = (isModified: boolean, value: ValueToLog) => {
    if (isModified) {
        logCustomEvent('ga_duplicate_id', {
            originalValue: JSON.stringify(value),
        });
    }
};
