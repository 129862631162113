import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { z } from 'zod';
import { useStore } from 'react-redux';

import config from '@@config';
import { DefaultHeaders, PatchDefaultHeaders } from '@@api/headers';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { paginated } from '@@api/utils/schemas/utils';
import {
    NewSlideshow,
    NewSlideshowSlide,
    Slideshow,
    SlideshowSlide,
    SlideshowSlideValidation,
    UnitySlideshow,
    UnitySlideshowSlide,
} from '@@api/services/slideshow/schemas';
import {
    deserializeSlideshow,
    deserializeSlideshowSlide,
    serializeNewSlideshow,
    serializeSlideshow,
    serializeSlideshowSlide,
} from '@@api/services/slideshow/transformers';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { RootState } from '@@scripts/store/store';
import { commonResponses } from '@@api/commonResponses';
import { Metadata } from '@@api/services/metadata/schemas';
import { getQueryKeys } from '@@api/utils/queryKeys';

const contract = initContract();

const router = contract.router(
    {
        slideshow: contract.router({
            getAll: {
                method: 'GET',
                path: '/slideshows',
                query: z.object({
                    ...SearchParams.omit({ contentType: true }).shape,
                    ...PaginationParams.shape,
                    useSearch: z.literal(true).optional(),
                }),
                responses: {
                    200: paginated(UnitySlideshow.transform(deserializeSlideshow)),
                },
            },
            get: {
                method: 'GET',
                path: '/slideshows/:id',
                pathParams: z.object({
                    id: Slideshow.shape.id,
                }),
                responses: {
                    200: UnitySlideshow.transform(deserializeSlideshow),
                },
            },
            put: {
                method: 'PUT',
                path: '/slideshows/:id',
                pathParams: z.object({
                    id: Slideshow.shape.id,
                }),
                body: Slideshow.transform(serializeSlideshow),
                responses: {
                    200: UnitySlideshow.transform(deserializeSlideshow),
                },
            },
            post: {
                method: 'POST',
                path: '/slideshows',
                body: NewSlideshow.transform(serializeNewSlideshow),
                responses: {
                    201: UnitySlideshow.transform(deserializeSlideshow),
                },
            },
            duplicate: {
                method: 'POST',
                path: '/slideshows',
                query: z.object({
                    sourceId: Slideshow.shape.id,
                }),
                body: z
                    .object({
                        metadata: Metadata.pick({ contentName: true }),
                        variants: Slideshow.shape.variants.nullish(),
                    })
                    // @ts-expect-error TODO: fix this
                    .transform(serializeSlideshow),
                responses: {
                    201: UnitySlideshow.transform(deserializeSlideshow),
                },
            },
        }),
        slide: contract.router({
            getAll: {
                method: 'GET',
                path: '/slideshows/:id/slides',
                pathParams: z.object({
                    id: Slideshow.shape.id,
                }),
                query: z.object({
                    ...PaginationParams.pick({ size: true, page: true }).shape,
                }),
                responses: {
                    200: paginated(UnitySlideshowSlide.transform(deserializeSlideshowSlide)),
                },
            },
            get: {
                method: 'GET',
                path: '/slideshows/:slideshowId/slides/:slideId',
                pathParams: z.object({
                    slideshowId: Slideshow.shape.id,
                    slideId: SlideshowSlide.shape.id,
                }),
                responses: {
                    200: UnitySlideshowSlide.transform(deserializeSlideshowSlide),
                },
            },
            patchAll: {
                method: 'PATCH',
                path: '/slideshows/:id/slides',
                pathParams: z.object({
                    id: z.string().uuid(),
                }),
                body: z.union([
                    z.array(
                        z.object({
                            op: z.literal('move'),
                            from: z.string(),
                            path: z.string(),
                        }),
                    ),
                    SlideshowSlide.pick({ variants: true })
                        // We use deepPartial here even though it is deprecated, as Zod doesn't provide any alternative so far
                        // https://github.com/colinhacks/zod/issues/2854
                        .deepPartial()
                        // @ts-expect-error Need to improve types here
                        .transform(serializeSlideshowSlide),
                ]),
                responses: {
                    200: z.never(),
                },
                headers: PatchDefaultHeaders,
            },
            put: {
                method: 'PUT',
                path: '/slideshows/:slideshowId/slides/:slideId',
                pathParams: z.object({
                    slideshowId: Slideshow.shape.id,
                    slideId: z.coerce.number(),
                }),
                body: SlideshowSlide.transform(serializeSlideshowSlide),
                responses: {
                    200: UnitySlideshowSlide.transform(deserializeSlideshowSlide),
                },
            },
            post: {
                method: 'POST',
                path: '/slideshows/:id/slides',
                pathParams: z.object({
                    id: Slideshow.shape.id,
                }),
                body: NewSlideshowSlide.transform(serializeSlideshowSlide),
                responses: {
                    201: UnitySlideshowSlide.transform(deserializeSlideshowSlide),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/slideshows/:slideshowId/slides/:slideId',
                pathParams: z.object({
                    slideshowId: Slideshow.shape.id,
                    slideId: z.coerce.number(),
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
            validateAll: {
                method: 'GET',
                path: '/slideshows/:id/slides/valid',
                pathParams: z.object({
                    id: Slideshow.shape.id,
                }),
                query: z.object({
                    includeDetails: z.boolean(),
                }),
                responses: {
                    200: z.array(SlideshowSlideValidation),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type SlideshowRouter = typeof router;

const slideshowClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.slideshowUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export type SlideshowClient = ReturnType<typeof slideshowClient>;

export const useSlideshowClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return slideshowClient(state);
};
