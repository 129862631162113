import { isURL } from 'validator';

import { parseUrl, toURL } from '@@utils/URL';

const document = window.document;

export const isFacebookUrl = (value) => {
    if (isURL(value.toString())) {
        const url = toURL(value);

        if (url instanceof URL) {
            return /facebook\.com/.test(url.hostname);
        }
    }

    return false;
};

export const isFacebookEmbedCode = (code) => {
    const url = parseFacebookUrlOrEmbedCode(code);

    if (url instanceof URL) {
        return true;
    }
};

export const parseFacebookUrlOrEmbedCode = (code) => {
    if (isFacebookUrl(code)) {
        return parseUrl(code);
    }

    const el = document.createElement('div');

    el.innerHTML = code;

    // this currently allows facebook embeds to only work when dragging/pasting
    // the embed code, not the url
    const [iframe] = el.getElementsByTagName('iframe');

    if (iframe instanceof HTMLElement) {
        const url = parseUrl(iframe.getAttribute('src'));

        if (isFacebookUrl(url)) {
            return url;
        }
    }
};
