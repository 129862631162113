import React, { CSSProperties, PropsWithChildren } from 'react';
import { styled } from '@mui/material';

import { ElementAttributes, ParagraphElement } from '@@editor/helpers/Element';
import {
    READONLY_BADGE_ATTRIBUTE,
    getTextReadOnlyBadgeStyle,
    BadgeProps,
} from '@@editor/plugins/serializable/styles';
import { Editor, ReactEditor } from '@@editor/helpers';

type PProps = BadgeProps & {
    $isArticleEditor?: boolean;
    showBadge?: boolean;
};

export const P = styled('p', { shouldForwardProp: (prop) => prop !== 'showBadge' })<PProps>(
    ({ $isArticleEditor, readOnly, showBadge, theme, ...props }) => ({
        position: 'relative',
        margin: 0,
        ...(!$isArticleEditor &&
            readOnly &&
            showBadge &&
            getTextReadOnlyBadgeStyle({ theme, ...props })),
        ...($isArticleEditor && theme.typography.editorPrimaryLarge),
    }),
);

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element?: ParagraphElement;
    showBadge: boolean;
    style?: CSSProperties;
}>;

const Paragraph = React.forwardRef<HTMLElement, Props>((props, ref) => {
    const { editor, attributes, children, showBadge, style } = props;

    const readOnly = ReactEditor.isReadOnly(editor);
    const translatedStyle = editor.t('embed.paragraph.crosshead.default');
    const styleProps = { [READONLY_BADGE_ATTRIBUTE]: translatedStyle };

    return (
        <P
            {...{ ref, style, ...attributes, ...styleProps, readOnly, showBadge }}
            data-type={translatedStyle}
            $isArticleEditor={editor.isArticleEditor}
        >
            {children}
        </P>
    );
});

export default Paragraph;
