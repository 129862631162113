import { math } from 'polished';
import { alpha, Popper, PopperProps, Theme, ThemeOptions } from '@mui/material';
import { NavLink, NavLinkProps, resolvePath, useSearchParams } from 'react-router-dom';
import type {} from '@mui/lab/themeAugmentation';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

import { getRel } from '@@utils/browser';
import { updateRef } from '@@utils/index';
import SoleSerifHeadlineWMdItalicWoff from '@@fonts/SoleSerifHeadline_W_MdIt.woff';
import SoleSerifHeadlineWMdItalicWoff2 from '@@fonts/SoleSerifHeadline_W_MdIt.woff2';
import SoleSerifHeadlineWMdItalicTtf from '@@fonts/SoleSerifHeadline_W_MdIt.ttf';
import SoleSerifTextWBoldWoff from '@@fonts/SoleSerifText_W_Bd.woff';
import SoleSerifTextWBoldWoff2 from '@@fonts/SoleSerifText_W_Bd.woff2';
import SoleSerifTextWBoldTtf from '@@fonts/SoleSerifText_W_Bd.ttf';
import SoleSerifTextWRegularWoff from '@@fonts/SoleSerifText_W_Rg.woff';
import SoleSerifTextWRegularWoff2 from '@@fonts/SoleSerifText_W_Rg.woff2';
import SoleSerifTextWRegularTtf from '@@fonts/SoleSerifText_W_Rg.ttf';
import SoleSerifTextWRegularItalicWoff from '@@fonts/SoleSerifText_W_RgIt.woff';
import SoleSerifTextWRegularItalicWoff2 from '@@fonts/SoleSerifText_W_RgIt.woff2';
import SoleSerifTextWRegularItalicTtf from '@@fonts/SoleSerifText_W_RgIt.ttf';

import { resolveColor } from '../utils';

const ONE_AND_A_HALF = 1.5;
// Using the same value as MUI is using internally (for tooltip background color).
const TOOLTIP_ALPHA_VALUE = 0.92;

const getListItemIndent = (theme: Theme, level?: number) =>
    level != null && level >= 0
        ? theme.spacing(2) + ' + ' + theme.spacing(4) + ' * ' + (level + 1) * ONE_AND_A_HALF
        : '0px';

const getListItemPadding = (theme, level) => ({
    paddingLeft: math(theme.spacing(4) + ' + ' + getListItemIndent(theme, level)),
    paddingRight: theme.spacing(4),
});

// https://mui.com/material-ui/guides/routing/#global-theme-link
export const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    | (Omit<NavLinkProps, 'to'> & {
          href: NavLinkProps['to'];
          to?: never;
          matchSearchParams?: boolean;
      })
    | (NavLinkProps & { href?: never; matchSearchParams?: boolean })
>((props, ref) => {
    const {
        className,
        href = '',
        to = href,
        rel = getRel(props.target),
        matchSearchParams,
        ...other
    } = props;

    const [searchParams] = useSearchParams();

    return (
        <NavLink
            {...other}
            className={(innerProps) => {
                const resolvedClassName =
                    typeof className === 'function' ? className(innerProps) : className;

                if (innerProps.isActive) {
                    if (!matchSearchParams) {
                        return clsx(resolvedClassName, 'Mui-selected');
                    }

                    const resolvedPath = resolvePath(to);
                    const targetSearchParams = new URLSearchParams(resolvedPath.search);

                    if (searchParams.size === targetSearchParams.size) {
                        const hasSameSearchParams = Array.from(targetSearchParams).every(
                            ([key, value]) => searchParams.get(key) === value,
                        );

                        if (hasSameSearchParams) {
                            return clsx(resolvedClassName, 'Mui-selected');
                        }
                    }
                }

                return resolvedClassName;
            }}
            ref={ref}
            to={to}
            rel={rel}
        />
    );
});

// By default popper renders components using a portal which injects elements into `document.body`. This generates
// problems, if we want to display popper components within modal dialogs, since we use the native dialog element,
// which renders modal dialogs in the top layer, which will always be displayed ABOVE everything else which is not
// rendered in the top layer (like stuff we inject into `document.body`).
// This `PopperComponent` tries to address this issue in a central place, by rendering the component inside the
// dialog element instead of the body, IF the component is actually inside a dialog.
const PopperComponent = React.forwardRef<HTMLElement, PopperProps>((props, ref) => {
    const [container, setContainer] = useState<HTMLElement | null>(null);

    const refCallback = useCallback(
        (instance) => {
            updateRef(ref, instance);

            if (instance) {
                setContainer((container) =>
                    !container ? instance.closest('body, dialog') : container,
                );
            }
        },
        [ref],
    );

    if (!props.disablePortal) {
        return (
            <Popper ref={refCallback} disablePortal={!container} container={container} {...props} />
        );
    }

    return <Popper {...props} />;
});

const components: ThemeOptions['components'] = {
    MuiCssBaseline: {
        styleOverrides: {
            '@font-face': {
                fontFamily: '"Sole Serif Text"',
                fontStyle: 'normal',
                fontWeight: 400,
                src: `url(${SoleSerifTextWRegularWoff2}) format('woff2'),
                    url(${SoleSerifTextWRegularWoff}) format('woff'),
                    url(${SoleSerifTextWRegularTtf}) format('truetype')`,
            },
            fallbacks: [
                {
                    '@font-face': {
                        fontFamily: '"Sole Serif Text"',
                        fontStyle: 'italic',
                        fontWeight: 400,
                        src: `url(${SoleSerifTextWRegularItalicWoff2}) format('woff2'),
                            url(${SoleSerifTextWRegularItalicWoff}) format('woff'),
                            url(${SoleSerifTextWRegularItalicTtf}) format('truetype')`,
                    },
                },
                {
                    '@font-face': {
                        fontFamily: '"Sole Serif Bold"',
                        fontStyle: 'bold',
                        fontWeight: 700,
                        src: `url(${SoleSerifTextWBoldWoff2}) format('woff2'),
                            url(${SoleSerifTextWBoldWoff}) format('woff'),
                            url(${SoleSerifTextWBoldTtf}) format('truetype')`,
                    },
                },
                {
                    '@font-face': {
                        fontFamily: '"Sole Serif Italic"',
                        fontStyle: 'italic',
                        fontWeight: 500,
                        src: `url(${SoleSerifHeadlineWMdItalicWoff2}) format('woff2'),
                            url(${SoleSerifHeadlineWMdItalicWoff}) format('woff'),
                            url(${SoleSerifHeadlineWMdItalicTtf}) format('truetype')`,
                    },
                },
            ],
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                // Avoid breaking text
                flexShrink: 0,
                whiteSpace: 'nowrap',
            },
            sizeSmall: {
                height: '24px',
            },
            sizeMedium: {
                height: '36px',
            },
            sizeLarge: {
                height: '48px',
            },
        },
    },
    MuiButtonBase: {
        defaultProps: {
            LinkComponent: LinkBehavior,
        },
    },
    MuiCard: {
        defaultProps: {
            square: true,
        },
        styleOverrides: {
            root: ({ ownerState: { color }, theme }) => ({
                // Make sure any absolute positioned element inside a card is not escaping it's boundaries
                position: 'relative',
                // Avoid cutting off absolute positioned elements that cross the cards boundaries
                overflow: 'visible',
                display: 'flex',
                flexDirection: 'column',
                '&::before': {
                    ...(color && {
                        position: 'absolute',
                        top: 0,
                        content: '""',
                        display: 'block',
                        width: '100%',
                        height: theme.borders[3],
                        backgroundColor: resolveColor(theme, color),
                    }),
                },
            }),
        },
    },
    MuiCardHeader: {
        defaultProps: {
            titleTypographyProps: {
                variant: 'h3',
            },
        },
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(4),
                // If this card header has a next sibling, which is not hidden and not a card header,
                // reduce padding-bottom
                // `:where` will reduce specifity to 0 and the `&` at the end will set it to the same level as
                // normal styles. This we do, in order to allow overriding those styles easily externally,
                // without having to know these internal rules.
                ':where(:has(+ *:not([aria-hidden="true"]):not(.MuiCardMedia-root)))&': {
                    paddingBottom: theme.spacing(2),
                },
            }),
            title: {
                display: 'flex',
                alignItems: 'center',
            },
            action: ({ theme }) => ({
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'auto',
                gap: theme.spacing(1),
            }),
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: ({ theme }) => ({
                ...theme.typography.small,
                borderColor: theme.palette.primary['100'],
            }),
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                verticalAlign: 'top',
            },
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: ({ theme }) => ({
                display: 'flex',
                padding: theme.spacing(4),
                '&:last-child': {
                    paddingBottom: theme.spacing(4),
                },
                // If the next sibling is a card content, reduce padding-bottom
                // `:where` will reduce specifity to 0 and the `&` at the end will set it to the same level as
                // normal styles. This we do, in order to allow overriding those styles easily externally,
                // without having to know these internal rules.
                ':where(:has(+ .MuiCardContent-root:not([aria-hidden="true"])))&': {
                    paddingBottom: `calc(${theme.spacing(4)} / 2)`,
                },
                // If this card content has a previous sibling, reduce padding-top
                // `:where` will reduce specifity to 0 and the `&` at the end will set it to the same level as
                // normal styles. This we do, in order to allow overriding those styles easily externally,
                // without having to know these internal rules.
                ':where(.MuiCardHeader-root:not([aria-hidden="true"]) + &, .MuiCardContent-root:not([aria-hidden="true"]) + &)&':
                    {
                        paddingTop: `calc(${theme.spacing(4)} / 2)`,
                    },
            }),
        },
    },
    MuiCardMedia: {
        styleOverrides: {
            root: {
                display: 'flex',
            },
        },
    },
    MuiCardActions: {
        styleOverrides: {
            root: ({ theme }) => ({
                borderTop: `1px solid ${theme.palette.divider}`,
                display: 'flex',
                padding: theme.spacing(4),
            }),
        },
    },
    MuiDivider: {
        styleOverrides: {
            root: ({ ownerState: { thick }, theme }) => ({
                ...(thick && {
                    borderBottomWidth: theme.borders[2],
                }),
            }),
            fullWidth: ({ ownerState: { indent }, theme }) => ({
                ...(indent != null && {
                    marginLeft: (indent < 0 ? '-' : '') + theme.spacing(Math.abs(indent)),
                    marginRight: (indent < 0 ? '-' : '') + theme.spacing(Math.abs(indent)),
                }),
            }),
        },
    },
    MuiDrawer: {
        defaultProps: {
            PaperProps: {
                elevation: 16,
            },
        },
    },
    MuiIconButton: {
        styleOverrides: {
            sizeSmall: {
                minWidth: '24px',
                minHeight: '24px',
            },
            sizeMedium: {
                minWidth: '36px',
                minHeight: '36px',
            },
            sizeLarge: {
                minWidth: '48px',
                minHeight: '48px',
            },
        },
    },
    MuiLink: {
        defaultProps: {
            color: 'secondary',
            component: LinkBehavior,
            underline: 'hover',
        },
    },
    MuiListItem: {
        defaultProps: {
            divider: true,
        },
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                ...(!ownerState.disableGutters &&
                    !ownerState.disablePadding &&
                    getListItemPadding(theme, ownerState.level)),
                ...(ownerState.active === false && {
                    color: theme.palette.primary['400'],
                }),
            }),
        },
    },
    MuiListItemButton: {
        defaultProps: {
            divider: true,
        },
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                ...(!ownerState.disableGutters &&
                    !ownerState.disablePadding &&
                    getListItemPadding(theme, ownerState.level)),
                ...(ownerState.active === false && {
                    color: theme.palette.primary['400'],
                }),
            }),
        },
    },
    MuiListItemText: {
        styleOverrides: {
            primary: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },
    },
    MuiLoadingButton: {
        defaultProps: {
            loadingPosition: 'start',
        },
    },
    MuiPagination: {
        styleOverrides: {
            root: {
                width: '100%',
            },
            ul: {
                justifyContent: 'center',
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            scroller: {
                height: '100%',
            },
            flexContainer: {
                height: '100%',
            },
        },
    },
    MuiTooltip: {
        defaultProps: {
            arrow: true,
            placement: 'top',
            PopperComponent,
        },
        styleOverrides: {
            arrow: ({ ownerState: { color }, theme }) => ({
                ...(color && {
                    color: alpha(theme.palette[color].main, TOOLTIP_ALPHA_VALUE),
                }),
            }),
            tooltip: ({ ownerState: { color }, theme }) => ({
                ...(color && {
                    color: theme.palette[color].contrastText,
                    backgroundColor: alpha(theme.palette[color].main, TOOLTIP_ALPHA_VALUE),
                }),
                ...theme.unstable_sx({ typography: 'medium' }),
            }),
        },
    },
    MuiTextField: {
        defaultProps: {
            size: 'small',
            fullWidth: true,
        },
    },
    MuiSwitch: {
        defaultProps: {
            color: 'success',
            inputProps: {
                type: 'checkbox',
            },
        },
    },
    MuiAutocomplete: {
        defaultProps: {
            fullWidth: true,
            size: 'small',
            slotProps: {
                popupIndicator: {
                    size: 'small',
                },
                clearIndicator: {
                    size: 'small',
                },
            },
        },
        styleOverrides: {
            tagSizeSmall: {
                height: 21,
            },
        },
    },
    MuiCheckbox: {
        defaultProps: {
            size: 'medium',
        },
    },
    MuiFormControl: {
        defaultProps: {
            fullWidth: true,
        },
    },
    MuiToggleButton: {
        styleOverrides: {
            root: ({ theme }) => ({
                '&.Mui-disabled': {
                    // This is the same as the default disabled color,
                    // but we need to set it again here because within MUI,
                    // the "selected" state is applied after the "disabled" state,
                    // and it looks not disabled anymore.
                    color: theme.palette.action.disabled,
                },
            }),
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: ({ theme }) => ({
                minWidth: theme.fixed.dialog.minWidth,
            }),
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(4),
            }),
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: ({ theme }) => ({
                paddingTop: `${theme.spacing(2)}!important`,
            }),
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: `0 ${theme.spacing(4)} ${theme.spacing(4)}`,
            }),
        },
    },
};

export default components;
