import React from 'react';
import {
    Autocomplete as MuiAutocomplete,
    AutocompleteProps as MuiAutocompleteProps,
    CircularProgress,
    ChipTypeMap,
    styled,
} from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import TextInput, { TextInputProps } from '@@form/components/TextInput';
import { FormFieldError } from '@@form/hooks/useReactHookFormFieldError';

const StyledListItem = styled('div')<{ level?: number }>(({ level = 0 }) => ({
    marginLeft: `calc(8px * 1.5 + 16px * ${level})`,
    marginRight: 'calc(8px * 1.5)',
}));

const Part = styled('span')<{ $highlight?: boolean }>(({ $highlight, theme }) => ({
    fontWeight: $highlight ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
}));

export type AutocompleteProps<
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> = RequiredBy<
    Omit<
        MuiAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
        'renderInput'
    >,
    'getOptionLabel'
> & {
    inputRef?: TextInputProps['inputRef'];
    label?: TextInputProps['label'];
    required?: TextInputProps['required'];
    placeholder?: TextInputProps['placeholder'];
    error?: FormFieldError;
    preserveErrorSpace?: TextInputProps['preserveErrorSpace'];
};

const Autocomplete = <
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(
    props: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
) => {
    const {
        placeholder,
        inputRef,
        label,
        required,
        loading,
        getOptionLabel,
        error,
        preserveErrorSpace,
        ...rest
    } = props;

    return (
        <MuiAutocomplete
            renderInput={(params) => (
                <TextInput
                    {...params}
                    error={error}
                    placeholder={placeholder}
                    inputRef={inputRef}
                    label={label}
                    required={required}
                    preserveErrorSpace={preserveErrorSpace}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option, { inputValue }) => {
                const optionToMatch = getOptionLabel(option);
                const matches = match(optionToMatch, inputValue, { insideWords: true });
                const parts = parse(optionToMatch, matches);

                return (
                    <li {...props}>
                        {/* We are trying to access a specific prop of a generic type
                        I do not know how to fix this issue
                        @ts-expect-error */}
                        <StyledListItem level={option.level}>
                            {parts.map((part, index) => (
                                <Part key={index} $highlight={part.highlight}>
                                    {part.text}
                                </Part>
                            ))}
                        </StyledListItem>
                    </li>
                );
            }}
            getOptionLabel={getOptionLabel}
            autoHighlight
            {...rest}
        />
    );
};

export default Autocomplete;
