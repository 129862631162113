import { z } from 'zod';

import { Entity, RichText, UnityRichText } from '../../utils/schemas/schemas';
import { unityVariantsShape, variantsShape } from '../../utils/schemas/utils';
import {
    EmbeddedMetadata,
    Metadata,
    UnityEmbeddedMetadata,
    UnityMetadata,
} from '../metadata/schemas';

const EmbedVariantBase = z.object({
    title: z.string(),
    titleHeader: z.string(),
    url: z.string().nullable(),
    allowAutomaticTeaserUpdate: z.boolean().optional(),
});

const UnityEmbedVariants = unityVariantsShape(
    EmbedVariantBase.extend({
        lead: UnityRichText,
    }),
);

const EmbedVariants = variantsShape(
    EmbedVariantBase.extend({
        lead: RichText,
    }),
);

const EmbedBase = Entity.extend({
    id: z.string().uuid(),
    metadataId: z.number(),
});

export const UnityEmbed = EmbedBase.extend({
    variants: UnityEmbedVariants,
    metadata: UnityEmbeddedMetadata.optional(),
});

export type UnityEmbed = z.infer<typeof UnityEmbed>;

export const Embed = EmbedBase.extend({
    variants: EmbedVariants,
    metadata: EmbeddedMetadata.optional(),
});

export type Embed = z.infer<typeof Embed>;

export const NewEmbed = Embed.pick({ variants: true }).extend({
    metadata: Metadata.pick({ contentName: true, mainCategoryId: true, tenantIds: true }),
});
export type NewEmbed = z.infer<typeof NewEmbed>;

export const NewUnityEmbed = UnityEmbed.pick({ variants: true }).extend({
    metadata: UnityMetadata.pick({ contentName: true, mainCategoryId: true, tenantIds: true }),
});
export type NewUnityEmbed = z.infer<typeof NewUnityEmbed>;

const LinkVariantBase = z.object({
    url: z.string().nullable(),
    openInNewWindow: z.boolean().optional(),
});

const UnityLinkVariants = unityVariantsShape(LinkVariantBase);

const LinkVariants = variantsShape(LinkVariantBase);

const LinkBase = Entity.extend({
    id: z.string().uuid(),
    metadataId: z.number(),
});

export const UnityLink = LinkBase.extend({
    variants: UnityLinkVariants,
    metadata: UnityEmbeddedMetadata.optional(),
});

export type UnityLink = z.infer<typeof UnityLink>;

export const Link = LinkBase.extend({
    type: z.string().optional(),
    variants: LinkVariants,
    metadata: EmbeddedMetadata.optional(),
});

export type Link = z.infer<typeof Link>;

export const NewLink = Link.pick({ variants: true, type: true }).extend({
    metadata: Metadata.pick({ contentName: true, mainCategoryId: true, tenantIds: true }),
});
export type NewLink = z.infer<typeof NewLink>;

export const NewUnityLink = UnityLink.pick({ variants: true }).extend({
    metadata: UnityMetadata.pick({ contentName: true, mainCategoryId: true, tenantIds: true }),
});
export type NewUnityLink = z.infer<typeof NewUnityLink>;

const VideoVariantBase = z.object({
    title: z.string(),
    titleHeader: z.string().nullable(),
    allowAutomaticTeaserUpdate: z.boolean().optional(),
});

const UnityVideoVariants = unityVariantsShape(
    VideoVariantBase.extend({
        lead: UnityRichText,
        intro: UnityRichText,
    }),
);

const VideoVariants = variantsShape(
    VideoVariantBase.extend({
        lead: RichText.nullable(),
        intro: RichText.nullable(),
    }),
);

const VideoBase = Entity.extend({
    id: z.string().uuid(),
    metadataId: z.number(),
    url: z.string().nullable(),
    elvisId: z.string().nullish(),
});

export const UnityVideo = VideoBase.extend({
    content: UnityRichText,
    variants: UnityVideoVariants,
    metadata: UnityEmbeddedMetadata.optional(),
});

export type UnityVideo = z.infer<typeof UnityVideo>;

export const Video = VideoBase.extend({
    content: RichText,
    variants: VideoVariants,
    metadata: EmbeddedMetadata.optional(),
});

export type Video = z.infer<typeof Video>;

export const NewVideo = Video.pick({
    content: true,
    variants: true,
    url: true,
    elvisId: true,
}).extend({
    metadata: Metadata.pick({ contentName: true, mainCategoryId: true, tenantIds: true }),
});
export type NewVideo = z.infer<typeof NewVideo>;

export const NewUnityVideo = UnityVideo.pick({
    content: true,
    variants: true,
    url: true,
    elvisId: true,
}).extend({
    metadata: UnityMetadata.pick({ contentName: true, mainCategoryId: true, tenantIds: true }),
});
export type NewUnityVideo = z.infer<typeof NewUnityVideo>;
