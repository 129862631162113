import { omit, mapValues, mapKeys } from 'lodash';
import moment, { Moment } from 'moment-timezone';

import { AUTHOR_TYPES } from '@@containers/metadata/components/Author/constants';
import { DEFAULT_TEASER_ID, VARIANTS_KEY } from '@@containers/Teaser/constants';
import deserializeState from '@@editor/serialization/deserializeState';
import serializeState from '@@editor/serialization/serializeState';
import transformValues from '@@editor/serialization/transformValues';
import {
    type Author,
    type Kickword,
    type Metadata,
    type Tag,
    type UnityAuthor,
    type UnityKickword,
    type UnityMetadata,
    type EmbeddedMetadata,
    type UnityEmbeddedMetadata,
    NewUnityKickword,
    NewKickword,
    TeaserVariants,
    NewTag,
} from '@@api/services/metadata/schemas';
import { AnyTeaser } from '@@containers/Teaser/types';

import { deserializeVariants, serializeVariants } from '../../utils/transformers';

const deserializeStyles = (styleVariants: UnityMetadata['styles']) =>
    styleVariants && deserializeVariants(styleVariants, (variant) => ({ style: variant }));

const serializeStyles = (styleVariants: Metadata['styles']) =>
    styleVariants && serializeVariants(styleVariants, (variant) => variant.style);

const deserializePlacement = (placementVariants: UnityMetadata['placement']) =>
    placementVariants &&
    deserializeVariants(placementVariants, (variant) => ({
        ...variant,
        categoryPlacementDetails: mapKeys(
            variant.categoryPlacementDetails,
            // `react-hook-form` doesn't like numeric keys, so we need to prefix them. In certain situations it turns
            // objects with numeric keys into arrays.
            (value, key) => `categoryId${key}`,
        ),
    }));

const serializePlacement = (placementVariants: Metadata['placement']) =>
    placementVariants &&
    serializeVariants(placementVariants, (variant) => ({
        ...variant,
        categoryPlacementDetails: mapKeys(variant.categoryPlacementDetails, (value, key) =>
            // `react-hook-form` doesn't like numeric keys, so we need to prefix them. In certain situations it turns
            // objects with numeric keys into arrays.
            key.replace(/^categoryId/, ''),
        ),
    }));

const deserializeMetadataKickword = (kickwordVariants: UnityMetadata['kickword']) =>
    // @ts-expect-error Kickword inside metadata can be null, which is non-standard for Variants, and TS gets confused
    kickwordVariants && deserializeVariants(kickwordVariants, (variant) => ({ kickword: variant }));

const serializeMetadataKickword = (kickwordVariants: Metadata['kickword']) =>
    kickwordVariants && serializeVariants(kickwordVariants, (variant) => variant.kickword);

const deserializeInfoboxes = (infoboxVariants: UnityMetadata['infobox']) =>
    infoboxVariants &&
    deserializeVariants(infoboxVariants, (variant) => ({
        infoboxes: variant.map((infobox) =>
            transformValues<typeof infobox, NonNullable<UnityMetadata['infobox']>>(infobox, [
                ['content', deserializeState],
            ]),
        ),
    }));

const serializeInfoboxes = (infoboxVariants: Metadata['infobox']) =>
    infoboxVariants &&
    serializeVariants(infoboxVariants, (variant) =>
        variant.infoboxes.map((infobox) =>
            transformValues<typeof infobox, NonNullable<UnityMetadata['infobox']>[string]>(
                infobox,
                [['content', serializeState]],
            ),
        ),
    );

const deserializeTeaserVariants = (teaserVariants: TeaserVariants): TeaserVariants =>
    mapValues(teaserVariants, (variant) =>
        transformValues(variant, [['image.caption', deserializeState]]),
    );

const serializeTeaserVariant = (variant: AnyTeaser): AnyTeaser =>
    transformValues(variant, [['image.caption', serializeState]]);

const serializeTeaserVariants = (teaserVariants: TeaserVariants) =>
    mapValues(teaserVariants, serializeTeaserVariant) as unknown as TeaserVariants;

export const deserializeTeasers = (teasers: UnityMetadata['teasers']) =>
    deserializeVariants(teasers, (teaser) => ({
        ...teaser,
        [VARIANTS_KEY]: deserializeTeaserVariants(teaser[VARIANTS_KEY]),
    }));

export const serializeTeasers = (
    deserializedTeasers: Metadata['teasers'],
): {
    teasers: UnityMetadata['teasers'];
} => {
    const teasers: UnityMetadata['teasers'] = serializeVariants(deserializedTeasers, (teaser) => ({
        ...teaser,
        [VARIANTS_KEY]: Object.entries(serializeTeaserVariants(teaser[VARIANTS_KEY])).reduce(
            (result, [variantId, variant]) => {
                if (variantId === DEFAULT_TEASER_ID) {
                    return Object.assign(result, { [variantId]: variant });
                }

                // Those two properties must stay only for the default teaser. The code is now not
                // injecting 'em anymore but previously it was and the backend was forgiving.
                // So if the teaser was already saved once in the past with the two additional properties,
                // it will always come back like that. For that reason we are filtering those properties out
                // here at schema level
                const nextVariant = omit(variant, ['useEmbedUrl', 'embedUrl']);

                return Object.assign(result, { [variantId]: nextVariant });
            },
            {} as TeaserVariants,
        ),
    }));

    return {
        teasers,
    };
};

export const deserializeTargetDateTime = (
    targetDateTime: UnityMetadata['targetDateTime'],
): Pick<Metadata, 'targetDateTime' | 'targetDate' | 'targetTime'> => {
    let deserializedTargetDateTime: Moment | null = null;
    let targetDate: string | null = null;
    let targetTime: string | null = null;

    if (targetDateTime) {
        deserializedTargetDateTime = moment.tz(targetDateTime, 'Europe/Zurich');
        [targetDate, targetTime] = deserializedTargetDateTime.format('YYYY-MM-DD HH:mm').split(' ');
    }

    return {
        targetDateTime: deserializedTargetDateTime ? deserializedTargetDateTime.format() : null,
        targetDate,
        targetTime,
    };
};

export const serializeTargetDateTime = ({ targetDate, targetTime }) => {
    if (targetDate && targetTime) {
        const date = moment(targetDate).format('YYYY-MM-DD');

        return moment.tz(`${date} ${targetTime}`, 'Europe/Zurich').toISOString();
    }

    return null;
};

export const deserializeMetadata = (entity: UnityMetadata | UnityEmbeddedMetadata): Metadata => {
    const { teasers, targetDateTime } = entity;
    const deserializedTeasers = deserializeTeasers(teasers);

    const deserializeAuthors = (authors: UnityAuthor[] | null): Author[] =>
        authors
            ? authors.map((author) => {
                  const parsedAuthor = omit(author, ['name', 'userId', 'agencyId']);

                  switch (author.type) {
                      case AUTHOR_TYPES.USER:
                      case AUTHOR_TYPES.MANUAL_USER:
                          return {
                              ...parsedAuthor,
                              manualType: author.type === AUTHOR_TYPES.MANUAL_USER,
                              type: AUTHOR_TYPES.USER,
                              user: {
                                  id: author.userId,
                                  name: author.name,
                              },
                          };
                      case AUTHOR_TYPES.CONTRIBUTOR:
                          return {
                              ...parsedAuthor,
                              type: AUTHOR_TYPES.CONTRIBUTOR,
                              user: {
                                  id: author.userId,
                                  name: author.name,
                              },
                          };
                      case AUTHOR_TYPES.AGENCY:
                      case AUTHOR_TYPES.MANUAL_AGENCY:
                          return {
                              ...parsedAuthor,
                              manualType: author.type === AUTHOR_TYPES.MANUAL_AGENCY,
                              type: AUTHOR_TYPES.AGENCY,
                              agency: {
                                  id: author.agencyId,
                                  name: author.name,
                              },
                          };
                      case AUTHOR_TYPES.SERVICE_USER:
                          return {
                              ...parsedAuthor,
                              type: AUTHOR_TYPES.SERVICE_USER,
                              user: {
                                  id: author.userId,
                                  name: author.name,
                              },
                          };
                      default:
                          return author;
                  }
              })
            : [];

    return {
        ...transformValues(entity, [
            ['note', deserializeState],
            ['authors', deserializeAuthors],
            [
                ['relatedContent', 'additionalSettings', 'urlSlugs', 'seoTeasers'],
                (data) => (data ? deserializeVariants(data) : data),
            ],
            ['placement', deserializePlacement],
            ['kickword', deserializeMetadataKickword],
            ['styles', deserializeStyles],
        ]),
        ...deserializeTargetDateTime(targetDateTime),
        teasers: deserializedTeasers,
        ...('infobox' in entity ? { infobox: deserializeInfoboxes(entity.infobox) } : []),
    } as Metadata;
};

export const serializeMetadata = (
    entity: Metadata | EmbeddedMetadata,
): UnityMetadata | UnityEmbeddedMetadata => {
    const { teasers } = entity;

    const serializeAuthors = (authors: Author[]) =>
        authors.map((author) => {
            const simplifiedAuthor = omit(author, ['manualType', 'user', 'agency']);

            switch (author.type) {
                case AUTHOR_TYPES.USER:
                    return {
                        ...simplifiedAuthor,
                        userId: author.user.id,
                        name: author.user.name,
                        type: author.manualType ? AUTHOR_TYPES.MANUAL_USER : author.type,
                        ...(author.manualType ? { name: author.user.name } : null),
                    };
                case AUTHOR_TYPES.CONTRIBUTOR:
                    return {
                        ...simplifiedAuthor,
                        userId: author.user.id,
                        name: author.user.name,
                    };
                case AUTHOR_TYPES.AGENCY:
                    return {
                        ...simplifiedAuthor,
                        agencyId: author.agency.id,
                        name: author.agency.name,
                        type: author.manualType ? AUTHOR_TYPES.MANUAL_AGENCY : author.type,
                    };
                case AUTHOR_TYPES.SERVICE_USER:
                    return {
                        ...simplifiedAuthor,
                        userId: author.user.id,
                        type: AUTHOR_TYPES.SERVICE_USER,
                    };
                default:
                    return simplifiedAuthor;
            }
        });

    return omit(
        {
            ...transformValues(entity, [
                ['note', serializeState],
                ['authors', serializeAuthors],
                [
                    ['relatedContent', 'additionalSettings', 'urlSlugs', 'seoTeasers'],
                    serializeVariants,
                ],
                ['placement', serializePlacement],
                ['kickword', serializeMetadataKickword],
                ['styles', serializeStyles],
            ]),
            targetDateTime:
                'targetDate' in entity && 'targetTime' in entity
                    ? serializeTargetDateTime({
                          targetDate: entity.targetDate,
                          targetTime: entity.targetTime,
                      })
                    : null,
            ...serializeTeasers(teasers),
            ...('infobox' in entity ? { infobox: serializeInfoboxes(entity.infobox) } : {}),
        },
        ['targetDate', 'targetTime', 'publishStatus'],
    ) as UnityMetadata;
};

export const deserializeTag = (entity: Tag): Tag => ({
    ...transformValues(entity, [['image.caption', deserializeState]]),
});

export const serializeTag = (entity: Tag | NewTag): Tag => ({
    ...transformValues(entity, [['image.caption', serializeState]]),
});

export const deserializeKickword = (entity: UnityKickword): Kickword => ({
    ...entity,
    variants: deserializeVariants(entity.variants, (variant) =>
        transformValues<typeof variant, Kickword['variants'][number]>(variant, [
            [['icon.caption'], deserializeState],
        ]),
    ),
});

export const serializeKickword = (
    entity: Kickword | NewKickword,
): UnityKickword | NewUnityKickword => ({
    ...entity,
    variants: serializeVariants(entity.variants, (variant) =>
        transformValues<typeof variant, UnityKickword['variants'][number]>(variant, [
            [['icon.caption'], serializeState],
        ]),
    ),
});
