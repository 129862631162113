import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { z } from 'zod';
import { useStore } from 'react-redux';

import config from '@@config';
import { DefaultHeaders } from '@@api/headers';
import { NewRTElement, RTElement, UnityRTElement } from '@@api/services/rtelements/schemas';
import { deserializeRTElement, serializeRTElement } from '@@api/services/rtelements/transformers';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { RootState } from '@@scripts/store/store';
import { commonResponses } from '@@api/commonResponses';
import { getQueryKeys } from '@@api/utils/queryKeys';

const contract = initContract();

const router = contract.router(
    {
        rtElements: contract.router({
            getAll: {
                method: 'GET',
                path: '/rt-elements',
                query: z.object({
                    ...SearchParams.pick({ q: true, tenantIds: true }).shape,
                    ...PaginationParams.shape,
                }),
                responses: {
                    200: z.array(UnityRTElement.transform(deserializeRTElement)),
                },
            },
            get: {
                method: 'GET',
                path: '/rt-elements/:id',
                pathParams: z.object({
                    id: RTElement.shape.id,
                }),
                responses: {
                    200: UnityRTElement.transform(deserializeRTElement),
                },
            },
            put: {
                method: 'PUT',
                path: '/rt-elements/:id',
                pathParams: z.object({
                    id: RTElement.shape.id,
                }),
                body: RTElement.transform(serializeRTElement),
                responses: {
                    200: UnityRTElement.transform(deserializeRTElement),
                },
            },
            post: {
                method: 'POST',
                path: '/rt-elements',
                body: NewRTElement.transform(serializeRTElement),
                responses: {
                    201: UnityRTElement.transform(deserializeRTElement),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/rt-elements/:id',
                pathParams: z.object({
                    id: RTElement.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type RTElementRouter = typeof router;

const rtElementsClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.rtElementsUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useRtElementsClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return rtElementsClient(state);
};
