import { useTranslation } from 'react-i18next';
import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { InputAdornment, Stack, styled } from '@mui/material';

import { focusInput } from '@@utils/DOM';
import TenantsAutocomplete from '@@containers/TenantsAutocomplete';
import ToggleButton from '@@components/buttonImplementations/ToggleButton';
import config from '@@config';
import Spacer from '@@components/Spacer';
import LeaguesAutocomplete from '@@containers/LeaguesAutocomplete';
import TextInput from '@@form/components/TextInput';
import FilterAutocomplete from '@@components/FilterAutocomplete';
import TagsAutocomplete from '@@containers/TagsAutocomplete';
import CategoriesAutocomplete from '@@containers/categories/CategoriesAutocomplete';
import AuthorSearchableAutocomplete from '@@components/AuthorSearchableAutocomplete/AuthorSearchableAutocomplete';
import PublicationStatus from '@@constants/PublicationStatus';
import ContentStatus from '@@constants/ContentStatus';
import Icon from '@@components/Icon';

import ContentLocaleRadioGroupSearch from './ContentLocaleRadioGroupSearch';
import { Search, SearchFields } from './types';

const FilterGroup = styled(Stack)(({ theme }) => ({
    gap: `12px ${theme.spacing(2)}`,
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',

    /* Here we force the max-width to make the expanded filters fields
    to go under the button instead of after it (665px is the width of two fields
    side by side) */
    maxWidth: '665px',
    width: '324px',
}));

const StyledToggleButton = styled(ToggleButton)({
    alignSelf: 'center',
});

const ContentLocaleRadioGroupWrapper = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(5),
}));

const getInputStyled = (Component) =>
    styled(Component)(({ $minWidth }) => ({
        minWidth: $minWidth || '324px',
        maxWidth: '324px',
    }));

const StyledTextInput = getInputStyled(TextInput);
const StyledTenantsAutocomplete = getInputStyled(TenantsAutocomplete);
const StyledFilterAutocomplete = getInputStyled(FilterAutocomplete);
const StyledTagsAutocomplete = getInputStyled(TagsAutocomplete);
const StyledLeaguesAutocomplete = getInputStyled(LeaguesAutocomplete);
const StyledCategoriesAutocomplete = getInputStyled(CategoriesAutocomplete);
const StyledAuthorSearchableAutocomplete = getInputStyled(AuthorSearchableAutocomplete);

export type UnitySearchProps = {
    fields: Search['fields'];
    values: Search['values'];
    debouncedValues: Search['debouncedValues'];
    focusOnMount?: boolean;
    leagueIdsSportType?: string;
    minWidth?: string;
    moreFilters?: boolean;
    fieldsProps?: {
        [K in keyof SearchFields]: {
            multiple?: boolean;
            disabled?: boolean;
        };
    };
    filters?: URLSearchParams;
};

const UnitySearch = (props: UnitySearchProps) => {
    const {
        focusOnMount = true,
        moreFilters = true,
        fields,
        fieldsProps,
        leagueIdsSportType,
        minWidth,
        values,
        filters,
    } = props;

    const domNodeRef = React.useRef<HTMLDivElement>(null);
    const isMounted = React.useRef(false);
    const [areFiltersExpanded, setAreFiltersExpanded] = React.useState(false);

    const { t } = useTranslation();

    const toggleAreFiltersExpanded = () =>
        setAreFiltersExpanded((areFiltersExpanded) => !areFiltersExpanded);

    const getCollapsedFilterCount = createSelector(
        [(values) => values],
        (values) =>
            Object.keys(values)
                .map((name) => ({ name, value: values[name] }))
                // q filter is not collapsed
                .filter(
                    (field) =>
                        !['q', 'contentLocale'].includes(field.name) && !isEmpty(field.value),
                ).length,
    );

    useEffect(() => {
        if (!isMounted.current && focusOnMount) {
            window.requestAnimationFrame(() => focusInput(domNodeRef.current));
            isMounted.current = true;
        }
    }, []);

    const filterCount = getCollapsedFilterCount(values);

    // This comment is for translation auto extract
    // (all possible contentTypes)
    // t('contentType.articles')
    // t('contentType.embeds')
    // t('contentType.links')
    // t('contentType.tickers')
    // t('contentType.videos')
    // t('contentType.slideshows')
    // t('contentType.breakingnews')
    const optionsLists = {
        contentType: config.contentTypes,
    };

    return (
        <Stack direction="row" flex="1 1 auto">
            <FilterGroup ref={domNodeRef} role="group">
                {fields.q && (
                    <StyledTextInput
                        $minWidth={minWidth}
                        {...fields.q}
                        value={fields.q.value || ''}
                        label={t('search.main.label')}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon name="magnifying-glass" />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}

                {moreFilters && (
                    <StyledToggleButton
                        count={filterCount}
                        expanded={areFiltersExpanded}
                        onChange={toggleAreFiltersExpanded}
                    />
                )}

                {areFiltersExpanded && (
                    <React.Fragment>
                        <Spacer sm v />
                        {/*
                         * This comment is for translation auto extract
                         * (all possible labels)
                         * t('search.leagues.iceHockey.label')
                         * t('search.leagues.soccer.label')
                         * t('search.leagues.tennis.label')
                         */}
                        {fields.leagueIds && (
                            <StyledLeaguesAutocomplete
                                $minWidth={minWidth}
                                {...fields.leagueIds}
                                display="block"
                                label={t(`search.leagues.${leagueIdsSportType}.label`)}
                                multiple
                                sportType={leagueIdsSportType}
                            />
                        )}

                        {fields.tenantIds && (
                            <StyledTenantsAutocomplete
                                $minWidth={minWidth}
                                {...fields.tenantIds}
                                label={t('search.tenants.label')}
                                multiple
                                isFullList
                                {...(fieldsProps?.tenantIds || {})}
                            />
                        )}

                        {fields.categoryIds && (
                            <StyledCategoriesAutocomplete
                                $minWidth={minWidth}
                                label={t('search.category.label')}
                                multiple
                                flat={false}
                                params={{
                                    active: true,
                                    sort: 'name,ASC',
                                }}
                                {...fields.categoryIds}
                            />
                        )}

                        {fields.tagIds && (
                            <StyledTagsAutocomplete
                                $minWidth={minWidth}
                                label={t('search.tags.label')}
                                {...fields.tagIds}
                            />
                        )}

                        {fields.userIds && (
                            <StyledAuthorSearchableAutocomplete
                                label={t('search.authors.label')}
                                {...fields.userIds}
                            />
                        )}

                        {fields.publicationStatus && (
                            <StyledFilterAutocomplete
                                $minWidth={minWidth}
                                {...fields.publicationStatus}
                                label={t('search.publicationStatus.label')}
                                optionsList={Object.values(PublicationStatus)}
                                translationNamespace="publicationStatus"
                                multiple
                            />
                        )}

                        {fields.contentStatus && (
                            <StyledFilterAutocomplete
                                $minWidth={minWidth}
                                {...fields.contentStatus}
                                label={t('search.contentStatus.label')}
                                optionsList={Object.values(ContentStatus)}
                                translationNamespace="contentStatus"
                                multiple
                            />
                        )}

                        {fields.contentType && (
                            <StyledFilterAutocomplete
                                $minWidth={minWidth}
                                {...fields.contentType}
                                label={t('search.contentType.label')}
                                optionsList={optionsLists.contentType}
                                translationNamespace="contentType"
                            />
                        )}

                        <Spacer sm v />
                    </React.Fragment>
                )}
            </FilterGroup>

            {areFiltersExpanded && fields.contentLocale && (
                <ContentLocaleRadioGroupWrapper>
                    <ContentLocaleRadioGroupSearch filters={filters} />
                </ContentLocaleRadioGroupWrapper>
            )}
        </Stack>
    );
};

export default UnitySearch;
