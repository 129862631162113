import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClientInferResponses } from '@ts-rest/core';
import { isFetchError } from '@ts-rest/react-query/v5';

import { HTTP_STATUS_CODES } from '@@constants/http';
import { extractETag } from '@@utils/eTagUtils';
import { deserializeCompareType } from '@@containers/Compare/commons/utils';
import snackbar from '@@containers/Snackbar';
import { ContentRouter } from '@@api/services/content/client';
import { type Compare } from '@@api/services/content/schemas/article';
import crypto from '@@scripts/utils/crypto';

type State = {
    isInConflict: false | Compare;
    setIsInConflict: React.Dispatch<React.SetStateAction<false | Compare>>;
    onConflictResolutionSuccess: VoidFunction;
    onConflictResolutionFail: (
        error: ClientInferResponses<ContentRouter['article']['put']> | Error,
    ) => void;
    articleETagValue: React.MutableRefObject<string | undefined> | null;
};

const INITIAL_STATE = {
    isInConflict: false as const,
    setIsInConflict: () => {},
    onConflictResolutionSuccess: () => {},
    onConflictResolutionFail: () => {},
    articleETagValue: null,
};

export const ArticleConflictContext = React.createContext<State>(INITIAL_STATE);

type Props = {
    children: React.ReactNode;
    contentId?: string;
};

export const ArticleConflictProvider = ({ children, contentId }: Props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [snackbarId] = useState(() => crypto.randomUUID());

    const [isInConflict, setIsInConflict] = useState<false | Compare>(false);
    const articleETagValue = useRef<string>();

    const onConflictResolutionSuccess = () => {
        setIsInConflict(false);
        navigate(
            {
                pathname: `/articles/${contentId}`,
                search: location.search,
            },
            { replace: true },
        );
    };

    const onConflictResolutionFail = (
        error: ClientInferResponses<ContentRouter['article']['put']> | Error,
    ) => {
        /* Make sure to also disable refetch on focus for this to be triggered.
           Otherwise, ETag will be automatically updated and the error not triggered in the BE */

        if (!isFetchError(error) && error.status === HTTP_STATUS_CODES.PRECONDITION_FAILED) {
            const snackbarText = isInConflict
                ? t('compare.alreadyInConflictNotification')
                : t('compare.isInConflictNotification');

            snackbar.info(snackbarText, { customAutoHideDuration: null, key: snackbarId });

            articleETagValue.current = extractETag(error.headers.get('etag')!);
            setIsInConflict(deserializeCompareType(error.body));
        }
    };

    useEffect(() => {
        if (!isInConflict) {
            snackbar.close(snackbarId);
        }
        // We want this to be triggered only when the conflict is resolved
    }, [isInConflict]);

    return (
        <ArticleConflictContext.Provider
            value={{
                isInConflict,
                setIsInConflict,
                onConflictResolutionSuccess,
                onConflictResolutionFail,
                articleETagValue,
            }}
        >
            {children}
        </ArticleConflictContext.Provider>
    );
};

export const useArticleConflictContext = () => {
    const context = useContext(ArticleConflictContext);

    return context;
};
