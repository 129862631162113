import invariant from 'tiny-invariant';

import { PERCENT_100 } from '../constants';

export const deserializeFocusPoint = (value?: { x: number; y: number }) => {
    if (!value) {
        return { x: '0', y: '0' };
    }

    const { x, y } = value;

    // denormalize [0,1] range
    const transformedPoint = {
        x: `${x * PERCENT_100}%`,
        y: `${y * PERCENT_100}%`,
    };

    return transformedPoint;
};

export const serializeFocusPoint = (
    value: { x: number | string; y: number | string },
    containerDomRef: HTMLDivElement,
) => {
    // if the value uses percentages already, just normalize them directly
    if (typeof value.x !== 'number' && value.x.indexOf('%') > 0) {
        const normalizePercentage = (percString) => percString.match(/[0-9]*/) / PERCENT_100;

        return {
            x: normalizePercentage(value.x),
            y: normalizePercentage(value.y),
        };
    }

    invariant(containerDomRef, 'Missing domNode to calculate normalized focus point.');

    const { width, height } = containerDomRef.getBoundingClientRect();

    // normalize to [0,1] range
    let x = Number(value.x) / width;
    let y = Number(value.y) / height;

    // keep koords in 0-1 bounds
    if (x < 0) {
        x = 0;
    }
    if (x > 1) {
        x = 1;
    }
    if (y < 0) {
        y = 0;
    }
    if (y > 1) {
        y = 1;
    }

    return {
        x,
        y,
    };
};
