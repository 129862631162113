import { z } from 'zod';

import { BE_STATUS, REMOTE_STATUS } from '@@containers/metadata/components/Push/constants';

import { Entity } from '../../utils/schemas/schemas';

export const NotificationCategory = z.object({
    name: z.string(),
    tenantIds: z.array(z.number()),
});

export type NotificationCategory = z.infer<typeof NotificationCategory>;

export const Notification = Entity.extend({
    id: z.number().optional(),
    body: z.string(),
    creatorId: z.string().optional(),
    metadataId: z.number().optional(),
    notificationCategories: z.array(z.string()).optional(),
    sound: z.boolean().optional(),
    tenantIds: z.array(z.number()).optional(),
});

export type Notification = z.infer<typeof Notification>;

const BaseNotificationStatus = z.object({
    notificationCategories: z.array(z.string()),
    tenantIds: z.array(z.number()),
});

const TransferedNotificationStatus = BaseNotificationStatus.extend({
    status: z.literal(BE_STATUS.TRANSFERRED),
    remoteStatus: z
        .object({
            finishedAt: z.string(),
            notificationCategories: z.array(z.string()),
            receivedAt: z.string(),
            status: z.nativeEnum(REMOTE_STATUS),
            tenants: z.array(z.number()),
        })
        .nullish(),
    remoteError: z.string().nullish(),
});

const ErroredNotificationStatus = BaseNotificationStatus.extend({
    status: z.literal(BE_STATUS.ERROR),
    remoteStatus: z.null().optional(),
});

export const NotificationStatus = z.discriminatedUnion('status', [
    TransferedNotificationStatus,
    ErroredNotificationStatus,
]);

export type NotificationStatus = z.infer<typeof NotificationStatus>;

export const EditionNotification = Notification.extend({
    editionId: z.number(),
    editionTargetId: z.number(),
});
export type EditionNotification = z.infer<typeof EditionNotification>;
