import { RouteObject } from 'react-router-dom';
import React from 'react';

import { TenantsParamsProvider } from '@@containers/TenantSpecific/TenantsParamsContext';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import(
                /* webpackChunkName: "placement" */ '@@routes/placement'
            );

            return {
                element: (
                    <TenantsParamsProvider>
                        <Layout />
                    </TenantsParamsProvider>
                ),
            };
        },
        children: [
            {
                path: ':tenantId/:categoryId/sections/:sectionId',
                lazy: async () => {
                    const { SectionModal } = await import(
                        /* webpackChunkName: "sectionModal" */ '@@routes/placement/components/SectionModal/SectionModal'
                    );

                    return { Component: SectionModal };
                },
            },
        ],
    },
];
