import { z } from 'zod';

const RevisionChangeOperation = z.enum(['add', 'remove', 'replace', 'move', 'copy']);

export type RevisionChangeOperation = z.infer<typeof RevisionChangeOperation>;

const RevisionChange = z.object({
    op: RevisionChangeOperation,
    path: z.string(),
    value: z.any().optional(),
    from: z.string().optional(),
});

export const Revision = z.object({
    changes: z.array(RevisionChange),
    date: z.string(),
    derivativeRevisionId: z.string(),
    derivativeVersion: z.number(),
    originRevisionId: z.string(),
    creator: z.unknown(),
    originVersion: z.number(),
});

export type Revision = z.infer<typeof Revision>;
