import { mapValues, pickBy, debounce, isEqual, isEmpty } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from '@@store/hooks';
import config from '@@config';
import { setArrayParam } from '@@router/urlSearchParamsUtils';
import ContentStatus from '@@constants/ContentStatus';
import { getUserId } from '@@auth/authSlice';
import { PATCH_CONTENT_TYPES } from '@@constants/http';
import { getContentLocaleSetting, getFiltersSetting, setSetting } from '@@settings/settingsSlice';
import { useAuthClient } from '@@api/services/auth/client';

import { Search, SearchFields, SearchNamespace, SearchValues } from './types';

export type UseSearchOptions = {
    searchNamespace: SearchNamespace;
    disableUrlSync?: boolean;
    fields: SearchFields;
};

const useSearch = (options: UseSearchOptions): Search => {
    const { disableUrlSync, fields, searchNamespace } = options;
    const { client: authClient, queryKeys: authKeys } = useAuthClient();
    const queryClient = authClient.useQueryClient();

    const isMounted = useRef(false);
    const initialValues = mapValues(fields, (field) => field?.value) as SearchValues;
    const [previousInitialValues, setPreviousInitialValues] = useState(initialValues);

    const [values, setValues] = useState<SearchValues>(initialValues);
    const [debouncedValues, setDebouncedValues] = useState<SearchValues>(initialValues);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const dispatch = useDispatch();

    const contentLocale = useSelector(getContentLocaleSetting);

    const savedFilters = useSelector(getFiltersSetting(searchNamespace));

    if (!isEqual(previousInitialValues, initialValues)) {
        setPreviousInitialValues(initialValues);
        setValues(initialValues);
        setDebouncedValues(initialValues);
    }

    const fieldsToPersist = mapValues(
        pickBy(fields, (field) => field.persist),
        (field) => field.value,
    );
    const fieldsToPersistNames = Object.keys(fieldsToPersist) as Array<keyof SearchFields>;

    const setDebouncedValue = (name: keyof SearchFields, value) => {
        setDebouncedValues((prevState) => ({
            ...prevState,
            [name]: !isEmpty(value) ? value : undefined,
        }));
    };

    const debouncedSetSearchParams = useMemo(
        () => debounce(setSearchParams, config.searchDebounce),
        [setSearchParams],
    );
    const debouncedSetDebouncedValue = useMemo(
        () => debounce(setDebouncedValue, config.searchDebounce),
        [],
    );

    const userId = useSelector(getUserId);

    const { mutate: patchPreferences } = authClient.users.preference.patch.useMutation({
        onSuccess: ({ body }) => {
            queryClient.invalidateQueries({
                queryKey: authKeys.users.preference.get({ params: { id: userId! } }),
            });
            dispatch(setSetting({ path: 'filters', value: body.filters }));
        },
    });

    const debouncedPatchPreferences = useMemo(
        () => debounce(patchPreferences, config.searchDebounce),
        [],
    );

    const handleChange = (options, name: keyof SearchFields) => (e) => {
        const { debounceSearch, mapToUrl } = options;

        const value = e && e.target ? e.target.value : e;

        setValue(name, value, () => {
            if (mapToUrl && !disableUrlSync) {
                changeUrlParam(name, value, debounceSearch);
            }

            if (fieldsToPersistNames.includes(name)) {
                debouncedPatchPreferences({
                    params: { id: userId! },
                    body: {
                        filters: {
                            [contentLocale]: {
                                [searchNamespace]: {
                                    [name]: value,
                                },
                            },
                        },
                    },
                    headers: { 'content-type': PATCH_CONTENT_TYPES.MERGE_PATCH },
                });
            }
        });

        // TODO: if name changes, we have to flush, or create a unique debounced
        // function for every field
        debouncedSetDebouncedValue(name, value);
    };

    const setValue = (name: keyof SearchFields, value, cb = () => {}) => {
        setValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        cb();
    };

    const getFieldProps = (field, fieldName: keyof SearchFields) => {
        const value = values[fieldName];

        return {
            value,
            onChange: handleChange(field, fieldName),
        };
    };

    const changeUrlParam = (name: keyof SearchFields, value: any, debounceSearch: boolean) => {
        const search = new URLSearchParams(searchParams);

        if (searchParams.has('page')) {
            search.set('page', '1');
        }

        if (value === null || value === undefined) {
            search.delete(name);
        } else if (Array.isArray(value)) {
            setArrayParam(search, name, value, 'replace');
        } else {
            search.set(name, value);
        }

        if (debounceSearch) {
            debouncedSetSearchParams(search, { replace: true });
        } else {
            setSearchParams(search, { replace: true });
        }
    };

    const fieldsProps = mapValues(fields, getFieldProps) as unknown as Search['fields'];

    useEffect(() => {
        if (!isMounted.current) {
            const nextBrowserUrlParams: AnyObject = {};

            fieldsToPersistNames.forEach((urlParam) => {
                const settingValue = savedFilters?.[urlParam];

                if (settingValue && !searchParams.has(urlParam)) {
                    nextBrowserUrlParams[urlParam] = settingValue;
                }
            });

            if (!isEmpty(nextBrowserUrlParams)) {
                const nextUrlParams = {
                    ...fieldsToPersist,
                    ...nextBrowserUrlParams,
                };

                if (options.disableUrlSync) {
                    setValues((prevState) => ({
                        ...prevState,
                        ...nextUrlParams,
                    }));
                } else {
                    setValues((prevState) => ({
                        ...prevState,
                        ...nextUrlParams,
                    }));

                    setSearchParams((searchParams) => {
                        mapValues(nextUrlParams, (value, name) => {
                            if (value === null || value === 'null' || value === '') {
                                searchParams.delete(name);
                            } else if (Array.isArray(value)) {
                                setArrayParam(searchParams, name, value, 'replace');
                            } else {
                                searchParams.set(name, value);
                            }
                        });

                        return searchParams;
                    });
                }
            }
        }

        if (location.pathname.includes('curatedList') && !searchParams.has('contentStatus')) {
            setSearchParams((params) => {
                params.set('contentStatus', ContentStatus.PROOFREAD);

                return params;
            });
        }

        isMounted.current = true;
    }, [
        fieldsToPersistNames,
        fieldsToPersist,
        dispatch,
        searchParams,
        setSearchParams,
        options.disableUrlSync,
        location.pathname,
        savedFilters,
    ]);

    return {
        fields: fieldsProps,
        values,
        debouncedValues,
    };
};

export default useSearch;
