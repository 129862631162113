import { get, noop, pickBy } from 'lodash';
import React, { useCallback } from 'react';
import { TableRow, styled } from '@mui/material';

import useMouseState from '@@hooks/useMouseState';
import { updateRef } from '@@utils/index';

import DataField from './DataField';

export const StyledRow = styled(TableRow)<{ $selectable?: boolean }>(({ $selectable }) => ({
    cursor: $selectable ? 'pointer' : 'default',

    '&[disabled]': {
        filter: 'opacity(0.5)',
        pointerEvents: 'none',
    },
}));

type RenderFieldProps<T = any> = {
    draggable?: boolean;
    column: TableColumn;
    columnIndex?: number;
    hovered?: boolean;
    record?: T;
    rowIndex?: number;
    selected?: boolean;
    value?: any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
    onClickAction: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        action: IContextMenuAction,
        extraData: { column: TableColumn; record: T },
    ) => void;
};

export type Props<T = any> = {
    as?: React.ElementType;
    className?: string;
    columns: TableColumn[];
    index: number;
    selected?: boolean;
    style?: React.CSSProperties;
    onClick?: (
        e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
        column: TableColumn,
        record: T,
        index: number,
    ) => void;
    draggable?: boolean;
    onDragStart?: (e: React.DragEvent<HTMLTableRowElement>) => void;
    disabled?: boolean;
    renderField?: (props: RenderFieldProps<T>) => React.ReactNode;
    children?: React.ReactNode;
    getRecord?: (props: Props) => any;
    onClickField?: (
        e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
        column: TableColumn,
        record: T,
    ) => void;
    onClickFieldAction?: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        action: IContextMenuAction,
        column: TableColumn,
        record: T,
    ) => void;
    onChangeField?: (
        e: React.ChangeEvent<HTMLInputElement>,
        column: TableColumn,
        record: T,
        index: number,
    ) => void;
    getRecordValue?: (record: T, column: TableColumn) => any;
    record?: T;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const Row = <T extends any>(
    props: Props<T> & { forwardedRef?: React.ForwardedRef<HTMLTableRowElement> },
) => {
    const {
        as,
        className,
        columns,
        selected,
        style,
        onClick,
        draggable,
        onDragStart,
        disabled,
        forwardedRef,
        getRecord = (props) => props.record,
        index,
        onClickField = noop,
        onClickFieldAction = noop,
        onChangeField = noop,
        getRecordValue = (record: T, column: TableColumn) => {
            if (Array.isArray(column.fieldName)) {
                return column.fieldName.reduce(
                    (acc, current) => ({
                        ...acc,
                        [current]: get(record, current),
                    }),
                    {},
                );
            }

            return get(record, column.fieldName!);
        },
    } = props;
    const [{ isHovered }, mouseStateRef] = useMouseState();

    const refCallback = useCallback((domNode) => {
        updateRef([forwardedRef, mouseStateRef], domNode);
    }, []);

    const handleClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (onClick) {
            const column = columns[index];
            const record = getRecord(props);

            onClick(e, column, record, index);
        }
    };

    const handleClickOnField = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        const column = columns[index];
        const record = getRecord(props);

        onClickField(e, column, record);
    };

    const handleClickOnFieldAction = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        action: IContextMenuAction,
        extraData: { column: TableColumn; record: T },
    ) => {
        onClickFieldAction(e, action, extraData.column, extraData.record);
    };

    const handleChangeOnField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const column = columns[index];
        const record = getRecord(props);

        onChangeField(e, column, record, index);
    };

    const renderField = (column: TableColumn, columnIndex: number) => {
        const record = getRecord(props);
        const recordValue = getRecordValue(record, column);

        const rowIndex = index;

        const value = column.getValue
            ? column.getValue({ record, recordValue, column, rowIndex, columnIndex })
            : recordValue;

        const fieldProps = {
            key: columnIndex,
            column,
            record,
            value,
            rowIndex,
            columnIndex,
            selected,
            hovered: isHovered,
            draggable,
            onClick: handleClickOnField,
            onClickAction: handleClickOnFieldAction,
            onChange: handleChangeOnField,
        };

        if (props.renderField) {
            if (typeof renderField === 'function') {
                return props.renderField(fieldProps);
            }

            const Component = props.renderField as React.ComponentType<RenderFieldProps<T>>;

            return <Component {...fieldProps} />;
        }

        return <DataField<T> {...fieldProps} />;
    };

    // Support react-virtuoso
    const dataProps = pickBy(props, (value, key) => key.startsWith('data-'));

    return (
        <StyledRow
            {...{ as, className, selected, style, draggable, onDragStart, disabled, ...dataProps }}
            $selectable={typeof onClick === 'function' && !disabled}
            ref={refCallback}
            hover
            onClick={handleClick}
        >
            {columns.map(renderField)}
        </StyledRow>
    );
};

const ForwardRef = React.forwardRef(
    (props: Props, ref: React.ForwardedRef<HTMLTableRowElement>) => (
        <Row {...props} forwardedRef={ref} />
    ),
);

export default ForwardRef;
