import { isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material';

import { DEFAULT_FOCUS_POINT } from '@@form/components/ImageEditor/constants';
import buildImageTransformationUrl, {
    type IImageTransformations,
} from '@@utils/buildImageTransformationUrl';
import { between } from '@@utils/number';
import getCoveredSize from '@@utils/images/getCoveredSize';
import Image, { type Props as ImageProps } from '@@components/Image';

type Props = {
    src?: string | null;
    transformations?: IImageTransformations;
    previewAspectRatio?: string;
    natural?: boolean;
} & Omit<ImageProps, 'src'>;

type StyledImageProps = Pick<Props, 'transformations' | 'natural'>;

const generateWidthStyles = (props: StyledImageProps) => {
    if (typeof props.transformations?.maxWidth !== 'undefined') {
        return {
            width: `${props.transformations.maxWidth}px`,
            maxWidth: `${props.transformations.maxWidth}px`,
        };
    } else if (!props.natural) {
        return {
            width: '100%',
        };
    }

    return {};
};

const generateHeightStyles = (props: StyledImageProps) => {
    if (typeof props.transformations?.maxHeight !== 'undefined') {
        return {
            height: `${props.transformations.maxHeight}px`,
            maxHeight: `${props.transformations.maxHeight}px`,
        };
    } else if (!props.natural) {
        return {
            height: 'auto',
        };
    }

    return {};
};

const updateObjectPosition = (
    imageEl: HTMLImageElement,
    transformations?: IImageTransformations,
) => {
    const focusPoint = transformations?.focusPoint;

    if (imageEl && focusPoint) {
        const { naturalWidth, naturalHeight } = imageEl;

        if (naturalWidth >= 0 && naturalHeight >= 0) {
            const computedStyles = getComputedStyle(imageEl);

            if (
                computedStyles &&
                computedStyles.objectFit === 'cover' &&
                // Never change the position of placeholder images!
                !imageEl.dataset.placeholder &&
                // Do not do any unnecessary calculations: If x and y equal 0.5 the browser
                // will do calculations perfectly fine natively
                !isEqual(focusPoint, DEFAULT_FOCUS_POINT)
            ) {
                const containerWidth = imageEl.clientWidth;
                const containerHeight = imageEl.clientHeight;
                const [coveredWidth, coveredHeight] = getCoveredSize({
                    width: naturalWidth,
                    height: naturalHeight,
                    containerWidth,
                    containerHeight,
                });

                // Convert focus point values from % to px
                const focusPointX = focusPoint.x * coveredWidth;
                const focusPointY = focusPoint.y * coveredHeight;

                // Calculate how much we have to move the image in order to display
                // the chosen focus point in the center
                let objectPositionX = -focusPointX + containerWidth / 2;
                let objectPositionY = -focusPointY + containerHeight / 2;

                // Make sure the new position is not out of bounds, not too far to the right,
                // not too far to the bottom, not too far to the left
                // and not too far to the top
                objectPositionX = between(objectPositionX, -(coveredWidth - containerWidth), 0);
                objectPositionY = between(objectPositionY, -(coveredHeight - containerHeight), 0);

                // eslint-disable-next-line no-param-reassign
                imageEl.style.objectPosition = `${objectPositionX}px ${objectPositionY}px`;
            } else {
                // eslint-disable-next-line no-param-reassign
                imageEl.style.objectPosition = 'initial';
            }
        }
    }
};

// Use `vertical-align: top;` to avoid whitespaces below image:
// https://stackoverflow.com/questions/11447707/div-container-larger-than-image-inside
const StyledImage = styled(Image, {
    shouldForwardProp: (prop) => !['transformations', 'natural'].includes(prop as string),
})<StyledImageProps>((props) => ({
    verticalAlign: 'top',
    ...generateWidthStyles(props),
    ...generateHeightStyles(props),
    ...(!props.natural && {
        objectFit: 'cover',
        borderRadius: `${props.theme.shape.borderRadius}px`,
    }),
}));

const PreviewImage: React.FC<Props> = (props) => {
    const { src, transformations, onLoad, previewAspectRatio, ...rest } = props;

    const transformedSrc = buildImageTransformationUrl(
        src,
        previewAspectRatio && transformations?.variants?.[previewAspectRatio]
            ? {
                  ...transformations,
                  cropMarks: transformations.variants[previewAspectRatio].cropMarks,
              }
            : transformations,
    );
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            updateObjectPosition(ref.current, transformations);
        }
    });

    const handleLoad = (e) => {
        updateObjectPosition(e.target, transformations);

        if (onLoad) {
            onLoad(e);
        }
    };

    return (
        <StyledImage
            {...rest}
            ref={ref}
            src={transformedSrc}
            // In order to avoid flaky unit tests, we need to set an initial value for `objectPosition`
            style={{ objectPosition: 'initial' }}
            transformations={transformations}
            onLoad={handleLoad}
        />
    );
};

export default PreviewImage;
