import { get } from 'lodash';
import React from 'react';
import { styled } from '@mui/material';

import { ImageElement, Element } from '@@editor/helpers/Element';
import PreviewImage from '@@containers/PreviewImage';
import toText from '@@editor/utils/toText';
import { Editor } from '@@editor/helpers';
import { MAX_HEIGHT, MAX_WIDTH } from '@@editor/plugins/serializable/constants';

import EmbedComponentLoader from '../../components/EmbedComponentLoader';
import OverlayIcon from './OverlayIcon';

const getImageSrc = (data) =>
    // have the original url, which could be an external url, as fallback
    get(data, 'embed.url') || get(data, 'src');

const LimitedHeightPreviewImage = styled(PreviewImage)({
    maxHeight: `${MAX_HEIGHT}px`,
    borderRadius: 0,
    width: '100%',
});

type Props = {
    element: ImageElement;
    inlineEdited?: boolean;
    editor: Editor;
    onLoad?: VoidFunction;
    onError?: VoidFunction;
};

const PreviewImageWrapper = ({ element, inlineEdited, onLoad, onError }: Props) => {
    const { data } = element;
    const embed = data.embed || {};

    const src = getImageSrc(data);
    const overlayIcon = get(data, 'embed.overlayIcon');

    const caption = element.children.find(Element.isEmbedCaptionElement);

    return (
        <React.Fragment>
            {overlayIcon && <OverlayIcon {...overlayIcon} />}
            <LimitedHeightPreviewImage
                transformations={{
                    ...embed,
                    mimetype: data.mimetype,
                    maxWidth: MAX_WIDTH,
                    maxHeight: MAX_HEIGHT,
                }}
                src={src}
                alt={toText(inlineEdited ? caption : embed.caption)}
                onLoad={onLoad}
                onError={onError}
            />
        </React.Fragment>
    );
};

export default (props: Props) => {
    const { element, editor } = props;
    const src = getImageSrc(element.data);

    if (src) {
        return (
            <EmbedComponentLoader editor={editor} element={element}>
                {({ onLoad, onError }) => (
                    <PreviewImageWrapper {...props} onLoad={onLoad} onError={onError} />
                )}
            </EmbedComponentLoader>
        );
    }

    return <PreviewImageWrapper {...props} />;
};
