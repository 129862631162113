import { z } from 'zod';

import {
    SectionLayouts as SectionLayoutsConst,
    SectionTypes as SectionTypesConst,
    SectionButtonTypes as SectionButtonTypesConst,
} from '@@routes/placement/constants';

import { Entity } from '../../utils/schemas/schemas';

export const Preset = Entity.extend({
    id: z.string().uuid(),
    presetName: z.string(),
    items: z.array(z.string()),
});

export type Preset = z.infer<typeof Preset>;

export const Bookmark = Entity.extend({
    id: z.string().uuid().optional(),
    metadataId: z.number(),
    relatedResourceId: z.string(),
    relatedResourceName: z.string(),
});

export type Bookmark = z.infer<typeof Bookmark>;

const SectionType = z.nativeEnum(SectionTypesConst);

export type SectionType = z.infer<typeof SectionType>;

export const SectionLayout = z.object({
    name: z.string(),
    tenantIds: z.array(z.number()),
});

export type SectionLayout = z.infer<typeof SectionLayout>;

export const SectionLayouts = z.nativeEnum(SectionLayoutsConst);

export type SectionLayouts = z.infer<typeof SectionLayouts>;

const BaseSection = Entity.extend({
    id: z.number(),
    type: SectionType,
    tenantId: z.number(),
    selfCategoryId: z.number(),
    title: z.string(),
    showTitle: z.boolean(),
    isSectionVisible: z.boolean(),
});

const SectionButtonBase = z.object({
    text: z.string(),
});

type SectionButtonBase = z.infer<typeof SectionButtonBase>;

const SectionCategoryButton = SectionButtonBase.extend({
    type: z.literal(SectionButtonTypesConst.CATEGORY),
    categoryId: z.number(),
});

const SectionTagButton = SectionButtonBase.extend({
    type: z.literal(SectionButtonTypesConst.TAG),
    tagId: z.number(),
});

const SectionInternalButton = SectionButtonBase.extend({
    type: z.literal(SectionButtonTypesConst.INTERNAL),
    metadataId: z.number(),
});

const SectionExternalButton = SectionButtonBase.extend({
    type: z.literal(SectionButtonTypesConst.EXTERNAL),
    url: z.string(),
});

const SectionButtonType = z.nativeEnum(SectionButtonTypesConst);

export type SectionButtonType = z.infer<typeof SectionButtonType>;

export type SectionCategoryButton = z.infer<typeof SectionCategoryButton>;
export type SectionTagButton = z.infer<typeof SectionTagButton>;
export type SectionInternalButton = z.infer<typeof SectionInternalButton>;
export type SectionExternalButton = z.infer<typeof SectionExternalButton>;

const SectionButton = z.union([
    SectionCategoryButton,
    SectionTagButton,
    SectionInternalButton,
    SectionExternalButton,
]);

export type SectionButton = z.infer<typeof SectionButton>;

export const DefaultSection = BaseSection.extend({
    type: z.union([
        z.literal(SectionTypesConst.DEFAULT),
        z.literal(SectionTypesConst.DEFAULT_CATEGORY),
    ]),
    layout: SectionLayouts,
    teaserCount: z.number(),
    isInfiniteScroll: z.boolean().nullable(),
    adsPositions: z
        .object({
            desktop: z.array(z.number()).nullable(),
        })
        .nullish(),
    isButtonVisible: z.boolean(),
    button: SectionButton.nullable(),
});

export type DefaultSection = z.infer<typeof DefaultSection>;
const NewDefaultSection = DefaultSection.omit({ id: true });

type NewDefaultSection = z.infer<typeof NewDefaultSection>;

export const CategorySection = DefaultSection.omit({ isInfiniteScroll: true }).extend({
    type: z.literal(SectionTypesConst.CATEGORY),
    pointerCategoryId: z.number(),
    pointerSectionId: z.number().nullish(),
});

export type CategorySection = z.infer<typeof CategorySection>;
const NewCategorySection = CategorySection.omit({ id: true });

type NewCategorySection = z.infer<typeof NewCategorySection>;

export const CustomSection = BaseSection.extend({
    type: z.literal(SectionTypesConst.CUSTOM),
    customTypeIdentifier: z.string(),
});

export type CustomSection = z.infer<typeof CustomSection>;

const NewCustomSection = CustomSection.omit({ id: true });

type NewCustomSection = z.infer<typeof NewCustomSection>;

export const Section = z.union([DefaultSection, CategorySection, CustomSection]);

export const NewSection = z.union([NewDefaultSection, NewCategorySection, NewCustomSection]);

export type NewSection = z.infer<typeof NewSection>;

export type Section = z.infer<typeof Section>;
export type UnitySection = z.infer<typeof Section>;
export type NewUnitySection = z.infer<typeof NewSection>;

export const isDefaultSection = (
    section?: Section | Pick<Section, 'type'>,
): section is DefaultSection =>
    section?.type === SectionTypesConst.DEFAULT ||
    section?.type === SectionTypesConst.DEFAULT_CATEGORY;

export const isNewDefaultSection = (section?: NewSection): section is NewDefaultSection =>
    section?.type === SectionTypesConst.DEFAULT ||
    section?.type === SectionTypesConst.DEFAULT_CATEGORY;

export const isCategorySection = (section?: Section): section is CategorySection =>
    section?.type === SectionTypesConst.CATEGORY;

export const isNewCategorySection = (section?: NewSection): section is NewCategorySection =>
    section?.type === SectionTypesConst.CATEGORY;

export const isCustomSection = (section?: Section): section is CustomSection =>
    section?.type === SectionTypesConst.CUSTOM;

export const isNewCustomSection = (section?: NewSection): section is NewCustomSection =>
    section?.type === SectionTypesConst.CUSTOM;

export const CategoryPage = z.object({
    id: z.number(),
    categoryId: z.number(),
    tenantId: z.number(),
    sections: z.array(Section),
    draft: z.boolean(),
});
export type CategoryPage = z.infer<typeof CategoryPage>;

const SectionContentItem = z.object({
    metadataId: z.number().nullish(),
    sectionContentItemType: z.string(),
    placeholderTenantId: z.number().nullish(),
    placeholderCategoryId: z.number().nullish(),
    placeholderIndexPosition: z.number().nullish(),
    publishedSince: z.string().optional(),
    pinned: z.boolean().optional(),
    deletedDate: z.string().optional(),
    unpublishedDate: z.string().optional(),
    placeholderType: z.string().nullish(),
    placeholderTitle: z.string().nullish(),
    placeholderIdentifier: z.string().nullish(),
});

export type SectionContentItem = z.infer<typeof SectionContentItem>;

export const SectionContent = z.object({
    sectionId: z.number(),
    tenantId: z.number(),
    categoryId: z.number(),
    content: z.array(SectionContentItem),
    updatedAt: z.string(),
    lastUpdatedBy: z.string().nullable(),
    draft: z.boolean(),
    previewUrl: z.string().nullable(),
});

export type SectionContent = z.infer<typeof SectionContent>;
