import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';

import { Position, StyledPreviewImage } from '@@containers/ContentItem/styles';

const PREVIEW_IMAGE_WIDTH = 84;
const PREVIEW_IMAGE_HEIGHT = 56;

const DEFAULT_POSITION = '';

export const ImgWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'reducedOpacity',
})(({ reducedOpacity }) => ({
    position: 'relative',
    alignSelf: 'flex-start',
    ...(reducedOpacity && { opacity: 0.3 }),
}));
export const ContentTeaserImage = (props) => {
    const { className, reducedOpacity, renderImage, entity, position } = props;

    return (
        <ImgWrapper className={className} reducedOpacity={reducedOpacity}>
            <Position>{position || DEFAULT_POSITION}</Position>

            {renderImage ? (
                renderImage(props)
            ) : (
                <StyledPreviewImage
                    src={get(entity, 'image.url')}
                    transformations={{
                        ...get(entity, 'image', {}),
                        maxWidth: PREVIEW_IMAGE_WIDTH,
                        maxHeight: PREVIEW_IMAGE_HEIGHT,
                    }}
                    previewAspectRatio={'3:2'}
                />
            )}
        </ImgWrapper>
    );
};

ContentTeaserImage.propTypes = {
    className: PropTypes.string,
    entity: PropTypes.object,
    position: PropTypes.number,
    reducedOpacity: PropTypes.bool,
    renderImage: PropTypes.func,
};

export default ContentTeaserImage;
