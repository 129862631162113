import { parseUrl, toURL } from '@@utils/URL';

const document = window.document;

const isTweetUrl = (value) =>
    /^http(?:s)?:\/\/(?:www\.)?(twitter|x)\.com\/[^<]*\/status\/.+/.test(value);

export const isTwitterUrl = (value) => {
    const url = toURL(value);

    if (url instanceof URL) {
        return /(twitter|x)\.com/.test(url.hostname);
    }
};

export const isTwitterEmbedCode = (code) => {
    const { url } = parseTwitterUrlOrEmbedCode(code);

    if (url instanceof URL) {
        return true;
    }
};

// https://help.twitter.com/de/managing-your-account/twitter-username-rules#
export const isValidTwitteUsername = (username) => /^[a-zA-Z0-9_]{1,15}$/.test(username);

export const parseTwitterUrlOrEmbedCode = (code) => {
    if (isTwitterUrl(code)) {
        return {
            url: parseUrl(code),
            dataset: {},
        };
    }

    const el = document.createElement('div');

    el.innerHTML = code;

    const [blockquote] = el.getElementsByTagName('blockquote');

    if (blockquote instanceof HTMLElement) {
        if (blockquote.className.match(/twitter-[tweet|video]/)) {
            const links = Array.from(blockquote.getElementsByTagName('a'));

            const tweetLink = links.find((link) => isTweetUrl(link.getAttribute('href')));

            if (tweetLink) {
                const tweetUrl = parseUrl(tweetLink.getAttribute('href'));

                if (tweetUrl) {
                    return {
                        url: tweetUrl,
                        dataset: blockquote.dataset,
                    };
                }
            }
        }
    }

    return {};
};
