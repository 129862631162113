import { useDispatch, useSelector } from '@@store/hooks';
import { getUserId } from '@@auth/authSlice';
import { setSetting } from '@@settings/settingsSlice';
import { type Preference } from '@@api/services/auth/schemas';
import { setLocale } from '@@lib/i18n/i18nSlice';
import { useAuthClient } from '@@api/services/auth/client';

const useHandleUserChange = () => {
    const userId = useSelector(getUserId);
    const dispatch = useDispatch();

    const dispatchPreferences = (preferences?: Preference) => {
        if (preferences) {
            dispatch(setLocale(preferences.uiLocale));
            dispatch(setSetting({ path: 'contentLocale', value: preferences.contentLocale }));
            dispatch(
                setSetting({ path: 'betaFeaturesEnabled', value: preferences.betaFeaturesEnabled }),
            );
            dispatch(setSetting({ path: 'collapsing', value: preferences.collapsing }));
            dispatch(setSetting({ path: 'filters', value: preferences.filters }));
        }
    };

    const { client: authClient, queryKeys: authKeys } = useAuthClient();
    const queryClient = authClient.useQueryClient();

    const params = { id: userId! };

    const fetchPreferences = () => {
        const client = authClient.initQueryClient(queryClient);

        return client.users.preference.get.fetchQuery({
            queryKey: authKeys.users.preference.get({ params }),
            queryData: { params },
        });
    };

    return { fetchPreferences, dispatchPreferences };
};

export default useHandleUserChange;
