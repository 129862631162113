import * as React from 'react';
import {
    FormControl,
    FormControlProps,
    FormHelperText,
    FormHelperTextProps,
    FormLabel,
    FormLabelProps,
    ToggleButtonGroup as MuiToggleButtonGroup,
    ToggleButtonGroupProps,
    styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FormFieldError } from '@@form/hooks/useReactHookFormFieldError';

import ToggleButtonRadio, { ToggleButtonRadioProps } from './ToggleButtonRadio';

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)({
    flexWrap: 'wrap',
});

export type ToggleButtonRadioGroupProps = Omit<ToggleButtonGroupProps, 'ref'> & {
    label?: FormLabelProps['children'];
    error?: FormFieldError;
    required?: FormControlProps['required'];
    disabled?: FormControlProps['disabled'];
    inputRef?: FormControlProps['ref'];
    helperText?: FormHelperTextProps['children'];
} & {
    fields: ToggleButtonRadioProps[];
    name: string;
};
const ToggleButtonGroup = (props: ToggleButtonRadioGroupProps) => {
    const { required, error, disabled, fields, label, name, inputRef, helperText, ...rest } = props;

    const { t } = useTranslation();

    const errorMessage = error?.[0];
    const message = errorMessage?.[0] ?? '';
    const replacements = errorMessage?.[1] ?? {};

    const helperTextValue = helperText || (errorMessage && t(message, replacements));

    return (
        <FormControl
            ref={inputRef}
            error={Boolean(error)}
            required={required}
            disabled={disabled}
            fullWidth
        >
            {label && <FormLabel id={name}>{label}</FormLabel>}
            <StyledToggleButtonGroup exclusive aria-labelledby={label ? name : undefined} {...rest}>
                {fields.map((field, index) => (
                    <ToggleButtonRadio
                        key={index}
                        value={field.value}
                        label={field.label}
                        disabled={disabled}
                    />
                ))}
            </StyledToggleButtonGroup>
            {helperTextValue && <FormHelperText>{helperTextValue}</FormHelperText>}
        </FormControl>
    );
};

export default ToggleButtonGroup;
