import { MutationCache, QueryCache, QueryClient } from '@tanstack/query-core';
import { ZodError } from 'zod';

import handleApiError, { isApiError } from '@@api/errorHandler';
import { HTTP_STATUS_CODES } from '@@constants/http';
import { store } from '@@scripts/store/store';
import { clearAuthentication } from '@@auth/authSlice';

import { FetcherValidationError } from './FetcherValidationError';

export const etagCache = new Map();

const onError = (error) => {
    if (isApiError(error) && error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
        store.dispatch(clearAuthentication());
    }

    handleApiError(error);
};
const queryCache = new QueryCache({
    onError,
});

const mutationCache = new MutationCache({
    onError,
});

const queryClient = new QueryClient({
    queryCache,
    mutationCache,
    defaultOptions: {
        queries: {
            // A 1 second cache helps with reducing duplicate requests on some scenarios,
            // while keeping the data fresh enough for most cases.
            staleTime: 1000,
            retry: (failureCount, error: any) => {
                // We want Zod errors to fail immediately
                // because no amount of retries will fix them.
                if (
                    error &&
                    (error instanceof ZodError || error instanceof FetcherValidationError)
                ) {
                    return false;
                }

                return failureCount < 3;
            },
        },
    },
});

export default queryClient;
