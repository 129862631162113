import { useQuery } from '@tanstack/react-query';

import config from '@@config';

const useVersion = () => {
    const url = `${window.location.origin}/version`;

    const query = () => fetch(url, { method: 'GET' }).then((res) => res.text());
    const refetchInterval = 60000;
    const { data } = useQuery({
        queryKey: ['version'],
        queryFn: query,
        refetchInterval,
        enabled: process.env.NODE_ENV === 'production' && !config.isVisualTest,
    });

    const isNewVersionAvailable = Boolean(data && data !== VERSION);

    return {
        newVersion: data,
        isNewVersionAvailable,
    };
};

export default useVersion;
