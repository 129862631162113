import { z } from 'zod';

import { Creator, Entity } from '../../utils/schemas/schemas';
import { FilerepoFile } from '../metadata/schemas';

export const ATTACHMENT_STATE = {
    USED: 'USED',
    UNUSED: 'UNUSED',
} as const;

export const AttachmentState = z.nativeEnum(ATTACHMENT_STATE);
export type AttachmentState = z.infer<typeof AttachmentState>;

export const SOURCE_TYPE = {
    FILE_REPO: 'filerepo',
} as const;
export const SourceType = z.nativeEnum(SOURCE_TYPE);

export const Attachment = Entity.extend({
    id: z.string(),
    sourceId: z.string(),
    sourceType: SourceType,
    state: AttachmentState,
    creator: Creator,
    fileMetadata: FilerepoFile.omit({ _links: true }),
    sourceUrl: z.string(),
});
export type Attachment = z.infer<typeof Attachment>;
