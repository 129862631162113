import React, { useState } from 'react';
import { Transforms } from 'slate';
import { styled } from '@mui/material';

import PreviewImage from '@@containers/PreviewImage';
import { Node, ReactEditor } from '@@editor/helpers';
import Spacer from '@@components/Spacer';

import { PluginsWrapper } from '../../components/PluginsWrapper';
import InlineTeaserItem from './InlineTeaserItem';
import { MAX_HEIGHT, MAX_WIDTH } from '../../constants';
import RelatedContent from './RelatedContent';

const StyledPluginsWrapper = styled(PluginsWrapper)({
    display: 'flex',
    flexDirection: 'column',
});

const OuterWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
});

const InnerWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(0, 0, 2),
}));

const InlineDynamicTeaser = (props) => {
    const { children, editor, element } = props;
    const { image, metadataIds } = element.data;
    const [contentIds, setContentIds] = useState(metadataIds);
    const readOnly = ReactEditor.isReadOnly(editor);
    const path = ReactEditor.findPath(editor, element);
    const node = Node.get(editor, path);

    const handleContentsChange = (formData) => {
        setContentIds(formData);

        Transforms.setNodes(
            editor,
            { ...node, data: { ...node.data, metadataIds: formData } },
            { at: path },
        );
    };

    return (
        <StyledPluginsWrapper>
            {children}

            <OuterWrapper contentEditable={false}>
                {image?.url && (
                    <>
                        <PreviewImage
                            src={image.url}
                            transformations={{
                                ...image,
                                maxWidth: MAX_WIDTH,
                                maxHeight: MAX_HEIGHT,
                            }}
                        />

                        <Spacer v md />
                    </>
                )}

                <InnerWrapper>
                    <RelatedContent
                        readOnly={readOnly}
                        columns={
                            image?.url && [
                                {
                                    fieldName: 'id',
                                    render: (props) => (
                                        <InlineTeaserItem
                                            key={props.rowIndex}
                                            metadataId={props.record.id}
                                            onChange={handleContentsChange}
                                            value={contentIds}
                                            readOnly={readOnly}
                                        />
                                    ),
                                },
                            ]
                        }
                        value={contentIds}
                        onChange={handleContentsChange}
                    />
                </InnerWrapper>
            </OuterWrapper>
        </StyledPluginsWrapper>
    );
};

export default InlineDynamicTeaser;
