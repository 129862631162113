import { getTomorrowISO, getDayAfterTomorrowISO } from '@@routes/editions/utils';
import { EditionStatus } from '@@routes/editions/constants';
import {
    NewEditionTargetEdition,
    type EditionTargetEdition,
    UnityEditionTargetEdition,
    NewUnityEditionTargetEdition,
} from '@@api/services/editions/schemas';

export const getEditionTargetEditionDefaults = (): NewEditionTargetEdition => ({
    status: EditionStatus.DRAFT,
    publishOn: getTomorrowISO(),
    nextEditionOn: getDayAfterTomorrowISO(),
    sections: [],
});

export const serializeEditionTargetEdition = (
    edition: EditionTargetEdition | NewEditionTargetEdition,
): UnityEditionTargetEdition | NewUnityEditionTargetEdition => ({
    ...edition,
    sections: edition.sections.map((section) => ({
        ...section,
        content: section.content.map((content) => ({
            type: 'metadata',
            metadataId: content,
        })),
    })),
});

export const deserializeEditionTargetEdition = (
    edition: UnityEditionTargetEdition,
): EditionTargetEdition => ({
    ...edition,
    sections: edition.sections.map((section) => ({
        ...section,
        content: section.content.map((content) => content.metadataId),
    })),
});
