import { z } from 'zod';

export const UserPresenceUser = z.object({
    attributes: z
        .object({
            isFormDirty: z.boolean(),
            isInactive: z.boolean(),
            lastSaved: z.string().nullable(),
        })
        .nullable(),
    firstName: z.string(),
    lastName: z.string(),
});

export type UserPresenceUser = z.infer<typeof UserPresenceUser>;
const UserPresenceUsers = z.record(z.string(), UserPresenceUser);

export type UserPresenceUsers = z.infer<typeof UserPresenceUsers>;
export const UserPresence = z.object({
    path: z.string().optional(),
    users: UserPresenceUsers.optional(),
});

export type UserPresence = z.infer<typeof UserPresence>;
const ActiveUser = UserPresenceUser.extend({
    id: z.string(),
    initials: z.string(),
    fullName: z.string(),
});

export type ActiveUser = z.infer<typeof ActiveUser>;
