import { debounce, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { keepPreviousData } from '@tanstack/react-query';

import config from '@@config';
import useDeepCompareMemo from '@@hooks/useDeepCompareMemo';
import { type Tag } from '@@api/services/metadata/schemas';
import Autocomplete, { AutocompleteProps } from '@@form/components/Autocomplete';
import { MetadataRouter, useMetadataClient } from '@@api/services/metadata/client';
import { getQueryParams } from '@@api/utils/getQueryParams';

type MultipleTagsAutocompleteProps = Pick<
    AutocompleteProps<Tag>,
    'placeholder' | 'disabled' | 'required' | 'label' | 'fullWidth' | 'inputRef'
> & {
    multiple?: true;
    value: Tag['id'][];
    onChange: (value: Tag['id'][]) => void;
};

export type TagAutocompleteProps = Pick<
    AutocompleteProps<Tag>,
    'placeholder' | 'disabled' | 'required' | 'label' | 'fullWidth' | 'inputRef'
> & {
    multiple: false;
    value: Tag['id'] | null;
    onChange: (value: Tag['id'] | null) => void;
};

export type TagsAutocompleteProps = TagAutocompleteProps | MultipleTagsAutocompleteProps;

const TagsAutocomplete = (props: TagsAutocompleteProps) => {
    const { value, onChange, multiple = true, ...rest } = props;
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const { t } = useTranslation();
    const [filter, setFilter] = useState('');
    const hasMinCharacters = filter.length >= 2;

    const memoizedParams = useDeepCompareMemo(
        () =>
            getQueryParams<MetadataRouter['tags']['getAll']>({
                active: true,
                q: filter,
                size: 20,
            }),
        [filter],
    );

    const { data: tagData, isLoading } = metadataClient.tags.getAll.useQuery({
        queryKey: metadataKeys.tags.getAll({ query: memoizedParams }),
        queryData: { query: memoizedParams },
        enabled: hasMinCharacters,
        placeholderData: keepPreviousData,
    });
    const tags = tagData?.body.content;

    const memoizedTags = React.useMemo(() => tags || [], [tags]);

    const includeIds = value?.toString();

    const query = getQueryParams<MetadataRouter['tags']['getAll']>({
        includeIds,
    });

    const { data: selectedTagData, isLoading: isSelectedTagsLoading } =
        metadataClient.tags.getAll.useQuery({
            queryKey: metadataKeys.tags.getAll({ query }),
            queryData: { query },
            enabled: Boolean(includeIds),
            placeholderData: keepPreviousData,
        });

    const selectedTags = selectedTagData?.body.content;

    const memoizedSelectedTags = React.useMemo(() => selectedTags || [], [selectedTags]);

    const setFilterDebounced = debounce(setFilter, config.searchDebounce);

    const combinedTagsList = uniqBy([...memoizedTags, ...memoizedSelectedTags], 'id');
    const tagsList = hasMinCharacters ? combinedTagsList : memoizedSelectedTags;

    const currentValue = React.useMemo(() => {
        if (!value) {
            return multiple ? [] : null;
        }

        if (Array.isArray(value)) {
            return value
                .map((item) => memoizedSelectedTags.find((tag) => tag.id === item))
                .filter((x): x is NonNullable<typeof x> => Boolean(x));
        }

        return memoizedSelectedTags.find((tag) => tag.id === value);
    }, [value, memoizedSelectedTags, multiple]);

    const handleChange = (event, value) => {
        setFilter('');

        const adjustedValue = Array.isArray(value) ? value.map((item) => item.id) : value.id;

        onChange(adjustedValue);
    };

    const handleInputChange = (event, value: string) => {
        setFilterDebounced(value);
    };

    return (
        <Autocomplete
            key={Array.isArray(currentValue) ? currentValue.join(',') : currentValue?.toString()}
            multiple={multiple}
            noOptionsText={
                hasMinCharacters ? t('metaform.tags.nonefound') : t('search.minCharacters')
            }
            loading={isLoading || isSelectedTagsLoading}
            value={currentValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            options={tagsList}
            getOptionLabel={(option: Tag | null) => option?.name ?? ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            filterOptions={(x) => x}
            filterSelectedOptions
            {...rest}
        />
    );
};

export default TagsAutocomplete;
