import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { z } from 'zod';
import { useStore } from 'react-redux';

import config from '@@config';
import { DefaultHeaders } from '@@api/headers';
import {
    MaintenanceNote,
    Quote,
    ReleaseNote,
    ReleaseNoteConfig,
    UnityMaintenanceNote,
    UnityReleaseNote,
} from '@@api/services/releasenotes/schemas';
import {
    deserializeMaintenancenote,
    deserializeReleasenote,
    serializeMaintenancenote,
    serializeReleasenote,
} from '@@api/services/releasenotes/transformers';
import { PaginationParams } from '@@api/utils/schemas/queryParams';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { paginated } from '@@api/utils/schemas/utils';
import { RootState } from '@@scripts/store/store';
import { commonResponses } from '@@api/commonResponses';
import { getQueryKeys } from '@@api/utils/queryKeys';

const contract = initContract();

const router = contract.router(
    {
        maintenanceNotes: contract.router({
            getAll: {
                method: 'GET',
                path: '/maintenancenotes',
                responses: {
                    200: z.array(UnityMaintenanceNote.transform(deserializeMaintenancenote)),
                },
            },
            get: {
                method: 'GET',
                path: '/maintenancenotes/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: UnityMaintenanceNote.transform(deserializeMaintenancenote),
                },
            },
            put: {
                method: 'PUT',
                path: '/maintenancenotes/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: MaintenanceNote.transform(serializeMaintenancenote),
                responses: {
                    200: UnityMaintenanceNote.transform(deserializeMaintenancenote),
                },
            },
            post: {
                method: 'POST',
                path: '/maintenancenotes',
                body: MaintenanceNote.transform(serializeMaintenancenote),
                responses: {
                    201: UnityMaintenanceNote.transform(deserializeMaintenancenote),
                },
            },
        }),
        quotes: contract.router({
            getAll: {
                method: 'GET',
                path: '/quotes',
                query: z.object({
                    date: z.string(),
                }),
                responses: {
                    200: z.array(Quote),
                },
            },
        }),
        releasenote: contract.router({
            getAll: {
                method: 'GET',
                path: '/releasenotes',
                query: z.object({
                    ...PaginationParams.pick({ sort: true }).shape,
                }),
                responses: {
                    200: paginated(UnityReleaseNote.transform(deserializeReleasenote)),
                },
            },
            get: {
                method: 'GET',
                path: '/releasenotes/:id',
                pathParams: z.object({
                    id: z.union([z.coerce.number(), z.string()]),
                }),
                responses: {
                    200: UnityReleaseNote.transform(deserializeReleasenote),
                },
            },
            put: {
                method: 'PUT',
                path: '/releasenotes/:id',
                pathParams: z.object({
                    id: z.union([z.coerce.number(), z.string()]),
                }),
                body: ReleaseNote.transform(serializeReleasenote),
                responses: {
                    200: UnityReleaseNote.transform(deserializeReleasenote),
                },
            },
            post: {
                method: 'POST',
                path: '/releasenotes',
                body: ReleaseNote.transform(serializeReleasenote),
                responses: {
                    201: UnityReleaseNote.transform(deserializeReleasenote),
                },
            },
        }),
        config: contract.router({
            get: {
                method: 'GET',
                path: '/config',
                responses: {
                    200: ReleaseNoteConfig,
                },
            },
            put: {
                method: 'PUT',
                path: '/config',
                body: ReleaseNoteConfig,
                responses: {
                    200: ReleaseNoteConfig,
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type ReleaseNoteRouter = typeof router;

const releaseNotesClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.releasenotesUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useReleaseNotesClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return releaseNotesClient(state);
};
