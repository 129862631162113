import React from 'react';
import { styled } from '@mui/material';

import { getDefaultTeaserVariant } from '@@containers/Teaser/utils';
import { useMetadataClient } from '@@api/services/metadata/client';

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: 'transparent',
    borderLeft: `3px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(1, 3),
    margin: theme.spacing(2, 0),
}));

const Title = styled('div')(({ theme }) => ({
    ...theme.typography.editorPrimarySmall,
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
}));

const TitleHeader = styled('div')(({ theme }) => ({
    ...theme.typography.editorPrimarySmall,
    color: theme.palette.primary.dark,
}));

type Props =
    | {
          metadataId: number;
      }
    | {
          metadataId: number;
          onChange: (value: number[]) => void;
          value: number[];
      };

const TeaserItem = (props: Props) => {
    const { metadataId } = props;
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const params = { id: metadataId };
    const { data, isLoading } = metadataClient.metadata.get.useQuery({
        queryKey: metadataKeys.metadata.get({ params, query: {} }),
        queryData: { params },
    });
    const teasers = data?.body.teasers;

    const teaser = getDefaultTeaserVariant(teasers);

    return !isLoading && teaser ? (
        <Wrapper>
            <TitleHeader>{teaser.titleHeader}</TitleHeader>
            <Title>{teaser.title}</Title>
        </Wrapper>
    ) : null;
};

export default TeaserItem;
