import React, { HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import { Fade, Popper, styled } from '@mui/material';
import { useReadOnly } from 'slate-react';

import { Element, LinkElement } from '@@editor/helpers/Element';
import { type Metadata } from '@@api/services/metadata/schemas';
import { getPluginData } from '@@editor/plugins/spellChecker/utils/data';
import { Editor, ReactEditor } from '@@editor/helpers';
import { useMetadataClient } from '@@api/services/metadata/client';

import LinkDetails from './LinkDetails';

const buildInternalLink = ({ resourceName, resourceId }: Metadata): string => {
    switch (resourceName) {
        case 'links':
        case 'videos':
        case 'embeds':
            return `/externalContent/${resourceName}/${resourceId}`;
        default:
            return `/${resourceName}/${resourceId}`;
    }
};

// MUI Link component is not used here because if selected comment creation is not working

const Link = styled('a')(({ theme }) => ({
    color: theme.palette.secondary.dark,
    '&:hover': {
        color: theme.palette.secondary.main,
    },
    textDecoration: 'underline',
    textUnderlineOffset: theme.spacing(1),
}));

type Props = {
    element: LinkElement;
    attributes: HTMLAttributes<HTMLAnchorElement>;
    children?: ReactNode;
    editor: Editor;
};

const POPPER_ID = 'link-popper';

const RenderedLink = (props: Props) => {
    const { attributes, children, element, editor } = props;
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();
    const anchorEl = useRef<HTMLAnchorElement>(null);

    const [popperOpen, setPopperOpen] = useState(false);

    const isElementSelected = ReactEditor.isElementSelected(editor, element);

    useEffect(() => {
        // Update popper state only when anchorEl is set and the element is selected
        if (anchorEl.current && isElementSelected) {
            setPopperOpen(true);
        } else {
            setPopperOpen(false);
        }
    }, [isElementSelected]);

    const href = Element.isExternalLinkElement(element) ? element.data.href : '';
    const metadataId = Element.isInternalLinkElement(element) ? element.data.metadataId : null;

    const params = { id: metadataId! };

    const { data: metadata } = metadataClient.metadata.get.useQuery({
        queryKey: metadataKeys.metadata.get({ params, query: {} }),
        queryData: { params },
        enabled: Boolean(metadataId),
    });

    const data = metadata?.body;

    const isSpellCheckerEnabled = getPluginData(editor, 'isEnabled');
    const isReadOnly = useReadOnly();

    // final href depends on external vs internal link
    const finalHref = data ? buildInternalLink(data) : href;

    const renderLink = (props = {}) => (
        <Link
            href={finalHref}
            target="_blank"
            title={`${data ? 'Internal' : 'External'} link: ${finalHref}`}
            {...attributes}
            {...props}
        >
            {children}
        </Link>
    );

    return isReadOnly || isSpellCheckerEnabled ? (
        renderLink()
    ) : (
        <span>
            {renderLink({ 'aria-describedby': POPPER_ID, ref: anchorEl })}

            <Popper
                transition
                id={POPPER_ID}
                open={popperOpen}
                anchorEl={anchorEl.current}
                placement="bottom-start"
                sx={(theme) => ({
                    zIndex: theme.zIndex.layer1,
                })}
                disablePortal
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                        <span>
                            <LinkDetails
                                metadataId={metadataId}
                                url={href}
                                element={element}
                                editor={editor}
                            />
                        </span>
                    </Fade>
                )}
            </Popper>
        </span>
    );
};

export default RenderedLink;
