import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';

import usePrevious from '@@hooks/usePrevious';
import buildImageTransformationUrl, {
    IImageTransformations,
} from '@@utils/buildImageTransformationUrl';
import useDebouncedValue from '@@hooks/useDebouncedValue';
import { type CropMarks, type Image } from '@@api/utils/schemas/schemas';

import { DEFAULT_IMAGE_SIZES, DEFAULT_CROP_MARKS, DEFAULT_FOCUS_POINT } from './constants';
import ImageCrop, { DEFAULT_IMAGE_SIZE } from './ImageCrop/ImageCrop';
import { serializeCropMarks, deserializeCropMarks, getAspectRatioNumber } from './ImageCrop/utils';
import FocusPoint from './FocusPoint/FocusPoint';
import { serializeFocusPoint, deserializeFocusPoint } from './FocusPoint/utils';
import {
    isPointWithinBounds,
    hasSignificantlyChangedCropMarks,
    createRatioCropMarks,
} from './utils';

const Wrapper = styled('div')(({ theme }) => ({
    minWidth: '100px',
    minHeight: '100px',
    background: theme.palette.primary['200'],
}));

const DOC_MOVE_OPTS = { capture: true, passive: false };

type Value = Image & { src: string };

type Props = {
    aspectRatio: string;
    className?: string;
    value: Value;
    onChange: (value: Value) => void;
    onLoad?: React.ReactEventHandler<HTMLImageElement>;
    setCroppingRatio?: (ratio: string) => void;
    transformations?: IImageTransformations;
    disableFocusPoint?: boolean;
    small?: boolean;
};

const ImageEditor = ({
    value: originalValue,
    className,
    aspectRatio,
    setCroppingRatio,
    transformations,
    onChange: originalOnChange,
    disableFocusPoint,
    onLoad = () => {},
}: Props) => {
    const { value, onChange } = useDebouncedValue({
        value: originalValue,
        deserialize: (value) => ({
            ...(value || {}),
            cropMarks: deserializeCropMarks(value.cropMarks || DEFAULT_CROP_MARKS),
        }),
        serialize: (value) => ({
            ...(value || {}),
            cropMarks: serializeCropMarks(value.cropMarks),
        }),
        onChange: originalOnChange,
    });

    const { src, cropMarks } = value;
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const image = useRef<HTMLImageElement | null>(null);

    const isPointerDownRef = useRef(false);
    const wasDndPointerEventRef = useRef(false);
    const isFirstCompleteCallAfterImageLoadedRef = useRef(false);
    const isFirstChangeCallAfterImageLoadedRef = useRef(false);

    const prevCropMarksRef = useRef<CropMarks | null>(null);
    const previousAspectRatio = usePrevious(aspectRatio);
    const aspectRatioNumber = getAspectRatioNumber(aspectRatio);

    const ref = useRef<HTMLDivElement>(null);

    const handleMouseMove = (e) => {
        setX(e.clientX);
        setY(e.clientY);
    };

    const handleChangeFocusPoint = (focusPoint) => {
        onChange({
            ...value,
            focusPoint,
        });
    };

    const handleChangeCropMarks = useCallback(
        (cropMarks) => {
            if (isFirstChangeCallAfterImageLoadedRef.current) {
                isFirstChangeCallAfterImageLoadedRef.current = false;

                if (aspectRatio) {
                    // We have to compare rounded aspect ratios because we do not crop and store it
                    // precise enough
                    const TWO_DECIMALS = 100;
                    const round = (number) => Math.round(number * TWO_DECIMALS) / TWO_DECIMALS;
                    const aspect = round(aspectRatioNumber);

                    const hasWrongAspectRatio = () => {
                        if (!image.current) {
                            return;
                        }
                        const imageAspect = round(
                            (image.current.width * cropMarks.width) /
                                (image.current.height * cropMarks.height),
                        );

                        return imageAspect !== aspect;
                    };

                    if (hasWrongAspectRatio()) {
                        const newCropMarks = createRatioCropMarks(aspectRatioNumber, image.current);
                        // Since onChange is debounced, we need to perform the updates
                        // for cropMarks and focusPoints together
                        // otherwise one onChange will overwrite the other

                        onChange({
                            ...value,
                            cropMarks: newCropMarks,
                            focusPoint: DEFAULT_FOCUS_POINT,
                        });

                        return;
                    }
                }
            }

            onChange({
                ...value,
                cropMarks,
            });
        },
        [aspectRatio, onChange, value],
    );

    useEffect(() => {
        const handleDocPointerDown = () => {
            isPointerDownRef.current = true;
            wasDndPointerEventRef.current = false;
        };

        const handleDocPointerMove = () => {
            if (isPointerDownRef.current) {
                wasDndPointerEventRef.current = true;
            }
        };

        const handleDocPointerDone = () => {
            isPointerDownRef.current = false;
        };

        document.addEventListener('pointerdown', handleDocPointerDown, DOC_MOVE_OPTS);
        document.addEventListener('pointermove', handleDocPointerMove, DOC_MOVE_OPTS);
        document.addEventListener('pointerup', handleDocPointerDone, DOC_MOVE_OPTS);
        document.addEventListener('pointercancel', handleDocPointerDone, DOC_MOVE_OPTS);

        return () => {
            document.removeEventListener('pointerdown', handleDocPointerDown, DOC_MOVE_OPTS);
            document.removeEventListener('pointermove', handleDocPointerMove, DOC_MOVE_OPTS);
            document.removeEventListener('pointerup', handleDocPointerDone, DOC_MOVE_OPTS);
            document.removeEventListener('pointercancel', handleDocPointerDone, DOC_MOVE_OPTS);
        };
    }, []);

    useEffect(() => {
        if (aspectRatio) {
            if (previousAspectRatio !== aspectRatio) {
                if (image.current) {
                    const newCropMarks = createRatioCropMarks(aspectRatioNumber, image.current);

                    handleChangeCropMarks(newCropMarks);
                }

                const croppingRatio = Number(aspectRatio).toFixed(4);

                if (setCroppingRatio) {
                    setCroppingRatio(croppingRatio);
                }
            }
        }
    }, [aspectRatio, handleChangeCropMarks, previousAspectRatio, setCroppingRatio]);

    const handleComplete = (crop: CropMarks, percentageCrop: CropMarks) => {
        // `handleComplete` is called when the image has been loaded, but also when the user
        // did interact with the image. We only want to process user interaction here, therefore
        // we return here if this was the first `handleComplete` call after the image has been
        // loaded.
        if (!ref.current || isFirstCompleteCallAfterImageLoadedRef.current) {
            isFirstCompleteCallAfterImageLoadedRef.current = false;

            return;
        }

        const containerClientRect = ref.current.getBoundingClientRect();

        const clickPoint = {
            x: x - containerClientRect.x,
            y: y - containerClientRect.y,
        };

        const prevFocusPoint = value.focusPoint;
        const prevCropMarks = prevCropMarksRef.current;
        const nextFocusPoint = serializeFocusPoint(clickPoint, ref.current);
        const nextCropMarks = serializeCropMarks(percentageCrop);

        if (isPointWithinBounds(prevFocusPoint, nextCropMarks)) {
            if (hasSignificantlyChangedCropMarks(prevCropMarks, nextCropMarks)) {
                // The user has changed the crop selection, but the focus point is still within
                // the crop area. Nothing needs to be done, everything is fine. It means the user
                // has either resized or moved the crop selection.
            } else if (
                isPointWithinBounds(nextFocusPoint, nextCropMarks) &&
                !wasDndPointerEventRef.current
            ) {
                // The user has not changed the crop selection and the next focus point would
                // be located within the crop area, we will apply that new position to the
                // focus point. It means the user has clicked somewhere within the crop
                // area.
                handleChangeFocusPoint(nextFocusPoint);
            }
        } else {
            // If the focus point would be placed outside of the crop selection (which is not
            // allowed), we will move the focus point to the center of the crop selection.
            handleChangeFocusPoint({
                x: nextCropMarks.x + nextCropMarks.width / 2,
                y: nextCropMarks.y + nextCropMarks.height / 2,
            });
        }

        prevCropMarksRef.current = nextCropMarks as CropMarks;
        wasDndPointerEventRef.current = false;
    };

    const handleImageLoaded = (imageValue) => {
        isFirstCompleteCallAfterImageLoadedRef.current = true;
        isFirstChangeCallAfterImageLoadedRef.current = true;
        image.current = imageValue;
        onLoad(imageValue);
    };

    const handleImageError = () => {
        image.current = null;
    };

    const point = deserializeFocusPoint(value.focusPoint || DEFAULT_FOCUS_POINT);
    const transformedSrc = buildImageTransformationUrl(
        src,
        transformations ||
            ({
                maxWidth: DEFAULT_IMAGE_SIZE,
                maxHeight: DEFAULT_IMAGE_SIZE,
            } as IImageTransformations),
    );

    return (
        <Wrapper ref={ref} className={className} onMouseMove={handleMouseMove}>
            {!disableFocusPoint && <FocusPoint value={point} />}
            <ImageCrop
                src={transformedSrc}
                aspectRatio={aspectRatio}
                setCroppingRatio={setCroppingRatio}
                value={cropMarks}
                onImageLoaded={handleImageLoaded}
                onImageError={handleImageError}
                onChange={handleChangeCropMarks}
                onComplete={handleComplete}
                small={disableFocusPoint}
            />
        </Wrapper>
    );
};

export default styled(ImageEditor)({
    position: 'relative',
    img: {
        maxWidth: DEFAULT_IMAGE_SIZES.maxWidth,
        maxHeight: DEFAULT_IMAGE_SIZES.maxHeight,
    },
});
