import { centerCrop, makeAspectCrop } from 'react-image-crop';

import { isBetween } from '@@utils/number';

export const isPointWithinBounds = (point, boundingRect) => {
    const { x, y, width, height } = boundingRect;

    const minX = x;
    const maxX = minX + width;
    const minY = y;
    const maxY = minY + height;

    return isBetween(point.x, minX, maxX) && isBetween(point.y, minY, maxY);
};

export const hasSignificantlyChangedCropMarks = (prevRect, nextRect) => {
    if (!prevRect) {
        return false;
    }

    // prevents marginal drag movement to be ignored on focus point placement
    const THRESHOLD_PERC = 0.005;

    // point distance
    const dQX1 = Math.pow(prevRect.x - nextRect.x, 2);
    const dQY1 = Math.pow(prevRect.y - nextRect.y, 2);
    const dOrigin = Math.sqrt(dQX1 + dQY1);

    const dQX2 = Math.pow(prevRect.x + prevRect.width - (nextRect.x + nextRect.width), 2);
    const dQY2 = Math.pow(prevRect.y + prevRect.height - (nextRect.y + nextRect.height), 2);
    const dBRCorner = Math.sqrt(dQX2 + dQY2);

    return dOrigin > THRESHOLD_PERC || dBRCorner > THRESHOLD_PERC;
};

export const createRatioCropMarks = (aspectRatio, imgEl) => {
    const imageAspect = imgEl.width / imgEl.height;
    const aspect = Number(aspectRatio);

    const crop = centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                height: aspect && imageAspect < aspect ? 0 : imgEl.height,
                width: aspect && imageAspect > aspect ? 0 : imgEl.width,
            },
            aspect || imageAspect,
            imgEl.width,
            imgEl.height,
        ),
        imgEl.width,
        imgEl.height,
    );

    delete (crop as { unit?: string }).unit;

    return crop;
};
