import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { MS, MINUTE, HOUR, DAY, type DateTimeType, DATE_TIME_TYPES } from './constants';
import { DATETIME } from './formats';
import { formatDate, formatPastDate, isCurrentWeek, formatTargetDateTime } from './utils';

type Props = {
    format?: string;
    live?: boolean;
    className?: string;
    date?: string;
    fullDatePrefix?: string;
    relative?: boolean;
    titlePrefix?: string;
    type?: DateTimeType;
};

export const DateTime: React.FC<Props> = ({
    format = DATETIME,
    live = true,
    className,
    date,
    fullDatePrefix,
    relative,
    titlePrefix,
    type,
}) => {
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (live) {
            tick();
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
            }
        };
    }, []);

    useEffect(() => {
        if (!live && timeoutId) {
            clearTimeout(timeoutId);
        }
    }, [live, date]);

    const parseDate = (date) => moment(date).valueOf();

    const tick = () => {
        const now = moment.now();
        const seconds = Math.round(Math.abs(now - parseDate(date)) / MS);

        let timer;

        if (seconds < MINUTE) {
            timer = MS;
        } else if (seconds < HOUR) {
            timer = MS * MINUTE;
        } else if (seconds < DAY) {
            timer = MS * HOUR;
        }

        if (timer) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setTimeoutId(setTimeout(tick, timer));
        }
    };

    if (!moment(date).isValid()) {
        console.warn('Invalid date provided');

        return <span>{moment(date).format()}</span>;
    }

    let parsed;

    switch (type) {
        case DATE_TIME_TYPES.past: {
            parsed = formatPastDate(date, relative);
            break;
        }

        case DATE_TIME_TYPES.targetDateTime: {
            parsed = formatTargetDateTime(date);
            break;
        }

        default: {
            parsed = formatDate(date, format);
        }
    }

    const timeTitle = formatDate(date, DATETIME);

    const titleDate = titlePrefix ? titlePrefix.concat(' ', timeTitle) : timeTitle;

    return (
        <span className={className}>
            {fullDatePrefix && !isCurrentWeek(date) && type === 'past' && (
                <span>{fullDatePrefix}&nbsp;</span>
            )}
            <time dateTime={date} title={titleDate}>
                {parsed}
            </time>
        </span>
    );
};

export default DateTime;
