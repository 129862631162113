import { MIME_TYPES } from '@@editor/helpers/Element';
import { isThreadsEmbedCode } from '@@utils/socialMedia/threads';
import { GenerateEmbedBlockData } from '@@editor/typings/Embed';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ originalSrc, src }) => {
    if (!isThreadsEmbedCode(src)) {
        return;
    }

    return {
        mimetype: MIME_TYPES.THREADS,
        src: (src as URL).href,
        originalSrc,
        options: {},
    };
};
