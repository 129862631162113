import { parseUrl, toURL } from '@@utils/URL';

export const isThreadsUrl = (url) => {
    const parsedUrl = toURL(url);

    if (parsedUrl instanceof URL) {
        return /threads\.net/.test(parsedUrl.hostname);
    }
};

export const isThreadsEmbedCode = (code) => {
    const url = parseThreadsUrlOrEmbedCode(code);

    if (url instanceof URL) {
        return true;
    }
};

export const parseThreadsUrlOrEmbedCode = (code) => {
    if (isThreadsUrl(code)) {
        return parseUrl(code);
    }

    const el = document.createElement('div');

    el.innerHTML = code;

    const [blockquote] = el.getElementsByTagName('blockquote');

    if (blockquote instanceof HTMLElement) {
        if (blockquote.className.match(/text-post-media/)) {
            const src = blockquote.getAttribute('data-text-post-permalink');

            const url = parseUrl(src);

            if (isThreadsUrl(url)) {
                return url;
            }
        }
    }
};
