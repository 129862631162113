import { stripUnit } from 'polished';
import React from 'react';
import invariant from 'tiny-invariant';
import { alpha, styled, Stack } from '@mui/material';

import Icon from '@@components/Icon';

type FocusPointValue = {
    x: string;
    y: string;
};

type Props = {
    value: FocusPointValue;
    color?: string;
};

export const Ring = styled('div')<{ $diameter: string }>(({ $diameter, theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: $diameter,
    width: $diameter,
    borderRadius: '50%',
    border: '1px solid white',
    boxShadow: theme.fixed.image.boxShadow,
}));

const AbsoluteStack = styled(Stack)<{
    $color?: string;
    $edgeLength: string;
    $value: FocusPointValue;
}>(({ $value, $edgeLength, theme, $color }) => ({
    pointerEvents: 'none',
    position: 'absolute',
    left: `calc(${$value.x} - ${Number(stripUnit($edgeLength)) / 2}px)`,
    top: `calc(${$value.y} - ${Number(stripUnit($edgeLength)) / 2}px)`,
    zIndex: theme.zIndex.layer1,
    color: 'white',
    [Ring]: {
        mixBlendMode: 'difference',
        borderColor: $color || 'white',
    },
    [Icon]: {
        color: $color || 'white',
    },
}));

const InnerRing = styled(Ring)(({ theme }) => ({
    backgroundColor: alpha(theme.palette.primary.main, theme.opacityFactors.low),
    border: '2px solid white',
}));

const FocusPoint: React.FC<Props> = ({ value, color }) => {
    invariant(value.x, 'Invalid coordinates provided');

    return (
        <AbsoluteStack direction="row" $edgeLength="80px" $value={value} $color={color}>
            <Ring $diameter="80px">
                <InnerRing $diameter="36px">
                    <Icon name="crosshairs-sharp" />
                </InnerRing>
            </Ring>
        </AbsoluteStack>
    );
};

FocusPoint.defaultProps = {
    value: {
        x: '50%',
        y: '50%',
    },
};

export default FocusPoint;
