import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { styled } from '@mui/material';
import { type Descendant } from 'slate';

import { useSelector } from '@@store/hooks';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';
import {
    PLUGIN_NAMES,
    type PluginOptions,
    type PluginList,
    type PluginName,
} from '@@editor/typings/UnityPlugins';
import { getContentLocale } from '@@lib/i18n/i18nSlice';
import { getReducedUISmartSetting } from '@@settings/settingsSlice';
import { useEditorElementsContext } from '@@editor/EditorElementsContext';
import config from '@@config';
import setupPlugins, { ALL_PLUGIN_CONFIG } from '@@editor/plugins/setup';
import useUploadFile from '@@api/hooks/useUploadFile';
import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';
import useFetchMetadata from '@@form/hooks/useFetchMetadata';
import { Tenant } from '@@api/services/tenant/schemas';

import RichTextEditorBase, { type Props as RichTextEditorBaseProps } from './RichTextEditorBase';

type Props = Omit<RichTextEditorBaseProps, 'plugins'> & {
    includedPlugins?: PluginName[];
    excludedPlugins?: PluginName[];
    pluginConfig?: PluginList[];
    pluginOptions?: PluginOptions;
    tenantIds?: Tenant['id'][];
    allowAttachments?: boolean;
    resetOnChangedProp?: {
        fieldName: string;
        parse?: (value: Descendant[]) => any;
    };
};

const RichTextEditor: React.FC<Props> = (props) => {
    const {
        includedPlugins = [],
        pluginConfig = ALL_PLUGIN_CONFIG,
        pluginOptions = {},
        isArticleEditor,
        tenantIds,
        allowAttachments = true,
    } = props;

    let {
        excludedPlugins = [
            PLUGIN_NAMES.INFOBOX,
            PLUGIN_NAMES.POLL,
            PLUGIN_NAMES.DYNAMIC_TEASER,
            PLUGIN_NAMES.COMMENT,
            PLUGIN_NAMES.SPELL_CHECKER,
        ],
    } = props;

    const { actions: elementsContextActions } = useEditorElementsContext();
    const { t } = useTranslation();
    const contentLocale = useSelector(getContentLocale);
    const reducedUI = useSelector(getReducedUISmartSetting);
    const uploadFile = useUploadFile();
    const fetchMetadata = useFetchMetadata();
    const spellCheck = usePostSpellCheck();

    if (reducedUI) {
        excludedPlugins = [...excludedPlugins, ...config.hiddenFloatingToolbarFeatures];
    }

    const plugins = useMemo(
        () =>
            setupPlugins(pluginConfig, {
                included: includedPlugins,
                excluded: excludedPlugins,
                defaultOptions: {
                    contentLocale,
                    reducedUI,
                    t,
                    uploadFile,
                    tenantIds,
                    ...(allowAttachments ? { elementsContextActions } : {}),
                },
                optionsPerPlugin: {
                    ...pluginOptions,
                    [PLUGIN_NAMES.SPELL_CHECKER]: {
                        ...pluginOptions[PLUGIN_NAMES.SPELL_CHECKER],
                        spellCheck,
                        tenantIds,
                    },
                    [PLUGIN_NAMES.LINK]: {
                        ...pluginOptions[PLUGIN_NAMES.LINK],
                        fetchMetadata,
                    },
                    [PLUGIN_NAMES.INSERT_HTML]: {
                        ...pluginOptions[PLUGIN_NAMES.INSERT_HTML],
                        fetchMetadata,
                    },
                },
            }),
        [],
    );

    return <RichTextEditorBase {...{ ...props, plugins, spellCheck: !isArticleEditor }} />;
};

export const EmbedContentEditor = styled(RichTextEditor, {
    shouldForwardProp: (prop) => prop !== 'placeholderText',
})<{ $templateElement?: boolean; placeholderText?: string }>(
    ({ $templateElement, placeholderText, theme }) => ({
        ...theme.typography.editorPrimarySmall,
        ...($templateElement && getInputPlaceholderStyle({ theme, placeholderText })),
    }),
);

export default RichTextEditor;
