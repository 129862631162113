import { useTranslation } from 'react-i18next';
import React, { CSSProperties } from 'react';
import { noop } from 'lodash';
import { Skeleton, Stack, TableCell, styled } from '@mui/material';

import { DATE } from '@@lib/dateTime/formats';
import { formatDate } from '@@lib/dateTime/utils';
import ActionMenu from '@@components/ActionMenu/ActionMenu';
import Checkbox from '@@form/components/Checkbox';
import ContentStatusIcon from '@@containers/ContentStatus/ContentStatusIcon';
import RadioInput from '@@form/components/RadioInput';
import Icon from '@@components/Icon';
import PreviewImage from '@@containers/PreviewImage';
import PublicationStatusIcon from '@@containers/PublicationStatus/PublicationStatusIcon';
import { IImageTransformations } from '@@utils/buildImageTransformationUrl';

const BooleanField = styled('div', {
    shouldForwardProp: (prop) => prop !== 'value',
})<{ value: boolean }>(({ value, theme }) => ({
    background: value ? theme.palette.success.main : theme.palette.error.main,
    width: '9px',
    height: '9px',
    margin: '0 auto',
}));

const StyledTableCell = styled(TableCell)<{
    $verticalAlign?: CSSProperties['verticalAlign'];
    $hovered?: boolean;
    $visibleOnHoverRowOnly?: boolean;
    $hasCustomRenderer?: boolean;
}>(({ $verticalAlign, $hovered, $visibleOnHoverRowOnly, $hasCustomRenderer, theme }) => ({
    verticalAlign: $verticalAlign || 'middle',
    ...($hasCustomRenderer ? { padding: 0 } : null),
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    position: 'relative',

    '&[data-type="loading"]': {
        verticalAlign: 'middle',
        padding: theme.spacing(3),
    },

    '&[data-type="id"], &[data-type="boolean"], &[data-type="actions"], &[data-type="checkbox"], &[data-type="radio"], &[data-type="contentStatus"], &[data-type="publicationStatus"]':
        {
            width: '1px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
        },

    '&[data-type="lineNumber"]': {
        textAlign: 'center',
        color: theme.palette.primary.main,
        width: theme.spacing(4),
    },

    '&[data-type="date"]': {
        textAlign: 'right',
    },

    '&[data-type="actions"]': {
        padding: `0 ${theme.spacing(2)} 0`,

        '> *': {
            visibility: !$visibleOnHoverRowOnly || $hovered ? 'visible' : 'hidden',
        },
    },

    '&[data-type="checkbox"], &[data-type="radio"]': {
        padding: 0,
    },
}));

type Props<T = any> = {
    column: TableColumn;
    as?: React.ElementType;
    className?: string;
    columnIndex?: number;
    draggable?: boolean;
    hovered?: boolean;
    record?: T;
    rowIndex?: number;
    selected?: boolean;
    value?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
    onClickAction?: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        action: IContextMenuAction,
        extraData: { column: TableColumn; record: T },
    ) => void;
    transformations?: IImageTransformations;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const DataField = <T extends any>(props: Props<T>) => {
    const {
        as,
        className,
        onClick,
        column,
        record,
        value,
        columnIndex,
        rowIndex,
        hovered,
        draggable,
        onChange,
        transformations,
        onClickAction = noop,
    } = props;

    const { t } = useTranslation();

    const renderField = () => {
        if (column == null || column.type === 'loading' || value === 'loading') {
            return <Skeleton role="progressbar" animation="wave" variant="text" />;
        }

        if (column.render) {
            return column.render({
                value,
                record,
                column,
                rowIndex,
                columnIndex,
                onClickAction,
                t,
            });
        }

        switch (column.type) {
            case 'image':
                return <PreviewImage src={value} transformations={transformations} />;

            case 'date': {
                return value ? formatDate(value, DATE) : null;
            }

            case 'lineNumber':
                return (
                    <Stack direction="row" justifyContent="center">
                        {draggable && hovered ? (
                            <Icon name="grip-dots-vertical" size="small" />
                        ) : (
                            value
                        )}
                    </Stack>
                );

            case 'checkbox':
                return <Checkbox checked={value} value={value} onChange={onChange} />;

            case 'radio':
                return <RadioInput checked={value} value={value} onChange={onChange} />;

            case 'boolean':
                return <BooleanField value={value} />;

            case 'contentStatus':
                return <ContentStatusIcon status={value} size={column.iconSize} />;

            case 'publicationStatus':
                return <PublicationStatusIcon status={value} size={column.iconSize} />;

            case 'actions': {
                return column.actions ? (
                    <ActionMenu
                        actions={column.actions}
                        wrapAfter={column.wrapActionsAfter}
                        extraData={{ record, column }}
                        onClickAction={onClickAction}
                    />
                ) : null;
            }

            default: {
                return props.value;
            }
        }
    };

    return (
        <StyledTableCell
            {...{ as, className }}
            $hasCustomRenderer={Boolean(column.render)}
            type={column.type}
            $visibleOnHoverRowOnly={column.visibleOnHoverRowOnly}
            onClick={onClick}
            sx={column.style}
            align={column.textAlign}
            $verticalAlign={column.verticalAlign}
            data-type={column.type}
            $hovered={hovered}
        >
            {renderField()}
        </StyledTableCell>
    );
};

export default DataField;
