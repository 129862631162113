import { z } from 'zod';

export const Newsletter = z.object({
    id: z.string(),
    categoryIds: z.array(z.number()).nullable(),
    name: z.string(),
    metadataId: z.number().nullable(),
    optInIds: z.record(z.string()).optional(),
});

export type Newsletter = z.infer<typeof Newsletter>;
