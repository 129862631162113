import { z } from 'zod';

import { Creator, Entity } from '../../../utils/schemas/schemas';

export const Comment = Entity.extend({
    id: z.string(),
    rootCommentId: z.string(),
    resourceId: z.string().optional(),
    creator: Creator,
    createdAt: z.string(),
    status: z.enum(['draft', 'resolved', 'active']),
    text: z.string(),
});

export type Comment = z.infer<typeof Comment>;
