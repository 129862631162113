import { get, isEmpty } from 'lodash';
import React from 'react';
import { styled } from '@mui/material';

import { Element } from '@@editor/helpers';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import CheckboxField from '@@form/fields/Checkbox';
import makeState from '@@editor/utils/makeState';
import CaptionEditorField from '@@form/fields/RichTextEditor/CaptionEditor';
import RichTextStringField from '@@form/fields/RichTextEditor/RichTextString';
import TextareaField from '@@form/fields/Textarea';
import isEmptyState from '@@editor/utils/isEmptyState';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { FormProps, UseReactHookFormProps } from '@@containers/ReactHookForm/types';

const FORM_NAME = 'SocialEmbedForm';

type Props = {
    type:
        | typeof PLUGIN_NAMES.EMBEDDED_CONTENT
        | typeof PLUGIN_NAMES.FACEBOOK
        | typeof PLUGIN_NAMES.IMAGE
        | typeof PLUGIN_NAMES.INSTAGRAM
        | typeof PLUGIN_NAMES.TIKTOK
        | typeof PLUGIN_NAMES.SLIDESHOW
        | typeof PLUGIN_NAMES.TWITTER
        | typeof PLUGIN_NAMES.VIDEOCMS
        | typeof PLUGIN_NAMES.YOUTUBE
        | typeof PLUGIN_NAMES.ZATTOO;
    formName: string;
    element: EmbedNode;
    renderButtons: FormProps['renderButtons'];
    validate: Validator[];
    withCaption: boolean;
    withCredit: boolean;
    withIncludeCaption?: boolean;
    withUrlAsDefault?: boolean;
    onCancel: VoidFunction;
    onChangeSrc: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        captionTitle: string;
        caption: Element[];
        credit: string;
        includeCaption?: boolean;
    };
    onSubmit: UseReactHookFormProps['onSubmit'];
    className?: string;
    // not using WithTranslation here to make the editor agnostic of the i18n framework
    t: (t: string) => string;
};

type FormValues = {
    embed: {
        src: string;
        captionTitle?: string;
        caption?: Element[];
        credit?: string;
        includeCaption?: boolean;
    };
};

// element data needs to be transformed back into form data
const transformElementToFormValues = (element: EmbedNode): FormValues => {
    // default
    if (!element) {
        return {
            embed: {
                src: '',
                captionTitle: '',
                caption: makeState(),
                credit: '',
                includeCaption: false,
            },
        };
    }

    const elementData = element.data;
    const captionTitle = get(elementData, 'embed.captionTitle');
    const caption = get(elementData, 'embed.caption');
    const credit = get(elementData, 'embed.credit');
    const includeCaption = Boolean(get(elementData, 'embed.includeCaption'));

    return {
        embed: {
            src: elementData.originalSrc || elementData.src || '',
            captionTitle,
            caption,
            credit,
            includeCaption,
        },
    };
};

const SocialEmbedForm = ({
    renderButtons,
    className,
    withCaption,
    withCredit,
    withIncludeCaption,
    withUrlAsDefault,
    element,
    onSubmit,
    onCancel,
    onChangeSrc,
    validate,
    type,
    t,
}: Props) => {
    const handleCancel = () => onCancel();

    return (
        <ReactHookForm
            formName={FORM_NAME}
            values={transformElementToFormValues(element)}
            onSubmit={onSubmit}
            onCancel={handleCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            className={className}
        >
            {({ getValues, setValue }) => {
                // overwrites the form field values on src change if they are unset,
                // only if the plugin asks for it.
                const handleOnSourceChange = (
                    e: React.ChangeEvent<HTMLTextAreaElement>,
                    onFieldChange,
                ) => {
                    onFieldChange(e);
                    const src = e.target.value;

                    if (!onChangeSrc || isEmpty(src)) {
                        return;
                    }

                    const [currCaptionTitle, currCaption, currCredit] = getValues([
                        'embed.captionTitle',
                        'embed.caption',
                        'embed.credit',
                    ]);

                    // read input from plugin
                    const { captionTitle, caption, credit, includeCaption } = onChangeSrc(e);
                    const rteCaption = caption && makeState(caption);

                    if (captionTitle && isEmpty(currCaptionTitle)) {
                        setValue('embed.captionTitle', captionTitle);
                    }

                    if (rteCaption && isEmptyState(currCaption)) {
                        setValue('embed.caption', rteCaption);
                    }

                    if (credit && isEmpty(currCredit)) {
                        setValue('embed.credit', credit);
                    }

                    if (includeCaption) {
                        setValue('embed.includeCaption', includeCaption);
                    }
                };

                return (
                    <>
                        <TextareaField
                            name="embed.src"
                            label={withUrlAsDefault ? t('embed.url') : t('embed.code')}
                            minRows={withUrlAsDefault ? 1 : undefined}
                            placeholder={withUrlAsDefault ? 'https://' : undefined}
                            required
                            validate={validate}
                            onChange={handleOnSourceChange}
                        />
                        {withIncludeCaption && (
                            <CheckboxField
                                key="includeCaption"
                                name="embed.includeCaption"
                                label={t('embed.includeCaption')}
                            />
                        )}
                        {withCaption && [
                            type !== PLUGIN_NAMES.VIDEOCMS && (
                                <RichTextStringField
                                    key="captionTitle"
                                    name="embed.captionTitle"
                                    label={t('embed.captionTitle')}
                                    displayUsedCharacters
                                />
                            ),
                            <CaptionEditorField
                                key="caption"
                                name="embed.caption"
                                label={t('embed.caption')}
                                displayUsedCharacters
                            />,
                        ]}
                        {withCredit && (
                            <RichTextStringField
                                key="credit"
                                name="embed.credit"
                                label={t('embed.credit')}
                                displayUsedCharacters
                            />
                        )}
                    </>
                );
            }}
        </ReactHookForm>
    );
};

export default styled(SocialEmbedForm)({
    width: '450px',
});
