import { z } from 'zod';

const EditionTargetEditionSectionBase = z.object({
    sectionDefinition: z.object({ type: z.literal('default') }),
});

const EditionTargetEditionBase = z.object({
    id: z.number(),
    tenantId: z.number().optional(),
    editionTargetId: z.number().optional(),
    status: z.string(),
    title: z.string().optional(),
    shortTitle: z.string().optional(),
    color: z.string().nullish(),
    publishOn: z.string(),
    nextEditionOn: z.string(),
    publishedAt: z.string().nullish(),
});

export const EditionTargetEdition = EditionTargetEditionBase.extend({
    sections: z.array(
        EditionTargetEditionSectionBase.extend({
            content: z.array(z.number()),
        }),
    ),
});

export type EditionTargetEdition = z.infer<typeof EditionTargetEdition>;

export const UnityEditionTargetEdition = EditionTargetEditionBase.extend({
    sections: z.array(
        EditionTargetEditionSectionBase.extend({
            content: z.array(
                z.object({
                    type: z.literal('metadata'),
                    metadataId: z.number(),
                }),
            ),
        }),
    ),
});

export type UnityEditionTargetEdition = z.infer<typeof UnityEditionTargetEdition>;

export const NewEditionTargetEdition = EditionTargetEdition.omit({ id: true });
export type NewEditionTargetEdition = z.infer<typeof NewEditionTargetEdition>;

export const NewUnityEditionTargetEdition = UnityEditionTargetEdition.omit({ id: true });
export type NewUnityEditionTargetEdition = z.infer<typeof NewUnityEditionTargetEdition>;
