import React, { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import { InputBaseComponentProps, styled } from '@mui/material';

import { Element } from '@@editor/helpers';
import { Wrapper } from '@@editor/toolbars/stickyToolbar/Toolbar';

import TextInputField, { TextInputFieldProps } from '../TextInput';

const StyledTextField = styled(TextInputField)(({ theme }) => ({
    '&:hover': {
        [Wrapper]: {
            zIndex: theme.fixed.editor.stickyToolbar.hoveredZindex,
        },
    },
})) as typeof TextInputField;

// https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
const InputComponent = React.forwardRef<any, InputBaseComponentProps>((props, ref) => {
    const { component: Component, onFocus, onChange, onBlur, onAnimationStart, ...rest } = props;

    React.useImperativeHandle(ref, () => ({
        focus() {},
    }));

    // When focusing the editor, MUI updates an internal state, called `focused`, inside its `InputBase`
    // component. This causes a re-render and because of the fact, that some props are re-calculated on
    // every render, like `onFocus`, `onBlur`, `onChange`, `onAnimationStart`, passed down by that same
    // MUI component, the editor would receive a re-render as well. This messes up the selection behaviour.
    // What we do here, is not really safe, since if those functions use a closure which might receive
    // updates, the cached version of the function will stay the same and therefore the updated value
    // of the closure will never be used, which might lead to unexpected behaviour. But it works for now and
    // we didn't find any other solution.
    // This is needed to avoid flaky selection behaviour in the editor:
    // https://tamedia.atlassian.net/browse/CD2-8991

    const handleFocus = useCallback((value) => {
        onFocus?.(value);
    }, []);

    const handleChange = useCallback((value) => {
        onChange?.(value);
    }, []);

    const handleBlur = useCallback((value) => {
        onBlur?.(value);
    }, []);

    const handleAnimationStart = useCallback((value) => {
        onAnimationStart?.(value);
    }, []);

    return (
        <Component
            {...rest}
            onFocus={handleFocus}
            onChange={handleChange}
            onBlur={handleBlur}
            onAnimationStart={handleAnimationStart}
        />
    );
});

type Props<TFieldValues extends FieldValues = FieldValues> = Omit<
    TextInputFieldProps<TFieldValues>,
    'component' | 'onChange'
> & {
    component: React.ComponentType<any>;
    onChange?: (value: Element[], onFieldChange: (...event: any[]) => void) => void;
};

export type RichTextFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
    Props<TFieldValues>,
    'component'
>;

const RichTextField = <TFieldValues extends FieldValues = FieldValues>(
    props: Props<TFieldValues>,
) => {
    const { component: Component, inputProps, ...rest } = props;

    return (
        <StyledTextField
            {...rest}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                inputComponent: InputComponent,
                inputProps: {
                    ...inputProps,
                    component: Component,
                },
                sx: {
                    paddingTop: '4px',
                },
            }}
            multiline
        />
    );
};

export default RichTextField;
