import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { z } from 'zod';
import { useStore } from 'react-redux';

import config from '@@config';
import { DefaultHeaders } from '@@api/headers';
import {
    Bookmark,
    CategoryPage,
    CategorySection,
    CustomSection,
    DefaultSection,
    Preset,
    Section,
    SectionContent,
    SectionLayout,
} from '@@api/services/placement/schemas';
import { PaginationParams } from '@@api/utils/schemas/queryParams';
import { paginated } from '@@api/utils/schemas/utils';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { RootState } from '@@scripts/store/store';
import { commonResponses } from '@@api/commonResponses';
import { getQueryKeys } from '@@api/utils/queryKeys';

const contract = initContract();

const router = contract.router(
    {
        bookmark: contract.router({
            getAll: {
                method: 'GET',
                path: '/bookmarks',
                query: z.object({
                    ...PaginationParams.shape,
                    metadataIds: z.string().optional(),
                }),
                responses: {
                    200: paginated(Bookmark),
                },
            },
            post: {
                method: 'POST',
                path: '/bookmarks',
                body: Bookmark,
                responses: {
                    201: Bookmark,
                },
            },
            delete: {
                method: 'DELETE',
                path: '/bookmarks/:id',
                pathParams: z.object({
                    id: Bookmark.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
        categoryPage: contract.router({
            get: {
                method: 'GET',
                path: '/categoryPage/:id',
                pathParams: z.object({
                    id: z.string(),
                }),
                responses: {
                    200: CategoryPage,
                },
            },
            put: {
                method: 'PUT',
                path: '/categoryPage/:id',
                pathParams: z.object({
                    id: z.string(),
                }),
                body: z.array(
                    z.union([
                        DefaultSection,
                        CategorySection.partial({ id: true }),
                        CustomSection.partial({ id: true }),
                    ]),
                ),
                responses: {
                    200: CategoryPage,
                },
            },
            publish: {
                method: 'POST',
                path: '/categoryPage/:id/publish',
                pathParams: z.object({
                    id: z.string(),
                }),
                body: z.undefined(),
                responses: {
                    200: z.undefined(),
                },
            },
        }),
        section: contract.router({
            get: {
                method: 'GET',
                path: '/sections/:id',
                pathParams: z.object({
                    id: z.string(),
                }),
                query: z.object({
                    includePreviewUrl: z.literal(true).optional(),
                }),
                responses: {
                    200: Section,
                },
            },
            put: {
                method: 'PUT',
                path: '/sections/:id',
                pathParams: z.object({
                    id: z.string(),
                }),
                body: Section,
                responses: {
                    200: Section,
                },
            },
            getContent: {
                method: 'GET',
                path: '/sections/:id/content',
                pathParams: z.object({
                    id: z.string(),
                }),
                query: z.object({
                    includePreviewUrl: z.literal(true).optional(),
                }),
                responses: {
                    200: SectionContent,
                },
            },
            putContent: {
                method: 'PUT',
                path: '/sections/:id/content',
                pathParams: z.object({
                    id: z.string(),
                }),
                body: SectionContent,
                responses: {
                    200: SectionContent,
                },
            },
            getHistory: {
                method: 'GET',
                path: '/sections/:id/history',
                pathParams: z.object({
                    id: z.string(),
                }),
                query: z.object({
                    ...PaginationParams.pick({ size: true }).shape,
                }),
                responses: {
                    200: paginated(SectionContent),
                },
            },
            layouts: {
                method: 'GET',
                path: '/sections/layouts',
                responses: {
                    200: z.array(SectionLayout),
                },
            },
        }),
        preset: contract.router({
            getAll: {
                method: 'GET',
                path: '/presets',
                query: z.object({
                    ...PaginationParams.pick({ size: true }).shape,
                }),
                responses: {
                    200: paginated(Preset),
                },
            },
            post: {
                method: 'POST',
                path: '/presets',
                body: Preset.partial({ id: true }),
                responses: {
                    201: Preset,
                },
            },
            delete: {
                method: 'DELETE',
                path: '/presets/:id',
                pathParams: z.object({
                    id: Preset.shape.id,
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type PlacementRouter = typeof router;

const placementClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.placementUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const usePlacementClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return placementClient(state);
};
