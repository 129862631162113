import React from 'react';
import { Transforms } from 'slate';
import { ThemeProvider } from '@mui/material';

import { ELEMENT_TYPES, TEXT_TYPES, UnorderedListElement } from '@@editor/helpers/Element';
import { Editor, Element } from '@@editor/helpers';
import renderElement from '@@editor/plugins/utils/renderElement';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
} from '@@editor/plugins/utils/inlineEditing';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import { createElementFromState } from '@@editor/serialization/UnitySerializer/deserializeNodes';
import { DEFAULT_BLOCK } from '@@editor/constants';

import { createGenerateLayoutBlock } from '../layout/utils';
import Summary from './Summary';
import EmbedWrapper from '../embed/components/EmbedWrapper';
import SummaryList from './SummaryList';
import { editorPlaceholderTheme } from '../styles';

const TYPE = PLUGIN_NAMES.SUMMARY;
const NODE_TYPE = ELEMENT_TYPES.SUMMARY_LIST;
const INLINE_EDITABLE_CHILDREN_TYPES = [ELEMENT_TYPES.SUMMARY_LIST_SUMMARY];

const CONTENT_CHILDREN_TYPES = [TEXT_TYPES.UNORDERED_LIST];

const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

export const createList = (list = ['']) =>
    Element.create<UnorderedListElement>({
        type: ELEMENT_TYPES.UNORDERED_LIST,
        data: {},
        children: list.map((text = '') => ({
            type: ELEMENT_TYPES.LIST_ITEM,
            data: {},
            children: [{ text }],
        })),
    });

const SummaryNode = (props) => {
    const { element, editor } = props;

    const generateTheme = (theme) =>
        Element.isTemplateElement(element) ? editorPlaceholderTheme(theme) : theme;

    return (
        <ThemeProvider theme={generateTheme}>
            <EmbedWrapper
                {...props}
                type={TYPE}
                component={Summary}
                placeholderComponent={Summary}
                contentEditable
                toolbarConfig={{
                    infos: {
                        iconName: PLUGIN_ICON_NAMES[TYPE],
                        title: editor.t('editor.plugin.summary.frame.title'),
                    },
                    actions: ['delete'],
                    // t('editor.plugin.summary.tooltip')
                    tooltips: [
                        {
                            title: 'editor.plugin.summary.tooltip',
                            iconName: 'bernand',
                            iconColor: 'amber',
                            iconSize: 'medium',
                        },
                    ],
                }}
            />
        </ThemeProvider>
    );
};

const withInlineSummary = (editor, options) => {
    const { isVoid, useInlineEditing, insertBreak } = editor;

    return Object.assign(editor, {
        isVoid: (element: Element) => {
            if (useInlineEditing && Element.isSummaryListRelatedElement(element)) {
                return false;
            }

            return element.type === NODE_TYPE ? true : isVoid(element);
        },
        normalizeNode: normalizeNode(editor, [
            [
                (editor, nodeEntry) => {
                    if (Element.isSummaryListSummaryElement(nodeEntry[0])) {
                        return normalizeInlineEditableElement(editor, nodeEntry, {
                            type: ELEMENT_TYPES.SUMMARY_LIST_SUMMARY,
                            allowedChildrenTypes: CONTENT_CHILDREN_TYPES,
                            minimumChildren: [ELEMENT_TYPES.UNORDERED_LIST],
                        });
                    }

                    return;
                },
            ],
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.SUMMARY_LIST,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: () => {
            const [firstMatch] = Editor.elements(editor, {
                match: Element.isSummaryListElement,
            });

            if (firstMatch && Element.isEmptySummaryListElement(firstMatch[0])) {
                const currentPath = firstMatch[1];
                const nextPath = [currentPath[0] + 1];

                Editor.insertElement(editor, Element.create(DEFAULT_BLOCK), { at: nextPath });
            } else {
                insertBreak();
            }
        },
        renderElement: renderElement(
            editor,
            [
                [NODE_TYPE, SummaryNode],
                [ELEMENT_TYPES.SUMMARY_LIST_SUMMARY, SummaryList],
            ],
            {
                ...options,
            },
        ),
        insertSummary: (at) => {
            generateLayoutBlock(
                editor,
                {
                    summary: [createList()],
                },
                {
                    at,
                    replace: false,
                },
            );

            Transforms.select(editor, at);
            Transforms.collapse(editor);
        },
        insertSummaryListSummary: ({ at, list, element }) => {
            Editor.withoutNormalizing(editor, () => {
                element.children.forEach((child, index) => {
                    const childPath = [...at, index];

                    if (Element.isSummaryListSummaryElement(child)) {
                        Transforms.removeNodes(editor, { at: childPath });

                        Transforms.insertNodes(
                            editor,
                            createElementFromState(ELEMENT_TYPES.SUMMARY_LIST_SUMMARY, [
                                createList(list),
                            ]),
                            {
                                at: childPath,
                            },
                        );
                    }
                });
            });

            Transforms.select(editor, at);
            Transforms.collapse(editor);
        },
    });
};

export default withInlineSummary;
