import { TFunction } from 'i18next';

import { Editor } from '@@editor/helpers';
import {
    PLUGIN_NAMES,
    OptionsPerPlugin,
    Plugin,
    PluginConfig,
    PluginList,
    PluginName,
} from '@@editor/typings/UnityPlugins';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import { UploadFile } from '@@api/hooks/useUploadFile';

// In order to avoid circular dependency errors we use require.context instead of require here
const pluginsContext = require.context(
    '.',
    true,
    /\/index\.(js|ts|tsx)$|\/text\/[a-zA-Z]+\.(js|ts|tsx)|\/rules\/[a-zA-Z]+\.(js|ts|tsx)$/,
);

const cache = {};

const importAll = (context) => {
    context.keys().forEach((key) => {
        try {
            const module = context(key);

            if (typeof module.default === 'function') {
                cache[key] = module;
            }
        } catch (e) {
            // In unit test context this is very polluting and there is nothing
            // I seem to be able to do to prevent this log
            if (process.env.NODE_ENV !== 'test') {
                console.warn('Could not import module', key, e);
            }
        }
    });
};

// We need to importAll here in order to avoid react error message
// "Do not call Hooks inside useEffect(...), useMemo(...)". If we use styled-components insdie a
// lazy imported module which is called inside useMemo of editor.js this error happens.
// https://github.com/styled-components/styled-components/issues/3045
importAll(pluginsContext);

const getPluginModulePath = (pluginName: string): string | undefined =>
    pluginsContext
        .keys()
        .find(
            (path) =>
                path.endsWith(`/${pluginName}/index.js`) ||
                path.endsWith(`/${pluginName}.js`) ||
                path.endsWith(`/${pluginName}/index.ts`) ||
                path.endsWith(`/${pluginName}.ts`) ||
                path.endsWith(`/${pluginName}/index.tsx`) ||
                path.endsWith(`/${pluginName}.tsx`),
        );

const getPluginModule = (name: PluginName) => {
    const modulePath = getPluginModulePath(name);

    if (modulePath) {
        const module = cache[modulePath] || pluginsContext(modulePath);

        if (typeof module.default === 'function') {
            return module.default;
        }
    }

    const pluginsWithoutFile: PluginName[] = [
        PLUGIN_NAMES.SPECIAL_CHARACTERS,
        PLUGIN_NAMES.INSERT_TEXT,
    ];

    // It IS possible that for a plugin name there is no plugin file, but
    // sometimes this might be a mistake. This is why we filter out the known ones and
    // warn about every other plugin file not found here.
    if (process.env.NODE_ENV === 'development' && !pluginsWithoutFile.includes(name)) {
        console.warn(`Could not find plugin module for "${name}"`);
    }

    return (editor) => editor;
};

const initializePlugin = (editor: Editor, { name, options }: PluginConfig) => {
    const pluginModule = getPluginModule(name);

    return pluginModule(editor, options);
};

export const ALL_PLUGIN_CONFIG: PluginList[] = [
    // DRAG_DROP needs to be above paragraph, since paragraph does not forward
    // the drop event (which is correct, nevertheless DRAG_DROP needs to get
    // notified about drop events)
    PLUGIN_NAMES.DRAG_DROP,
    PLUGIN_NAMES.FACEBOOK,
    PLUGIN_NAMES.TWITTER,
    PLUGIN_NAMES.TEASER_GENERATOR,
    PLUGIN_NAMES.INSTAGRAM,
    PLUGIN_NAMES.YOUTUBE,
    PLUGIN_NAMES.TIKTOK,
    PLUGIN_NAMES.THREADS,
    PLUGIN_NAMES.FRONTEND_COMPONENT,
    {
        name: PLUGIN_NAMES.VIDEOCMS,
        options: {
            plugins: [PLUGIN_NAMES.FILE_UPLOAD],
        },
    },
    PLUGIN_NAMES.ZATTOO,
    PLUGIN_NAMES.EMBEDDED_CONTENT,
    // Important! The image plugin needs to be loaded before the link plugin, otherwise pasted image urls
    // would be inserted as a link instead of an image
    {
        name: PLUGIN_NAMES.IMAGE,
        options: {
            plugins: [PLUGIN_NAMES.FILE_UPLOAD],
        },
    },
    PLUGIN_NAMES.SLIDESHOW,
    PLUGIN_NAMES.INFOBOX,
    PLUGIN_NAMES.POLL,
    PLUGIN_NAMES.SNIPPET,
    {
        name: PLUGIN_NAMES.EMBEDDED_COMPONENT,
        options: {
            plugins: [
                PLUGIN_NAMES.EMBEDDED_INFOBOX,
                PLUGIN_NAMES.EMBEDDED_POLL,
                PLUGIN_NAMES.EMBEDDED_IFRAME,
                PLUGIN_NAMES.EMBEDDED_SNIPPET,
            ],
        },
    },
    PLUGIN_NAMES.INTERVIEW,
    PLUGIN_NAMES.IMPORT_INTERVIEW,
    PLUGIN_NAMES.INLINE_INTERVIEW,
    PLUGIN_NAMES.QUOTE,
    PLUGIN_NAMES.INLINE_QUOTE,
    PLUGIN_NAMES.SUMMARY,
    PLUGIN_NAMES.SEPARATOR,
    PLUGIN_NAMES.DYNAMIC_TEASER,
    PLUGIN_CONFIG_TEMPLATES.autoReplaceText,
    PLUGIN_NAMES.INSERT_HTML,
    // Important! The list plugin needs to be placed above the paragraph plugin in order to overwrite
    // its `insertBreak` logic
    PLUGIN_NAMES.LIST,
    PLUGIN_NAMES.PARAGRAPH,
    PLUGIN_NAMES.BOLD,
    PLUGIN_NAMES.ITALIC,
    PLUGIN_NAMES.UNDERLINED,
    PLUGIN_NAMES.SUBSCRIPT,
    PLUGIN_NAMES.SUPERSCRIPT,
    PLUGIN_CONFIG_TEMPLATES.specialCharacters,
    PLUGIN_NAMES.LINK,
    PLUGIN_CONFIG_TEMPLATES.softHyphen,
    {
        name: PLUGIN_NAMES.COMMENT,
        options: {
            unsupportedElementTypes: [ELEMENT_TYPES.EMBED_CREDIT],
        },
    },
    PLUGIN_NAMES.SPELL_CHECKER,
];

const resolvePluginConfigEntry = (entry: PluginList): PluginConfig => {
    const resolvedEntry = typeof entry === 'string' ? { name: entry } : entry;

    return {
        ...resolvedEntry,
    };
};

type Options = {
    included?: PluginName[];
    excluded?: PluginName[];
    defaultOptions?: {
        reducedUI?: boolean;
        t?: TFunction;
        uploadFile?: UploadFile;
        contentLocale?: string;
        tenantIds?: number[];
    };
    optionsPerPlugin?: OptionsPerPlugin;
};

export const setupPlugins = (pluginConfig, options: Options = {}): Plugin[] => {
    const {
        included: includedPlugins = [],
        excluded: excludedPlugins = [],
        defaultOptions = {},
        optionsPerPlugin = {},
    } = options;

    return pluginConfig.reduce((previousValue, entry) => {
        const {
            name,
            options: pluginOptions = {},
            when = () => true,
        } = resolvePluginConfigEntry(entry);

        if (excludedPlugins.indexOf(name) >= 0 && includedPlugins.indexOf(name) < 0) {
            return previousValue;
        }

        const childPlugins = setupPlugins(pluginOptions.plugins || [], options);

        return previousValue.concat({
            name,
            options: {
                ...defaultOptions,
                ...pluginOptions,
                ...optionsPerPlugin[name],
                plugins: childPlugins,
            },
            when,
        });
    }, [] as Plugin[]);
};

const createInitializationPlan = (
    pluginConfig: PluginList[],
    rootPluginConfig = pluginConfig,
): PluginConfig[] =>
    pluginConfig.reduce((previousValue, entry) => {
        const { name, options, when } = resolvePluginConfigEntry(entry);

        if (!when?.(options, rootPluginConfig)) {
            return previousValue;
        }

        return previousValue
            .concat(createInitializationPlan(options?.plugins || [], rootPluginConfig))
            .concat({ name, options });
    }, [] as PluginConfig[]);

export const withPlugins = (editor: Editor, pluginConfig: PluginConfig[]): Editor => {
    const initializationPlan = createInitializationPlan(pluginConfig);

    // We need to reverse this to preserve the plugins order. For example the image plugin
    // needs to get triggered before the link plugin.
    return initializationPlan.reverse().reduce(initializePlugin, editor);
};

export default setupPlugins;
