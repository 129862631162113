import { find } from 'lodash';
import { Location } from 'slate';

import { PLUGIN_NAMES, PLUGIN_ICON_NAMES, Plugin, PluginName } from '@@editor/typings/UnityPlugins';
import { Editor, Element } from '@@editor/helpers';
import { Element as DropdownElement } from '@@components/ElementsDropdown';
import setupPlugins from '@@editor/plugins/setup';

// t(`editor.floatingToolbar.label.facebook`)
// t(`editor.floatingToolbar.label.instagram`)
// t(`editor.floatingToolbar.label.twitter`)
// t(`editor.floatingToolbar.label.tiktok`)
// t(`editor.floatingToolbar.label.threads`)
// t(`editor.floatingToolbar.label.youtube`)
// t('editor.floatingToolbar.label.videocms')
// t('editor.floatingToolbar.label.embeddedcontent')
// t('editor.floatingToolbar.label.infobox')
// t('editor.floatingToolbar.label.interviewSegment')
// t('editor.floatingToolbar.label.importInterview')
// t('editor.floatingToolbar.label.inlineInterview')
// t('editor.floatingToolbar.label.image')
// t('editor.floatingToolbar.label.poll')
// t('editor.floatingToolbar.label.quote')
// t('editor.floatingToolbar.label.inlineQuote')
// t('editor.floatingToolbar.label.slideshow')
// t('editor.floatingToolbar.label.embeddedComponent')
// t('editor.floatingToolbar.label.dynamicTeaser')
// t('editor.floatingToolbar.label.summary')
// t(`editor.floatingToolbar.label.zattoo`)
// t(`editor.floatingToolbar.label.separator`)
// t(`editor.floatingToolbar.label.frontendComponent`)
// t(`editor.insert.embed.facebook`)
// t(`editor.insert.embed.instagram`)
// t(`editor.insert.embed.twitter`)
// t(`editor.insert.embed.tiktok`)
// t(`editor.insert.embed.threads`)
// t(`editor.insert.embed.youtube`)
// t(`editor.insert.embed.videocms`)
// t('editor.insert.embed.embeddedcontent')
// t(`editor.insert.embed.infobox`)
// t(`editor.insert.embed.interviewSegment`)
// t(`editor.insert.embed.importInterview`);
// t(`editor.insert.embed.inlineInterview`);
// t(`editor.insert.embed.image`)
// t(`editor.insert.embed.poll`)
// t(`editor.insert.embed.quote`)
// t(`editor.insert.embed.slideshow`)
// t('editor.insert.embed.embeddedComponent')
// t(`editor.insert.embed.dynamicTeaser`)
// t(`editor.insert.embed.summary`)
// t(`editor.insert.embed.zattoo`)
// t(`editor.insert.embed.separator`)
// t(`editor.insert.embed.frontendComponent`)

const getDefaultConfig = (editor: Editor, plugin: Plugin, at?: Location) => ({
    label: editor.t(`editor.floatingToolbar.label.${plugin.name}`),
    title: editor.t(`editor.insert.embed.${plugin.name}`),
    iconName: PLUGIN_ICON_NAMES[plugin.name],
    onMouseDown: () => editor.showEmbedModal(plugin.name, undefined, at),
});

export const getBaseButtonConfig = (
    editor: Editor,
    activePlugins: PluginName[],
    options: {
        customConfig?: DropdownElement[];
        at?: Location;
    },
): DropdownElement[] => {
    const { customConfig = [], at } = options;

    const formattedPlugins = setupPlugins(activePlugins);

    return formattedPlugins.reduce(
        // eslint-disable-next-line complexity
        (previousValue: DropdownElement[], plugin) => {
            const isPluginAvailable = Boolean(find(editor.availablePlugins, { name: plugin.name }));

            if (isPluginAvailable) {
                const defaultConfig = getDefaultConfig(editor, plugin, at);

                switch (plugin.name) {
                    case PLUGIN_NAMES.SEPARATOR: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () => editor.insertSeparator(at),
                            },
                        ];
                    }

                    case PLUGIN_NAMES.INTERVIEW: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () => editor.showInterviewModal(undefined, false, at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.IMPORT_INTERVIEW: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () =>
                                    editor.showImportInterviewModal(undefined, false, at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.INLINE_INTERVIEW: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () => editor.insertInlineInterview(at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.INLINE_QUOTE: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () => editor.insertQuote(at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.IMAGE: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () => editor.insertImage(at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.DYNAMIC_TEASER: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () => editor.insertDynamicTeaser(at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.EMBEDDED_CONTENT: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () => editor.insertEmbeddedContent(at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.INFOBOX: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () => editor.insertInfobox(at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.POLL: {
                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                onMouseDown: () => editor.insertPoll(at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.SUMMARY: {
                        const doesArticleContainSummary = editor.children.some(
                            Element.isSummaryListElement,
                        );

                        return [
                            ...previousValue,
                            {
                                ...defaultConfig,
                                tooltipMessage: doesArticleContainSummary
                                    ? editor.t('editor.floatingToolbar.summary.tooltip')
                                    : '',
                                disabled: doesArticleContainSummary,
                                onMouseDown: () => editor.insertSummary(at),
                            },
                        ];
                    }
                    case PLUGIN_NAMES.EMBEDDED_COMPONENT:
                    case PLUGIN_NAMES.FRONTEND_COMPONENT:
                    case PLUGIN_NAMES.VIDEOCMS:
                    case PLUGIN_NAMES.SLIDESHOW:
                    case PLUGIN_NAMES.FACEBOOK:
                    case PLUGIN_NAMES.TWITTER:
                    case PLUGIN_NAMES.TIKTOK:
                    case PLUGIN_NAMES.THREADS:
                    case PLUGIN_NAMES.INSTAGRAM:
                    case PLUGIN_NAMES.YOUTUBE:
                    case PLUGIN_NAMES.QUOTE:
                    case PLUGIN_NAMES.ZATTOO:
                        return [...previousValue, defaultConfig];

                    default:
                        return previousValue;
                }
            }

            return previousValue;
        },
        customConfig,
    );
};
