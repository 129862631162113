import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { z } from 'zod';
import { useStore } from 'react-redux';

import config from '@@config';
import { DefaultHeaders } from '@@api/headers';
import {
    EditionTargetEdition,
    NewEditionTargetEdition,
    UnityEditionTargetEdition,
} from '@@api/services/editions/schemas';
import { paginated } from '@@api/utils/schemas/utils';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { RootState } from '@@scripts/store/store';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { commonResponses } from '@@api/commonResponses';
import {
    deserializeEditionTargetEdition,
    serializeEditionTargetEdition,
} from '@@api/services/editions/transformers';
import { getQueryKeys } from '@@api/utils/queryKeys';

const contract = initContract();

const router = contract.router(
    {
        edition: contract.router({
            post: {
                method: 'POST',
                path: '/edition-targets/:id/editions',
                pathParams: z.object({
                    id: z.number(),
                }),
                body: NewEditionTargetEdition.transform(serializeEditionTargetEdition),
                responses: {
                    201: UnityEditionTargetEdition.transform(deserializeEditionTargetEdition),
                },
            },
            getAll: {
                method: 'GET',
                path: '/edition-targets/:id/editions',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                query: z.object({
                    ...PaginationParams.shape,
                    ...SearchParams.pick({ q: true }).shape,
                }),
                responses: {
                    200: paginated(
                        UnityEditionTargetEdition.transform(deserializeEditionTargetEdition),
                    ),
                },
            },
            get: {
                method: 'GET',
                path: '/edition-targets/:targetId/editions/:editionId',
                pathParams: z.object({
                    targetId: z.coerce.number(),
                    editionId: z.coerce.number(),
                }),
                responses: {
                    200: UnityEditionTargetEdition.transform(deserializeEditionTargetEdition),
                },
            },
            delete: {
                method: 'DELETE',
                path: '/edition-targets/:targetId/editions/:editionId',
                pathParams: z.object({
                    targetId: z.coerce.number(),
                    editionId: z.coerce.number(),
                }),
                body: z.undefined(),
                responses: {
                    204: z.never(),
                },
            },
            put: {
                method: 'PUT',
                path: '/edition-targets/:targetId/editions/:editionId',
                pathParams: z.object({
                    targetId: z.coerce.number(),
                    editionId: z.coerce.number(),
                }),
                body: EditionTargetEdition.transform(serializeEditionTargetEdition),
                responses: {
                    200: UnityEditionTargetEdition.transform(deserializeEditionTargetEdition),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type EditionsRouter = typeof router;

const editionsClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.editionsUrl,
        ...clientDefaults(state),
    };

    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useEditionsClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return editionsClient(state);
};
