import React, { useMemo } from 'react';
import { type Descendant } from 'slate';

import { PLUGIN_NAMES, type PluginList } from '@@editor/typings/UnityPlugins';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import setupPlugins from '@@editor/plugins/setup';
import usePostSpellCheck from '@@form/hooks/usePostSpellCheck';
import { type Tenant } from '@@api/services/tenant/schemas';

import RichTextEditorBase from './RichTextEditorBase';

type Props = {
    singleLine?: boolean;
    tenantIds: Tenant['id'][];
    spellCheck?: boolean;
    value?: Descendant[];
    readOnly?: boolean;
};

const BasicRichTextEditor: React.FC<Props> = ({ tenantIds, ...props }) => {
    const spellCheck = usePostSpellCheck();

    const plugins = useMemo(() => {
        const pluginConfig: PluginList[] = [
            PLUGIN_CONFIG_TEMPLATES.autoReplaceText,
            PLUGIN_NAMES.PARAGRAPH,
        ];

        if (props.singleLine) {
            pluginConfig.push(PLUGIN_NAMES.SINGLE_LINE);
        }

        if (props.spellCheck) {
            pluginConfig.push(PLUGIN_NAMES.SPELL_CHECKER);
        }

        return setupPlugins(pluginConfig, {
            optionsPerPlugin: {
                [PLUGIN_NAMES.SPELL_CHECKER]: {
                    spellCheck,
                    tenantIds,
                },
            },
        });
    }, [props.singleLine, props.spellCheck, spellCheck]);

    return (
        <RichTextEditorBase {...props} plugins={plugins} hideStickyToolbar hideFloatingToolbar />
    );
};

export default BasicRichTextEditor;
