import { styled, Alert, Collapse, IconButton, Link, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import React, { ReactNode, useEffect, useState } from 'react';

import { SYSTEM_STATUS } from '@@routes/maintenancenotes/constants';
import { useStorage } from '@@hooks/useStorage';
import useLiveUpdates from '@@api/hooks/useLiveUpdates';
import useVersion from '@@api/hooks/useVersion';
import Icon from '@@components/Icon';
import { useReleaseNotesClient } from '@@api/services/releasenotes/client';

const StyledCollapse = styled(Collapse)(({ theme }) => ({
    border: `1px solid ${theme.palette.warning.dark}`,
    '& + &': {
        borderTopWidth: 0,
    },
})) as typeof Collapse;

type AppAlertProps = {
    message: ReactNode;
    onClose?: VoidFunction;
};

const AppAlert = React.forwardRef<HTMLDivElement, AppAlertProps>(
    ({ message, onClose, ...props }, ref) => (
        <StyledCollapse {...props} ref={ref}>
            <Alert
                action={
                    onClose && (
                        <IconButton color="inherit" size="small" onClick={onClose}>
                            <Icon name="xmark" />
                        </IconButton>
                    )
                }
                severity="warning"
                square
                variant="filled"
            >
                {message}
            </Alert>
        </StyledCollapse>
    ),
);

const AppNotifications = () => {
    const [searchParams] = useSearchParams();
    const { newVersion, isNewVersionAvailable } = useVersion();
    const [isNewVersionVisible, setIsNewVersionVisible] = useState(false);
    const code = searchParams.get('code');
    const { client: releaseNotesClient, queryKeys: releaseNotesKeys } = useReleaseNotesClient();

    const { data } = releaseNotesClient.maintenanceNotes.getAll.useQuery({
        queryKey: releaseNotesKeys.maintenanceNotes.getAll(),
        queryData: {},
        enabled: !code,
    });
    const maintenancenotes = data?.body;

    const maintenancenote = maintenancenotes?.[0];

    const isMaintenance = maintenancenote?.state === SYSTEM_STATUS.MAINTENANCE;

    const [showMaintenanceMessage, setShowMaintenanceMessage] = useStorage(
        'showMaintenanceModeMsg',
        true,
        'sessionStorage',
    );

    useLiveUpdates(
        {
            path: `/releasenotes/maintenancenotes/${maintenancenote?.id}`,
            queryKey: releaseNotesKeys.maintenanceNotes.getAll(),
        },
        {
            enabled: Boolean(maintenancenote?.id),
            onUpdate: () => setShowMaintenanceMessage(true),
        },
    );

    useEffect(() => {
        setIsNewVersionVisible(isNewVersionAvailable);
    }, [isNewVersionAvailable]);

    const shouldNotifyUserAboutMaintenance = isMaintenance && showMaintenanceMessage;

    const handleCloseMaintenanceNotification = () => setShowMaintenanceMessage(false);

    return (
        <Stack flexGrow={1}>
            <TransitionGroup>
                {shouldNotifyUserAboutMaintenance && (
                    <AppAlert
                        message={maintenancenote?.notification}
                        onClose={handleCloseMaintenanceNotification}
                    />
                )}

                {isNewVersionVisible && (
                    <AppAlert
                        message={
                            // Translation key
                            // t('newVersion')
                            <Trans
                                i18nKey="newVersion"
                                values={{ newVersion }}
                                components={{
                                    reloadLink: (
                                        <Link
                                            color="text.primary"
                                            href={window.location.href}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                window.location.reload();
                                            }}
                                        />
                                    ),
                                }}
                            />
                        }
                    />
                )}
            </TransitionGroup>
        </Stack>
    );
};

export default AppNotifications;
