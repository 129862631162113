export const BE_STATUS = {
    TRANSFERRED: 'TRANSFERRED',
    ERROR: 'ERROR',
} as const;

export const REMOTE_STATUS = {
    SENDING: 'sending',
    ACCEPTED: 'message accepted',
    PENDING: 'delivery pending',
    DELIVERED: 'delivered',
    SCHEDULED: 'scheduled',
} as const;

export const PUSH_STATUS = {
    /**
     * * This comment is for translation auto extract
     * t('pushStatus.sending')
     * t('pushStatus.description.sending')
     * t('pushStatus.finished')
     * t('pushStatus.description.finished')
     * t('pushStatus.failed')
     * t('pushStatus.description.failed')
     * t('pushStatus.externalError')
     * t('pushStatus.description.externalError')
     * t('pushStatus.unknown')
     * t('pushStatus.description.unknown')
     */
    SENDING: 'sending',
    FINISHED: 'finished',
    FAILED: 'failed',
    EXTERNAL_ERROR: 'externalError',
    UNKNOWN: 'unknown',
} as const;
