import { useTranslation } from 'react-i18next';
import {
    styled,
    Button,
    ButtonProps,
    IconButton,
    IconButtonProps,
    CircularProgress,
} from '@mui/material';
import React from 'react';

import DefaultSubmitButton, {
    Props as DefaultSubmitButtonProps,
} from '@@components/buttonImplementations/SubmitButton';
import Icon from '@@components/Icon';
import FloatingBox from '@@components/FloatingBox';

import { useButtonsContext } from './ButtonsContext';
import ButtonGroup from '../ButtonGroup';

const FormSubmitButton = styled(DefaultSubmitButton)({});

const FormCancelButton = styled(Button)({});

const FormDeleteButton = styled(Button)(({ theme }) => ({
    marginRight: 'auto',
    '&:hover, &:focus': {
        color: theme.palette.error.main,
    },
    '&:active': {
        color: theme.palette.error.dark,
    },
}));

const FormRestoreButton = styled(Button)({
    marginRight: 'auto',
});

const RestoreButton = (props: ButtonProps = {}) => {
    const { handleClickOnRestoreButton } = useButtonsContext();
    const { t } = useTranslation();

    return (
        <FormRestoreButton onClick={handleClickOnRestoreButton} variant="outlined" {...props}>
            {props.children || t('form.restoreButton')}
        </FormRestoreButton>
    );
};

const DeleteButton = (props: ButtonProps = {}) => {
    const { handleClickOnDeleteButton } = useButtonsContext();
    const { t } = useTranslation();

    return (
        <FormDeleteButton onClick={handleClickOnDeleteButton} variant="text" {...props}>
            {props.children || t('form.deleteButton')}
        </FormDeleteButton>
    );
};

const CloseButton = (props: IconButtonProps = {}) => {
    const { handleClickOnCloseButton } = useButtonsContext();
    const { t } = useTranslation();

    return (
        <FloatingBox anchor={null} spacing={3}>
            <IconButton
                title={t('form.closeButton.tooltip')}
                onClick={handleClickOnCloseButton}
                {...props}
            >
                <Icon name="xmark" />
            </IconButton>
        </FloatingBox>
    );
};

type CancelButtonProps = ButtonProps & { iconOnly?: boolean; iconName?: string };
const CancelButton = ({ iconOnly, children, iconName, ...props }: CancelButtonProps = {}) => {
    const { handleClickOnResetButton, pristine, hasActiveCancelButton } = useButtonsContext();
    const { t } = useTranslation();
    const text = children || t('form.cancelButton');

    if (iconOnly) {
        return (
            <IconButton
                disabled={hasActiveCancelButton ? false : pristine}
                onClick={handleClickOnResetButton}
                {...props}
                type="reset"
            >
                <Icon name={iconName ?? 'circle-xmark-regular'} size="large" />
            </IconButton>
        );
    }

    return (
        <FormCancelButton
            startIcon={iconName && <Icon name={iconName} />}
            disabled={hasActiveCancelButton ? false : pristine}
            onClick={handleClickOnResetButton}
            variant="outlined"
            {...props}
            type="reset"
        >
            {text}
        </FormCancelButton>
    );
};

type SubmitButtonProps = DefaultSubmitButtonProps & { iconOnly?: boolean };
const SubmitButton = ({ children, iconOnly, iconName, ...props }: SubmitButtonProps = {}) => {
    const { handleClickOnSubmitButton, pristine, submitting } = useButtonsContext();
    const { t } = useTranslation();
    const text = children || t('form.submitButton');

    if (iconOnly) {
        return (
            <IconButton
                color="success"
                onClick={handleClickOnSubmitButton}
                disabled={pristine || submitting}
                {...props}
                type="submit"
            >
                {submitting ? (
                    <CircularProgress color="inherit" size={24} />
                ) : (
                    <Icon name={iconName ?? 'circle-check-sharp'} size="large" />
                )}
            </IconButton>
        );
    }

    return (
        <FormSubmitButton
            onClick={handleClickOnSubmitButton}
            disabled={pristine || submitting}
            loading={submitting}
            iconName={iconName}
            {...props}
        >
            {text}
        </FormSubmitButton>
    );
};

const Buttons = {
    SubmitButton,
    CancelButton,
    CloseButton,
    DeleteButton,
    RestoreButton,
};

export const ButtonPreset = () => {
    const {
        hasButtonGroup,
        hasDeleteButton,
        isEditMode,
        hasRestoreButton,
        alwaysShowCancelButton,
        hasCancelButton,
        dirty,
        hasCloseButton,
        ...props
    } = useButtonsContext();

    const renderDeleteButton = () =>
        props.renderDeleteButton ? props.renderDeleteButton(Buttons) : <DeleteButton />;

    const renderCancelButton = () =>
        props.renderCancelButton ? props.renderCancelButton(Buttons) : <CancelButton />;

    const renderSubmitButton = () =>
        props.renderSubmitButton ? props.renderSubmitButton(Buttons) : <SubmitButton />;

    const renderRestoreButton = () =>
        props.renderRestoreButton ? props.renderRestoreButton(Buttons) : <RestoreButton />;

    return hasButtonGroup ? (
        <ButtonGroup>
            {hasDeleteButton && isEditMode ? renderDeleteButton() : null}

            {hasRestoreButton ? renderRestoreButton() : null}

            {hasCancelButton && (alwaysShowCancelButton || dirty) ? renderCancelButton() : null}

            {renderSubmitButton()}

            {hasCloseButton && <CloseButton />}
        </ButtonGroup>
    ) : null;
};

export type ButtonsType = {
    DeleteButton: typeof DeleteButton;
    CancelButton: typeof CancelButton;
    SubmitButton: typeof SubmitButton;
    RestoreButton: typeof RestoreButton;
    CloseButton: typeof CloseButton;
};

export default {
    ...Buttons,
    ButtonPreset: () => <ButtonPreset />,
};
