import { z } from 'zod';

import { LANG_CODES } from '@@lib/i18n/constants';
import { ContentStatus as ContentStatusEnum } from '@@constants/ContentStatus';
import { PublicationStatus as PublicationStatusEnum } from '@@constants/PublicationStatus';
import { UnityElement } from '@@editor/typings/UnityElements';
import { Element } from '@@editor/helpers';

export const Entity = z.object({
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
});

export const UnityRichTextElement = z.custom<UnityElement>();

export const UnityRichText = z.object({
    richTextFormat: z.array(UnityRichTextElement),
});

export const RichTextElement = z.custom<Element>();

export const RichText = z.array(RichTextElement);

export const ContentLocale = z.enum([LANG_CODES.GERMAN, LANG_CODES.FRENCH]);
export const UiLocale = z.enum([LANG_CODES.ENGLISH, LANG_CODES.GERMAN, LANG_CODES.FRENCH]);

const Point = z.object({
    x: z.number(),
    y: z.number(),
});

export type Point = z.infer<typeof Point>;

const FocusPoint = Point;

export type FocusPoint = z.infer<typeof FocusPoint>;

const CropMarks = z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
});

export type CropMarks = z.infer<typeof CropMarks>;

const CropVariant = z.object({
    cropMarks: CropMarks.nullish(),
});

const DefaultTeaserImageVariants = z.object({
    '1:1': CropVariant,
    '3:2': CropVariant,
    '16:9': CropVariant,
});

const OpenGraphImageVariants = z.object({
    '2:1': CropVariant,
});

const ImageVariants = z.union([
    DefaultTeaserImageVariants,
    OpenGraphImageVariants,
    z.record(z.never()),
]);

export type ImageVariants = z.infer<typeof ImageVariants>;

export const Image = z.object({
    focusPoint: FocusPoint.nullish(),
    cropMarks: CropMarks.nullish(),
    url: z.string().nullish(),
    elvisId: z.string().nullish(),
    caption: z.any(), // TODO: should be Element[]
    credit: z.string().optional(),
    name: z.string().optional(),
    naturalWidth: z.number().optional(),
    naturalHeight: z.number().optional(),
    width: z.number().optional(),
    height: z.number().optional(),
    mimetype: z.string().nullish(),
    variants: ImageVariants.nullish(),
    style: z
        .object({
            imageType: z.union([z.literal('image'), z.literal('infographic')]),
        })
        .optional(),
});

export type Image = z.infer<typeof Image>;
export const ImageWithoutUrl = Image.partial({ url: true });

export type ImageWithoutUrl = z.infer<typeof ImageWithoutUrl>;

export const Creator = z.object({
    id: z.string(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
});

export type Creator = z.infer<typeof Creator>;

export const ContentStatus = z.nativeEnum(ContentStatusEnum);
export type ContentStatus = z.infer<typeof ContentStatus>;

export const PublicationStatus = z.nativeEnum(PublicationStatusEnum);
export type PublicationStatus = z.infer<typeof PublicationStatus>;

export const ContentOwner = z.enum(['UNITY']);

export const ContentTypes = {
    ARTICLES: 'articles',
    TICKERS: 'tickers',
    SLIDESHOWS: 'slideshows',
    VIDEOS: 'videos',
    LINKS: 'links',
    EMBEDS: 'embeds',
    BREAKIGNEWS: 'breakingnews',
} as const;

export const ContentType = z.nativeEnum(ContentTypes);
export type ContentType = z.infer<typeof ContentType>;
