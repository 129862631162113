import React from 'react';
import { styled } from '@mui/material';

import { VideocmsElement } from '@@editor/helpers/Element';
import PreviewImage from '@@containers/PreviewImage';
import { MAX_HEIGHT } from '@@editor/plugins/serializable/constants';
import { Editor } from '@@editor/helpers';

import EmbedComponentLoader from '../../components/EmbedComponentLoader';

const LimitedHeightPreviewImage = styled(PreviewImage)({
    maxHeight: `${MAX_HEIGHT}px`,
    borderRadius: 0,
    width: '100%',
});

const Wrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.fixed.editor.embed.backgroundColor,
}));

type Props = {
    editor: Editor;
    element: VideocmsElement;
};

const VideoCmsPreview = ({ element, editor }: Props) => {
    const { embed } = element.data;

    return (
        <EmbedComponentLoader editor={editor} element={element}>
            {({ onLoad, onError }) => (
                <Wrapper>
                    <LimitedHeightPreviewImage
                        src={embed?.previewImage?.url || ''}
                        onLoad={onLoad}
                        onError={onError}
                        transformations={{ ...embed?.previewImage }}
                    />
                </Wrapper>
            )}
        </EmbedComponentLoader>
    );
};

export default VideoCmsPreview;
