import { keyBy, omit } from 'lodash';

import { CustomText, Element, ELEMENT_TYPES } from '@@editor/helpers/Element';
import { UnityElement } from '@@editor/typings/UnityElements';
import { DEFAULT_TENANT_ID } from '@@containers/metadata/constants';
import { type Article, type RevisionData } from '@@api/services/content/schemas/article';
import UnitySerializer from '@@editor/serialization/UnitySerializer/UnitySerializer';

import { findInContent } from './deserialization';

const serializeNodeWithoutId = (node: Element | CustomText): UnityElement | undefined | void => {
    const unitySerializer = new UnitySerializer();
    const nodeWithoutId = omit<Element | CustomText>(node, 'id') as Element | CustomText;

    return unitySerializer.serializeNode(nodeWithoutId);
};

export const transformToArticleContent = (entity: Article | RevisionData): Element[] => {
    // This is required because of Conflict Resolution which already has title, title header and lead inside its content
    if (findInContent(entity.content, ELEMENT_TYPES.TITLE_HEADER)) {
        return entity.content;
    }

    // First we transform the variants to match the BE format
    const variantsByKey = keyBy(entity.variants, 'variantId');

    let headings: any[] = [];

    Object.entries(variantsByKey).forEach(([variantId, variant]) => {
        headings = variant.headings.map((element, elementIndex) => ({
            ...(headings?.[elementIndex] || {}),
            // We need to convert the variantId back to number because the
            // keyBy util from lodash is creating string keys
            ...(Number(variantId) === DEFAULT_TENANT_ID
                ? element
                : {
                      variants: {
                          ...headings?.[elementIndex]?.variants,
                          [variantId]: serializeNodeWithoutId(element),
                      },
                  }),
        }));
    });

    return headings.concat(entity.content);
};
