import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { omit } from 'lodash';

import maxLength from '@@form/utils/validators/maxLength';
import makeState from '@@editor/utils/makeState';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';
import { sanitizeHtml } from '@@utils/string';
import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';
import InfoTooltip from '@@components/InfoTooltip';
import { useAiToolsClient } from '@@api/services/aiTools/client';
import TextareaField from '@@form/fields/Textarea';
import { type Image as ImageType } from '@@api/utils/schemas/schemas';
import { ButtonsType } from '@@form/components/ImageEditor/ImageUpload/ButtonsContext';
import { ImageUploadProps } from '@@form/components/ImageEditor/ImageUpload/ImageUpload';

import TextField from './TextInput';
// If we remove that import, even though we do not need it here anymore, we will get
// a circular dependency error:
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import RichTextStringField from './RichTextEditor/RichTextString';
import CaptionEditorField from './RichTextEditor/CaptionEditor';
import ImageUploadField from './ImageUploadField';
import RadioGroupField from './RadioGroup';

const MAX_DESCRIPTION_LENGTH = 1000;
const IMAGE_METADATA_FIELDS = ['caption', 'credit'];
const isRTEDataFormat = (fieldName) => ['caption'].includes(fieldName);

const RefreshGroup = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

const DescriptionLabel = styled('div')({
    display: 'flex',
});

export const IMAGE_STYLE_TYPES = {
    image: 'image',
    infographic: 'infographic',
};

type BaseProps = {
    name: string;
    allowImageEdit?: boolean;
    aspectRatio?: string;
    defaultValue?: ImageType;
    label: string;
    flagAsOptional?: boolean;
    noDropArea?: boolean;
    hasButtonGroup?: boolean;
    placeholder?: string;
    required?: boolean;
    requiredFields?: {
        name?: boolean;
        credit?: boolean;
    };
    renderButtons?: (buttons: ButtonsType, ownProps: ImageUploadProps) => React.ReactNode;
    showCaption?: boolean;
    showCredit?: boolean;
    showImageType?: boolean;
    transformations?: {
        maxWidth: number;
        maxHeight: number;
    };
};

type ImageOnlyProps = BaseProps & {
    imageOnly: true;
    imageAndDescriptionOnly?: never;
};

type DescriptionOnlyProps = BaseProps & {
    imageOnly?: never;
    imageAndDescriptionOnly?: true;
};

type Props = ImageOnlyProps | DescriptionOnlyProps;

export const ImageField = (props: Props) => {
    const {
        defaultValue,
        imageOnly,
        imageAndDescriptionOnly,
        name,
        requiredFields = {
            name: false,
            credit: false,
        },
        showCaption = true,
        showCredit = true,
        showImageType = false,
    } = props;
    const { t } = useTranslation();
    const { setValue, getValues } = useReactHookFormContext();
    const imageData = getValues(name);
    const { client: aiToolsClient } = useAiToolsClient();
    const { mutate: postResource, isPending: isSubmitting } =
        aiToolsClient.imageDescriptionGenerator.post.useMutation();

    const handleImageMetadataChange = useCallback(
        (metadata) =>
            IMAGE_METADATA_FIELDS.forEach((fieldName) => {
                const fieldHasMetadata = metadata.hasOwnProperty(fieldName);

                if (fieldHasMetadata) {
                    const fieldPath = `${name}.${fieldName}`;

                    const sanitizedValue = sanitizeHtml(metadata[fieldName]);

                    setValue(
                        fieldPath,
                        isRTEDataFormat(fieldName) ? makeState(sanitizedValue) : sanitizedValue,
                    );
                }
            }),
        [setValue, getValues, name],
    );

    const getImageDescription = (url) => {
        postResource(
            {
                body: {
                    imageUrl: url,
                },
            },
            {
                onSuccess: ({ body }) => {
                    setValue(`${name}.name`, body.altText);
                },
            },
        );
    };

    const handleRefreshImageDescription = () => {
        if (imageData) {
            getImageDescription(imageData.image.url);
        }
    };

    const handleImageUploadChange = (value) => {
        if (value.url) {
            getImageDescription(value.url);
        }
    };

    const getUploadImageFieldName = () => {
        if (imageOnly) {
            return name;
        }

        // in case name is nested (for example variants.0.image)
        const path = name.split('.');

        return `${name}.${path[path.length - 1]}`;
    };

    return (
        <React.Fragment>
            <ImageUploadField
                {...props}
                onChange={(value, fieldOnChange) => {
                    fieldOnChange(omit(value, 'uploadSuccess'));

                    if (imageAndDescriptionOnly || !imageOnly) {
                        if (value.uploadSuccess) {
                            handleImageUploadChange(value);
                        }
                    }
                }}
                name={getUploadImageFieldName()}
                defaultValue={!imageOnly ? {} : defaultValue}
                onMetadataChange={handleImageMetadataChange}
            />

            {showImageType && (
                <>
                    <Spacer md v />

                    <RadioGroupField
                        row
                        required
                        name={`${name}.style.imageType`}
                        defaultValue={defaultValue?.style?.imageType}
                        fields={[
                            {
                                value: IMAGE_STYLE_TYPES.image,
                                label: t('image.style.image'),
                            },
                            {
                                value: IMAGE_STYLE_TYPES.infographic,
                                label: t('image.style.infographic'),
                            },
                        ]}
                    />

                    <Spacer md v />
                </>
            )}

            {(!imageOnly || imageAndDescriptionOnly) && (
                <React.Fragment>
                    <Spacer md v />

                    <TextareaField
                        name={`${name}.name`}
                        label={<DescriptionLabel>{t('image.description')}</DescriptionLabel>}
                        defaultValue={defaultValue?.name}
                        validate={[maxLength(MAX_DESCRIPTION_LENGTH)]}
                    />

                    <RefreshGroup>
                        <InfoTooltip
                            title={t('metaform.teaser.imageDescription.tooltip')}
                            iconName="bernand"
                            iconSize="large"
                            iconColor="amber"
                        >
                            <LoadingButton
                                color="primary"
                                variant="outlined"
                                startIcon={<Icon name="rotate" />}
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={handleRefreshImageDescription}
                            >
                                {t('metaform.teaser.modal.refreshDescription')}
                            </LoadingButton>
                        </InfoTooltip>
                    </RefreshGroup>

                    <Spacer md v />

                    {!imageAndDescriptionOnly && (
                        <React.Fragment>
                            {showCaption && (
                                <CaptionEditorField
                                    name={`${name}.caption`}
                                    label={t('image.caption')}
                                    defaultValue={defaultValue?.caption}
                                    displayUsedCharacters
                                />
                            )}

                            <Spacer md v />

                            {showCredit && (
                                <TextField
                                    name={`${name}.credit`}
                                    label={t('image.credit')}
                                    defaultValue={defaultValue?.credit}
                                    required={requiredFields.credit}
                                    displayUsedCharacters
                                />
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ImageField;
