import React, { PropsWithChildren, CSSProperties } from 'react';
import { styled } from '@mui/material';

import { ElementAttributes } from '@@editor/helpers/Element';
import { Editor } from '@@editor/helpers';

const Wrapper = styled('ol')<{ $isArticleEditor?: boolean }>(({ theme, $isArticleEditor }) => ({
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    ...($isArticleEditor ? { ...theme.typography.editorPrimaryLarge } : {}),
}));

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    style?: CSSProperties;
}>;

const Ol = React.forwardRef<HTMLElement, Props>(({ attributes, children, editor, style }, ref) => (
    <Wrapper {...{ ref, style, ...attributes }} $isArticleEditor={editor.isArticleEditor}>
        {children}
    </Wrapper>
));

export default Ol;
