import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Stack } from '@mui/material';

import { ElementAttributes, EmbedElement } from '@@editor/helpers/Element';
import { Editor, Element, Node, ReactEditor } from '@@editor/helpers';

import { getInputPlaceholderStyle } from '../../styles';

type StyledQuoteCaptionProps = {
    $templateElement: boolean;
    $placeholderText: string;
};

export const StyledQuoteCaption = styled('div')<StyledQuoteCaptionProps>(
    ({ $templateElement, $placeholderText, theme }) => ({
        ...theme.typography.editorPrimaryXlarge,
        paddingRight: `calc(2 * ${theme.spacing(4)})`,
        textAlign: $templateElement ? 'right' : 'left',
        ...($templateElement && {
            ...getInputPlaceholderStyle({ theme, placeholderText: $placeholderText }),
            '&&::before': {
                right: `calc(2 * ${theme.spacing(4)})`,
            },
        }),

        flexGrow: $templateElement ? 1 : 0,
    }),
);

export const Dash = styled('span')(({ theme }) => ({
    ...theme.typography.editorPrimaryXlarge,
}));

type Props = {
    attributes: ElementAttributes;
    children: React.ReactNode;
    editor: Editor;
    element: EmbedElement;
};

const QuoteCaption = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);
    const isTemplateElement =
        Element.isTemplateElement(parentNode) || Element.isEmptyQuoteCaptionElement(element);

    return (
        <>
            <Stack direction="row" justifyContent="flex-end">
                {!isTemplateElement && <Dash contentEditable={false}>{'- '}</Dash>}
                <StyledQuoteCaption
                    {...attributes}
                    $templateElement={isTemplateElement}
                    $placeholderText={`- ${t('editor.plugin.quote.authorPlaceholderText')}`}
                >
                    {children}
                </StyledQuoteCaption>
            </Stack>
        </>
    );
};

export default QuoteCaption;
