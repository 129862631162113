/* eslint-disable no-magic-numbers */
export const MS = 1000;
export const MINUTE = 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
/* eslint-enable no-magic-numbers */

export const DATE_TIME_TYPES = {
    past: 'past',
    targetDateTime: 'targetDateTime',
} as const;

export type DateTimeType = (typeof DATE_TIME_TYPES)[keyof typeof DATE_TIME_TYPES];
